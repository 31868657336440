import React from 'react';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';
import SignupHeader from '../components/SignupHeader';
import emailSent from '../assets/email-sent.svg';

export default function SignupConfirmation() {
  return (
    <Comp>
      <Helmet title="Email Confirmation" />
      <SignupHeader step={4} />

      <h1>Step 4. Email Confirmation</h1>
      <p>
        In order to complete your account setup and set your password, please
        check your inbox for an email with further instructions.
      </p>

      <Centered>
        <img
          src={emailSent}
          alt="Please check your email for further instructions"
        />
        <h2>Thank you! Please check your email for further instructions.</h2>
      </Centered>
    </Comp>
  );
}

const Comp = styled.div`
  width: 100%;

  h1 {
    font-size: 1.6rem;
    margin: 30px 0 10px 0;
    font-weight: 600;
  }
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;

  img {
    width: 100%;
    max-width: 400px;
  }

  h2 {
    max-width: 400px;
    text-align: center;
  }
`;
