import React, {useState} from 'react';
import {useStore} from 'react-hookstore';
import styled from 'styled-components';
import {
  Card,
  ButtonGroup,
  TextArea,
  Button,
  Icon,
  Divider,
} from '@blueprintjs/core';
import {DateInput} from '@blueprintjs/datetime';
import moment from 'moment';
import {colors} from '../modules/styles';
import SuggestMultiSelect from './SuggestMultiSelect';

export default function NoteCard({note, onSave, onDelete, users}) {
  const [isEditing, setIsEditing] = useState(note._id ? false : true); // Start in edit mode for new notes
  const [text, setText] = useState(note.text ?? '');
  const [reminder, setReminder] = useState(
    note.reminder ? new Date(note.reminder) : null
  );
  const [selectedUsers, setSelectedUsers] = useState(note.sharedWith ?? []);
  const [currentUser] = useStore('currentUser');

  const onReminderChange = date => {
    if (date) {
      // Needed to set the default time to 9am
      if (!reminder) {
        date.setHours(9);
      }

      // Ensure we're always setting the minutes to zero
      date.setMinutes(0);
    }
    setReminder(date);
  };

  const onCancel = () => {
    if (!note._id) {
      // It's a new blank note so just delete it
      onDelete(note.provision, note._id);
    } else {
      setIsEditing(false);
      setText(note.text ?? '');
      setReminder(note.reminder ? new Date(note.reminder) : null);
      setSelectedUsers(note.sharedWith ?? []);
    }
  };

  return (
    <Comp>
      <Card elevation={2} key={note._id || `note_${note.provision}`}>
        {note._id && note.createdBy && (
          <>
            <CreatedBy>
              <b>
                {note.createdBy.id === currentUser.id
                  ? 'Me'
                  : note.createdBy.fullName}
              </b>
              {note.createdBy.id === currentUser.id && (
                <Button
                  minimal
                  small
                  intent="primary"
                  onClick={() => setIsEditing(true)}
                >
                  <EditButton>EDIT</EditButton>
                </Button>
              )}
            </CreatedBy>
            <Divider />
          </>
        )}
        {isEditing ? (
          <>
            <NoteHeader>
              <NoteTextWrapper>
                <TextArea
                  id="note"
                  type="text"
                  placeholder="Add a note..."
                  value={text}
                  onChange={e => setText(e.target.value)}
                  autoFocus={true}
                  growVertically
                  rows={3}
                  width="350px"
                />
              </NoteTextWrapper>
            </NoteHeader>
            <InputWrapper>
              <DateInput
                className="note-card-date-input"
                onChange={d => onReminderChange(d)}
                placeholder="Add an optional reminder..."
                popoverProps={{position: 'left'}}
                value={reminder}
                minDate={new Date()}
                maxDate={moment().add(10, 'years').toDate()}
                formatDate={date => moment(date).format('MMMM D, YYYY @ h A')}
                parseDate={str => new Date(str)}
                highlightCurrentDay={true}
                timePrecision="minute"
                timePickerProps={{
                  useAmPm: true,
                  defaultValue: reminder || new Date(2000, 1, 1, 9),
                }}
                closeOnSelection={false}
              />
            </InputWrapper>
            {users.length > 0 && (
              <InputWrapper>
                <SuggestMultiSelect
                  id="shareWith"
                  placeholder="Share this note..."
                  items={users.map(u => ({
                    id: u.id,
                    name: u.fullName,
                  }))}
                  filterable={true}
                  selectedItems={selectedUsers.map(userId => ({
                    id: userId,
                    name: users.find(u => u.id === userId)?.fullName,
                  }))}
                  onSelect={user => {
                    if (!selectedUsers.includes(user.id)) {
                      setSelectedUsers(prevSelectedUsers => [
                        ...prevSelectedUsers,
                        user.id,
                      ]);
                    }
                  }}
                  onRemove={(_, index) => {
                    const newList = [...selectedUsers];
                    newList.splice(index, 1);
                    setSelectedUsers(newList);
                  }}
                  enableNew={false}
                  fill
                  position="left"
                />
              </InputWrapper>
            )}

            <NoteFooter>
              <Button
                intent="danger"
                minimal
                icon="trash"
                onClick={() => onDelete(note.provision, note._id)}
              />
              <ButtonGroup minimal>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                  intent="primary"
                  disabled={!text}
                  onClick={() => {
                    onSave(
                      note.provision,
                      note._id,
                      text,
                      reminder,
                      selectedUsers
                    );
                    setIsEditing(false);
                  }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </NoteFooter>
          </>
        ) : (
          <>
            <NoteHeader>
              <NoteTextWrapper>
                <NoteText>{note.text}</NoteText>
              </NoteTextWrapper>
            </NoteHeader>
            {note.reminder && (
              <Reminder>
                <Icon icon="time" iconSize={14} /> Reminder set for{' '}
                {moment(note.reminder).format('lll')}
              </Reminder>
            )}
          </>
        )}
      </Card>
    </Comp>
  );
}

const Comp = styled.div``;

const CreatedBy = styled.div`
  padding: 5px 5px 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const NoteTextWrapper = styled.span`
  padding: 5px;
  overflow: hidden;

  textarea.bp3-input {
    width: 234px;
  }
`;

const NoteText = styled.div`
  max-width: 206px;
`;

const InputWrapper = styled.div`
  padding: 5px;
`;

const Reminder = styled.div`
  font-size: 0.8rem;
  color: ${colors.primary};
  font-style: italic;
  padding: 5px;
`;

const EditButton = styled.span`
  font-weight: bold;
  font-size: 0.8rem;
`;

const NoteFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
`;
