import React, {useState, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  FormGroup,
  Icon,
  InputGroup,
  Position,
  Spinner,
  Switch,
} from '@blueprintjs/core';
import {Tooltip2} from '@blueprintjs/popover2';
import styled from 'styled-components';
import {useStore} from 'react-hookstore';
import fetcher from '../modules/fetcher';
import notifications from '../modules/notifications';
import {colors, media, radius} from '../modules/styles';
import {
  filterJurisdictionsBySubscription,
  getNameByCode,
  jurisdictionCodes,
} from '../modules/jurisdictions';
import {ChecklistRequirementStatuses} from '../modules/options';
import FilterSelect from '../components/FilterSelect';
import ChecklistsTable from '../components/ChecklistsTable';
import ChecklistsTour from '../components/ChecklistsTour';

export default function ChecklistsPg() {
  const [currentUser] = useStore('currentUser');
  const [isMobile] = useStore('isMobile');

  const userJurisdictions = filterJurisdictionsBySubscription(
    currentUser,
    jurisdictionCodes
  ).map(jur => ({
    id: jur,
    name: getNameByCode(jur),
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [selectedJurisdiction, setSelectedJurisdiction] = useState(
    userJurisdictions.length === 1 ? userJurisdictions[0] : null
  );
  const [numEmployees, setNumEmployees] = useState(null);
  const [checklistData, setChecklistData] = useState(null);
  const [hideCompletedItems, setHideCompletedItems] = useState(false);

  useEffect(() => {
    const loadData = async (jurisdiction, employees) => {
      setIsLoading(true);
      try {
        const res = await fetcher.get('/api/checklists', {
          params: {
            jurisdiction,
            ...(numEmployees && {numEmployees: employees}),
          },
        });
        setChecklistData(res.data);
      } catch (e) {
        notifications.error(`Error loading menu data: ${e.message}`);
      }
      setIsLoading(false);
    };

    if (selectedJurisdiction) {
      loadData(selectedJurisdiction.id, numEmployees);
    }
  }, [selectedJurisdiction, numEmployees]);

  const onSelectJurisdiction = jurisdiction => {
    localStorage.setItem(
      'selectedJurisdictions',
      JSON.stringify([jurisdiction.id])
    );
    setSelectedJurisdiction(jurisdiction);
  };

  const handleChangeStatus = async (id, status) => {
    try {
      const updatedRequirement = (
        await fetcher.patch(
          `/api/checklists/${id}/accounts/${currentUser.account.id}`,
          {
            status,
          }
        )
      ).data;

      const updatedChecklist = [...checklistData];
      const updatedRequirementIndex = checklistData.findIndex(
        item => item.id === updatedRequirement.requirementId
      );
      updatedChecklist[updatedRequirementIndex].status =
        updatedRequirement.status;
      updatedChecklist[updatedRequirementIndex].lastUpdatedBy =
        updatedRequirement.lastUpdatedBy;

      setChecklistData(updatedChecklist);
    } catch (e) {
      notifications.error(`Error Updating Status: ${e.message}`);
    }
  };

  return (
    <Comp>
      <Helmet title="Checklists" />
      <ChecklistsTour isJurisdictionSelected={!!selectedJurisdiction} />

      <ContentSection>
        <FilterCol>
          <Filters>
            <InfoHeader>What are Checklists?</InfoHeader>

            <p>
              Checklists provide an overview of day-to-day HR compliance
              requirements in each jurisdiction where you have employees.
            </p>

            <p>
              Note: Checklists focus on your proactive obligations. They do not
              include requirements in response to specific events (e.g.,
              workplace accidents, investigations and inspections) or the labour
              relations process, which are covered in Info Hub.
            </p>
            <p>
              For a list of all the legislation we track, see{' '}
              <NavLink to={'scopeOfProduct'}>Scope of Product</NavLink>.
            </p>

            <p>
              <strong>
                To fully understand each requirement, make sure to click the
                link and read the applicable summary in Info Hub.
              </strong>
            </p>
          </Filters>
        </FilterCol>

        <Content>
          <Header>
            <h1>Checklists</h1>
            <p>
              Checklists are collaborative. All subscribers at{' '}
              <b>your organization</b> will work from the same checklist(s).
            </p>
          </Header>
          <div data-tour="checklists-inputs">
            {userJurisdictions?.length > 1 && (
              <FormInput>
                <FormGroup
                  inline={!isMobile}
                  label={'Select a jurisdiction'}
                  labelInfo={
                    <Tooltip2
                      content={
                        <TooltipContent>
                          <p>
                            Select a jurisdiction where your employees work.
                            Typically, the law of the province where an employee
                            works will apply to that employee.
                          </p>
                          <p>
                            Since certain requirements are only triggered once
                            you have a minimum number of employees in that
                            jurisdiction, you can only create a Checklist for
                            one jurisdiction at a time.
                          </p>
                        </TooltipContent>
                      }
                      position="bottom"
                      intent="primary"
                    >
                      <Icon icon="info-sign" size={12} />
                    </Tooltip2>
                  }
                >
                  <FilterSelect
                    id="jurisdiction"
                    items={userJurisdictions}
                    activeItem={{id: selectedJurisdiction?.id}}
                    onSelect={onSelectJurisdiction}
                    position={isMobile ? Position.BOTTOM : Position.RIGHT}
                  >
                    <Selected>
                      <span>{selectedJurisdiction?.name}</span>
                      <Icon icon="caret-down" />
                    </Selected>
                  </FilterSelect>
                </FormGroup>
              </FormInput>
            )}

            <FormInput>
              <FormGroup
                inline={!isMobile}
                label={
                  <span>
                    Number of employees <em>(Optional)</em>
                  </span>
                }
                labelFor="numEmployees"
                labelInfo={
                  <Tooltip2
                    content={
                      <TooltipContent>
                        {userJurisdictions?.length > 1 ? (
                          <p>
                            Some requirements are triggered based on the number
                            of employees you employ in each jurisdiction. To
                            determine which requirements apply to your
                            workplace, enter the number of employees that work
                            in the jurisdiction you have selected.
                          </p>
                        ) : (
                          <p>
                            Some requirements are triggered based on the number
                            of employees you employ. Enter the number of
                            employees you employ, to determine which
                            requirements apply to your workplace.
                          </p>
                        )}
                        <p>
                          If you are unsure whether someone should be included
                          in your employee count, review the Definitions of
                          Employee/Worker in Application under Employment
                          Standards and Health & Safety.
                        </p>
                        <p>
                          Note: For public sector employers, many requirements
                          apply regardless of the number of employees in your
                          workplace.
                        </p>
                      </TooltipContent>
                    }
                    position="bottom"
                    intent="primary"
                  >
                    <Icon icon="info-sign" size={12} />
                  </Tooltip2>
                }
              >
                <InputGroup
                  id="numEmployees"
                  type="number"
                  placeholder=""
                  value={numEmployees || ''}
                  onChange={e => setNumEmployees(e.target.value)}
                  min={1}
                />
              </FormGroup>
            </FormInput>
          </div>

          <Box>
            {!isLoading && !checklistData && (
              <NoItems>Select a province to see your Checklist.</NoItems>
            )}

            {isLoading && <Spinner />}

            {checklistData?.length === 0 && !isLoading && (
              <NoItems>
                There are no requirements for the selected criteria.
              </NoItems>
            )}

            {!isLoading && checklistData?.length > 0 && (
              <TableContainer>
                <ContentHeader>
                  Checklist for {selectedJurisdiction.name}
                </ContentHeader>

                <Switch
                  checked={hideCompletedItems}
                  label={'Hide completed items'}
                  alignIndicator="right"
                  onChange={() => setHideCompletedItems(!hideCompletedItems)}
                />
                <ChecklistsTable
                  items={
                    hideCompletedItems
                      ? checklistData.filter(
                          item =>
                            item.status !== ChecklistRequirementStatuses.Done
                        )
                      : checklistData
                  }
                  onChangeStatus={handleChangeStatus}
                />
              </TableContainer>
            )}
          </Box>
        </Content>
      </ContentSection>
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (min-width: ${media.lg}) {
    flex-direction: row;
    padding: 0px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;

  @media (min-width: ${media.lg}) {
    margin: 20px 46px 0 60px;
    padding: 0;
  }
`;

const Header = styled.div`
  margin-bottom: 18px;
`;

const FilterCol = styled.div`
  width: 100%;

  @media (min-width: ${media.lg}) {
    width: 260px;
    min-width: 260px;
  }
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${media.lg}) {
    flex-direction: row;
  }

  a {
    color: ${colors.primary};
    text-decoration: underline;
  }
`;

const FormInput = styled.div`
  .bp3-inline {
    justify-content: flex-start;
  }

  .bp3-label {
    min-width: 275px;

    .bp3-text-muted .bp3-popover2-target {
      display: inline-block;
      margin-top: 0px;

      svg {
        margin-bottom: 2px;
      }
    }
  }
`;

const Box = styled.div`
  border: 1px solid ${colors.gray2};
  background-color: white;
  border-radius: ${radius};
  padding: 15px 30px;
  min-width: 100%;
  max-width: 1100px;
  margin-bottom: 30px;
  overflow-x: auto;
`;

const NoItems = styled.div`
  font-style: italic;
`;

const TableContainer = styled.div`
  .bp3-switch {
    width: fit-content;
    margin-left: auto;
  }
`;

const ContentHeader = styled.h2`
  text-align: center;
  margin: 5px 0px 15px 0px;
`;

const InfoHeader = styled.h2`
  font-size: 1.2rem;
  margin: 0 0 20px 0;
`;

const TooltipContent = styled.div`
  width: 300px;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (min-width: ${media.lg}) {
    min-width: 220px;
    max-width: 220px;
    margin: 92px 0px 0 50px;
    padding: 0;
  }
`;

const Selected = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colors.gray2};
  border-radius: ${radius};
  background-color: white;
  padding: 5px 10px;
  min-width: 210px;
  max-width: 210px;
  cursor: pointer;
`;
