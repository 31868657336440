import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {NavLink, Route} from 'react-router-dom';
import SignupPlanSelectionPg from './SignupPlanSelectionPg';
import SignupCustomizePlanPg from './SignupCustomizePlanPg';
import SignupAccountPg from './SignupAccountPg';
import SignupConfirmationPg from './SignupConfirmationPg';
import SignupPasswordPg from './SignupPasswordPg';

export default function SignupPg() {
  const [data, setData] = useState({areas: [], jurisdictions: []});

  useEffect(() => {
    // Pull options from localStorage. We're storing it here in case they refresh the page during sign-up.
    const savedStr = sessionStorage.getItem('signup');
    if (savedStr) {
      const savedObj = JSON.parse(savedStr);
      setData(savedObj);
    }
  }, []);

  const onUpdate = (newOpts, reset = false) => {
    setData(d => {
      const newData = reset ? newOpts : {...d, ...newOpts};
      sessionStorage.setItem('signup', JSON.stringify(newData));
      return newData;
    });
  };

  return (
    <Comp>
      <Content>
        <Route
          exact
          path="/signup/start"
          render={() => <SignupPlanSelectionPg onUpdate={onUpdate} />}
        />
        <Route
          exact
          path="/signup/customize"
          render={() => (
            <SignupCustomizePlanPg onUpdate={onUpdate} data={data} />
          )}
        />
        <Route
          exact
          path="/signup/account"
          render={() => <SignupAccountPg data={data} />}
        />
        <Route
          exact
          path="/signup/confirmation"
          render={() => <SignupConfirmationPg />}
        />
        <Route
          exact
          path="/signup/password/:code"
          render={props => <SignupPasswordPg {...props} />}
        />
        <Route
          exact
          path="/signup/welcome/:code"
          render={props => <SignupPasswordPg {...props} showSteps={false} />}
        />

        <Footer>
          <NavLink to="/privacy" target="_blank" rel="no-opener no-referrer">
            Privacy Policy
          </NavLink>{' '}
          <NavLink to="/terms" target="_blank" rel="no-opener no-referrer">
            Terms &amp; Conditions
          </NavLink>
        </Footer>
      </Content>
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 40px 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0 15px;

  @media (min-width: 600px) {
    max-width: 1200px;
    margin: 0 50px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;

  a {
    margin: 0 10px;
    text-decoration: underline;
  }
`;
