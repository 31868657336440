import React, {useState} from 'react';
import Tour from 'reactour';
import {colors} from '../modules/styles';
import useLocalStorage from '../hooks/useLocalStorage';
import Button from './Button';

export default function ReportBuilderTour(props) {
  const [hasSeen, setHasSeen] = useLocalStorage('reportBuilderTourSeen', false);
  const [isOpen, setIsOpen] = useState(!hasSeen);

  const steps = [
    {
      selector: '',
      content: (
        <div>
          <p>
            Welcome to the <strong>Info Hub</strong>.
          </p>
          <p>
            This quick feature tour will show you how to find the information
            you're looking for and how to save custom reports.
          </p>
          <p>Click the arrow or use your keyboard's arrow keys to continue.</p>
        </div>
      ),
    },
    {
      selector: '[data-tour="help-menu"]',
      content: (
        <p>
          You can revisit this tour at any time by clicking here and selecting{' '}
          <strong>Restart Feature Tours</strong>.
        </p>
      ),
    },
    {
      selector: '[data-tour="filters"]',
      content:
        "To get started, use these filters to find what you're looking for.",
    },
    {
      selector: '[data-tour="filters"]',
      content:
        'Filtering by Topic, Provision and Jurisdiction allows you to drill down into a specific Area of Law to find relevant content.',
    },
    {
      selector: '[data-tour="add-to-report"]',
      content: (
        <>
          <p>
            Once you've found the information you're looking for, you can create
            a custom report which will save that information for quick access
            later.
          </p>
          <p>
            In addition, you will receive email notifications when any content
            within your custom reports change. This helps to ensure you're
            always up-to-date with the information you care about most.
          </p>
        </>
      ),
    },
    {
      selector: '[data-tour="note"]',
      content:
        'You can add a Note/Reminder about any Provision, and set a date for an email to be sent reminding you.',
    },
    {
      selector: '[data-tour="reports-nav-button"]',
      content: "After you've created a custom report, you can access it here.",
    },
    {
      selector: '[data-tour="reports-nav-button"]',
      content:
        "You can also access all of the provisions you've left a note or reminder on from within this section.",
    },
    {
      selector: '[data-tour="search"]',
      content: 'You can also use Search to find information in Info Hub.',
    },
    {
      selector: '',
      content: (
        <div>
          <p>
            You're all set! If you have any further questions, just let us know.
          </p>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Tour
        steps={steps}
        accentColor={colors.secondary}
        rounded={3}
        isOpen={isOpen}
        startAt={0}
        closeWithMask={false}
        disableInteraction={true}
        onRequestClose={() => {
          setIsOpen(false);
          setHasSeen(true);
        }}
        lastStepNextButton={
          <Button
            onClick={() => {
              setIsOpen(false);
              setHasSeen(true);
            }}
          >
            End Tour
          </Button>
        }
        {...props}
      />
    </React.Fragment>
  );
}
