import ENV from '../environment';
import axios from 'axios';

class Fetcher {
  constructor() {
    this.instance = axios.create({baseURL: ENV.SERVER_HOST});

    this.get = this.instance.get;
    this.post = this.instance.post;
    this.patch = this.instance.patch;
    this.delete = this.instance.delete;

    this.csrfToken = null;
    this.token = localStorage.getItem('token');

    this.setInterceptor = this.setInterceptor.bind(this);
    this.setInterceptor(this.instance);
  }

  setCSRFToken(token) {
    this.csrfToken = token;
  }

  setToken(token) {
    if (token) {
      // Change the active token
      this.token = token;

      // Persist for later
      localStorage.setItem('token', token);
    }
  }

  setInterceptor(instance) {
    instance.interceptors.request.use(req => {
      // Add the CSRF Token to our local requests but not to external requests. Note that
      // baseURL will have a valid url during dev but be blank in Staging and Prod, so we can't
      // rely on just the startsWith() check. This was the source of a bug we had to track down.
      if (req.url) {
        if (
          (req.url.startsWith('/') ||
            (this.instance.defaults.baseURL &&
              req.url.startsWith(this.instance.defaults.baseURL))) &&
          this.csrfToken
        ) {
          req.headers['X-CSRF-Token'] = this.csrfToken;
        }
      }

      // Add the token
      if (this.token) {
        req.headers.authorization = `Bearer ${this.token}`;
      }

      return req;
    });

    instance.interceptors.response.use(
      res => {
        // Re-save the token every time since it may have been refreshed
        // console.log('incoming headers', res.headers);
        if (res.headers.authorization) {
          const newToken = res.headers.authorization.substr(7).trim();
          if (this.token !== newToken) {
            this.setToken(newToken);
          }
        }
        return res;
      },
      err => {
        if (err.response && err.response.status === 401) {
          // User has become unauthed, so redirect to login page
          window.location.href = '/login';
        }
        return Promise.reject(err.response); // https://github.com/axios/axios/issues/960#issuecomment-320659373
      }
    );
  }
}

export default new Fetcher();
