import React, {Component} from 'react';
import styled from 'styled-components';
import {colors} from '../modules/styles';
import {FormGroup, InputGroup, Intent} from '@blueprintjs/core';

const Comp = styled(FormGroup)`
  .bp3-label .bp3-text-muted {
    font-size: 0.7rem;
    margin-left: 5px;
  }

  &.fill {
    width: 100%;
  }

  input {
    box-shadow: none;
    border: 1px solid ${colors.gray2};
  }

  input:focus {
    box-shadow: 0 0 0 2px rgba(238, 128, 50, 0.8),
      0 0 0 3px rgba(238, 128, 50, 0.5), inset 0 1px 1px rgba(238, 128, 50, 0.2);
    outline: none !important;
  }
`;

class Input extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <Comp
        className={
          this.props.fill
            ? `${this.props.className} fill`
            : this.props.className
        }
        disabled={this.props.disabled}
        helperText={
          this.props.hasError ? this.props.errorMsg : this.props.helperText
        }
        inline={this.props.inline}
        intent={this.props.hasError ? Intent.DANGER : this.props.intent}
        label={this.props.label}
        labelFor={this.props.labelFor}
        labelInfo={this.props.labelInfo}
      >
        <InputGroup
          defaultValue={this.props.defaultValue}
          disabled={this.props.disabled}
          inputRef={this.props.inputRef}
          intent={this.props.hasError ? Intent.DANGER : this.props.intent}
          large={this.props.large}
          leftIcon={this.props.leftIcon}
          name={this.props.name}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          rightElement={this.props.rightElement}
          round={this.props.round}
          type={this.props.type}
          value={this.props.value}
        />
      </Comp>
    );
  }
}

export default Input;
