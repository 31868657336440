import {Checkbox, Icon} from '@blueprintjs/core';
import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import notifications from '../modules/notifications';
import {colors} from '../modules/styles';
import {isValidPassword} from '../modules/validation';
import Button from './Button';
import Input from './Input';

export default function PasswordReset({onSubmit, showTerms = false}) {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [hasTermsError, setHasTermsError] = useState(false);

  const onResetPassword = e => {
    e.preventDefault();

    if (showTerms && !isTermsChecked) {
      setHasTermsError(true);
      return;
    }

    if (isValidPassword(password) && password === password2) {
      onSubmit(password);
    } else {
      notifications.error('Invalid password format. Please try again.');
    }
  };

  const validPassword = isValidPassword(password) && password === password2;
  const hasUpper = password.match(/[A-Z]/);
  const hasLower = password.match(/[a-z]/);
  const hasNumber = password.match(/[0-9]/);
  const hasLength = password.length >= 8;
  const fieldsMatch = password.length > 0 && password === password2;

  return (
    <Comp>
      <p>
        Passwords must be at least 8 characters with both uppercase and
        lowercase letters and at least one number.
      </p>
      <form onSubmit={onResetPassword}>
        <Input
          label="Enter a New Password:"
          type="password"
          name="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          leftIcon="lock"
          large={true}
          fill={true}
        />
        <Input
          label="Enter the New Password Again:"
          type="password"
          name="password2"
          value={password2}
          onChange={e => setPassword2(e.target.value)}
          leftIcon="lock"
          large={true}
          fill={true}
        />
        <Checks>
          <ValidCheck>
            <Icon
              icon={hasUpper ? 'tick-circle' : 'circle'}
              intent={hasUpper ? 'success' : 'danger'}
            />
            Uppercase Letters Detected
          </ValidCheck>
          <ValidCheck>
            <Icon
              icon={hasLower ? 'tick-circle' : 'circle'}
              intent={hasLower ? 'success' : 'danger'}
            />
            Lowercase Letters Detected
          </ValidCheck>
          <ValidCheck>
            <Icon
              icon={hasNumber ? 'tick-circle' : 'circle'}
              intent={hasNumber ? 'success' : 'danger'}
            />
            Numbers Detected
          </ValidCheck>
          <ValidCheck>
            <Icon
              icon={hasLength ? 'tick-circle' : 'circle'}
              intent={hasLength ? 'success' : 'danger'}
            />
            At Least 8 Characters Detected
          </ValidCheck>
          <ValidCheck>
            <Icon
              icon={fieldsMatch ? 'tick-circle' : 'circle'}
              intent={fieldsMatch ? 'success' : 'danger'}
            />
            Password Fields Match Each Other
          </ValidCheck>
        </Checks>

        {showTerms && (
          <>
            <h2>Terms &amp; Conditions</h2>
            <Terms>
              <Checkbox
                checked={isTermsChecked}
                large={true}
                onChange={() => setIsTermsChecked(!isTermsChecked)}
              />
              <div>
                <p>
                  By submitting this information I confirm that I have read the{' '}
                  <NavLink
                    to="/privacy"
                    target="_blank"
                    rel="no-opener no-referrer"
                  >
                    Privacy Policy
                  </NavLink>{' '}
                  and{' '}
                  <NavLink
                    to="/terms"
                    target="_blank"
                    rel="no-opener no-referrer"
                  >
                    Terms &amp; Conditions
                  </NavLink>{' '}
                  and agree to be bound by their terms.
                </p>

                {hasTermsError && (
                  <SmallRed>
                    <Icon icon="warning-sign" />
                    You must agree to the above before continuing.
                  </SmallRed>
                )}
              </div>
            </Terms>
          </>
        )}

        <Buttons>
          <Button
            rightIcon="chevron-right"
            type="submit"
            disabled={!validPassword || (showTerms && !isTermsChecked)}
            intent="warning"
          >
            Set Password &amp; Continue
          </Button>
        </Buttons>
      </form>
    </Comp>
  );
}

const Comp = styled.div`
  .bp3-form-group {
    margin-bottom: 30px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
`;

const Checks = styled.div`
  width: 100%;
  margin: 0 0 30px 0;
`;

const ValidCheck = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 0.9rem;

  .bp3-icon {
    margin-right: 10px;
    font-size: 16px;
  }
`;

const Terms = styled.div`
  display: flex;

  a {
    color: ${colors.secondary};
    text-decoration: underline;
  }
`;

const SmallRed = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  background-color: ${colors.redCalloutLight};
  color: ${colors.redCallout};
  padding: 5px;
  margin: 0 0 10px 0;

  .bp3-icon {
    margin-right: 10px;
  }
`;
