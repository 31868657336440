import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Icon, Position} from '@blueprintjs/core';
import moment from 'moment';
import {colors} from '../modules/styles';
import fetcher from '../modules/fetcher';
import {Popover2} from '@blueprintjs/popover2';

let timer;

export default function Notifications() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    // Do initial menu loading
    const loadData = async () => {
      try {
        const res = await fetcher.get('/api/notifications/mine');
        setItems(res.data);
      } catch (e) {
        // Fail silently since it's not super important
      }
    };

    loadData();

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const onOpen = () => {
    // Mark the items as read
    if (items[0]) {
      timer = setTimeout(async () => {
        const res = await fetcher.patch('/api/notifications/mine', {
          lastSeen: items[0].id,
        });
        setItems(res.data);
      }, 3000);
    }
  };

  const onClose = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  const unseen = items.filter(i => i.seen === false);

  return (
    <Comp>
      <Popover2
        onOpened={onOpen}
        onClosed={onClose}
        position={Position.BOTTOM}
        content={
          <Items>
            {items.length === 0 && <div>You're all caught up!</div>}
            {items.map(item => (
              <React.Fragment key={item.id}>
                <Item className={item.seen ? 'seen' : 'unseen'}>
                  <Date>
                    {moment(item.createdAt).format('MMMM Do')}{' '}
                    {!item.seen && <SmallDot />}
                  </Date>

                  {item.link ? (
                    <URLTitle href={item.link}>{item.title}</URLTitle>
                  ) : (
                    <Title>{item.title}</Title>
                  )}

                  <Text dangerouslySetInnerHTML={{__html: item.text}} />
                </Item>
                <hr />
              </React.Fragment>
            ))}
          </Items>
        }
      >
        <IconButton className={unseen.length ? 'has-items' : ''}>
          {unseen.length > 0 && <Dot />}
          <Icon icon="notifications" iconSize={18} />
        </IconButton>
      </Popover2>
    </Comp>
  );
}

const Comp = styled.div`
  display: none;

  @media (min-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 20px;
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 10px;
  color: white;
  position: relative;
  outline: none;
  cursor: pointer;

  &.has-items {
    color: white;
  }
`;

const SmallDot = styled.div`
  width: 7px;
  height: 7px;
  background-color: ${colors.success};
  border-radius: 50%;
`;

const Dot = styled.div`
  position: absolute;
  top: 7px;
  right: 0px;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: white;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${colors.success};
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
`;

const Items = styled.div`
  padding: 10px;

  hr {
    border: none;
    border-bottom: 1px solid ${colors.gray3};
  }
`;

const Item = styled.div`
  max-width: 300px;
  margin: 10px 0;
  padding: 10px 10px 10px 10px;

  &.unseen {
    background-color: ${colors.gray4};
  }
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.7rem;
  margin: 0 0 5px 0;
`;

const Title = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 20px 0;
`;

const URLTitle = styled.a`
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 20px 0;
  color: ${colors.primary};

  &:hover {
    color: ${colors.primary};
  }
`;

const Text = styled.div`
  font-size: 0.9rem;
`;
