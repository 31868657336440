import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import orderBy from 'lodash.orderby';
import {Icon, MenuItem} from '@blueprintjs/core';
import {Select} from '@blueprintjs/select';
import {Tooltip2} from '@blueprintjs/popover2';
import {useStore} from 'react-hookstore';
import BillTrackerTableColumnHeading from './BillTrackerTableColumnHeading';
import {ChecklistRequirementStatuses} from '../modules/options';
import {colors, radius} from '../modules/styles';

export default function ChecklistsTable({items, onChangeStatus}) {
  const [sortBy, setSortBy] = useState('area.name');
  const [sortDir, setSortDir] = useState('asc');
  const [openedStatusDropdown, setOpenedStatusDropdown] = useState(null);
  const [currentUser] = useStore('currentUser');

  const changeSort = field => {
    field === sortBy && sortDir === 'asc'
      ? // Flip the direction
        setSortDir('desc')
      : setSortDir('asc');

    setSortBy(field);
  };

  // Sort the results
  const sorted = orderBy(
    items,
    [sortBy, 'area.name', 'requirementType'],
    sortDir
  );

  return (
    <Comp>
      <table className="bp3-html-table bp3-interactive">
        <TableHeader>
          <tr>
            <BillTrackerTableColumnHeading
              onClick={changeSort}
              label={
                <>
                  <span>Status</span>
                  <Tooltip2
                    content={
                      <TooltipContent>
                        <p>
                          You can track the status of your compliance with each
                          requirement - Not Started, In Progress, Done. Your
                          selections will be saved for the next time you login.
                        </p>
                      </TooltipContent>
                    }
                    position="bottom"
                    intent="primary"
                  >
                    <Icon
                      icon="info-sign"
                      size={12}
                      className="bp3-text-muted"
                    />
                  </Tooltip2>
                </>
              }
              field="status"
              currentSort={sortBy}
              currentSortDir={sortDir}
              width="13%"
              showSortIcon={true}
            />
            <BillTrackerTableColumnHeading
              label={
                <>
                  <span>Requirement</span>
                  <Tooltip2
                    content={
                      <TooltipContent>
                        <p>
                          Requirements refer to the HR compliance requirements
                          in each jurisdiction where you have employees.
                        </p>
                      </TooltipContent>
                    }
                    position="bottom"
                    intent="primary"
                  >
                    <Icon
                      icon="info-sign"
                      size={12}
                      className="bp3-text-muted"
                    />
                  </Tooltip2>
                </>
              }
              field="requirement"
              currentSort={sortBy}
              currentSortDir={sortDir}
              width="30%"
            />
            <BillTrackerTableColumnHeading
              onClick={changeSort}
              label="Type of Requirement"
              field="requirementType"
              data-tour="requirement-type-header"
              currentSort={sortBy}
              currentSortDir={sortDir}
              width="22%"
              showSortIcon={true}
            />
            <BillTrackerTableColumnHeading
              onClick={changeSort}
              label="Area of Law"
              field="area.name"
              data-tour="area-of-law-header"
              currentSort={sortBy}
              currentSortDir={sortDir}
              width="15%"
              showSortIcon={true}
            />
            <BillTrackerTableColumnHeading
              onClick={changeSort}
              label={
                <>
                  <span>Last Updated</span>
                  <Tooltip2
                    content={
                      <TooltipContent>
                        <p>
                          Last Updated shows you the last time that this
                          requirement was amended in Info Hub.
                        </p>
                      </TooltipContent>
                    }
                    position="bottom"
                    intent="primary"
                  >
                    <Icon
                      icon="info-sign"
                      size={12}
                      className="bp3-text-muted"
                    />
                  </Tooltip2>
                </>
              }
              field="lastUpdated"
              data-tour="last-updated-header"
              currentSort={sortBy}
              currentSortDir={sortDir}
              width="20%"
              showSortIcon={true}
            />
          </tr>
        </TableHeader>
        <tbody>
          {sorted?.map(
            ({
              id,
              requirementType,
              requirement,
              lastUpdated,
              area,
              status,
              href,
              lastUpdatedBy,
            }) => (
              <tr key={id}>
                <td data-tour="requirement-status">
                  <Tooltip2
                    content={<span>Last updated by {lastUpdatedBy}</span>}
                    position="bottom"
                    disabled={!lastUpdatedBy || id === openedStatusDropdown}
                    intent="primary"
                    usePortal={false}
                  >
                    <Select
                      id="status"
                      items={Object.keys(ChecklistRequirementStatuses)}
                      activeItem={ChecklistRequirementStatuses[status]}
                      onItemSelect={async item => onChangeStatus(id, item)}
                      filterable={false}
                      popoverProps={{
                        rootBoundary: 'document',
                        position: 'bottom',
                        portalClassName: 'filter-select-popover',
                        onOpening: () => setOpenedStatusDropdown(id), // Needed to prevent tooltip and select dropdown from both being open
                        onClosed: () => setOpenedStatusDropdown(null),
                      }}
                      itemRenderer={(item, {handleClick, modifiers}) => {
                        if (!modifiers.matchesPredicate) {
                          return null;
                        }
                        return (
                          <MenuItem
                            active={modifiers.active}
                            key={item}
                            text={ChecklistRequirementStatuses[item]}
                            onClick={handleClick}
                          />
                        );
                      }}
                    >
                      <Selected>
                        <span>{ChecklistRequirementStatuses[status]}</span>
                        <Icon icon="caret-down" />
                      </Selected>
                    </Select>
                  </Tooltip2>
                </td>
                <td>
                  {href ? (
                    currentUser.account.selectedAreas.includes(area?.id) ||
                    currentUser.isStaff ? (
                      <NavLink to={href} target="_blank">
                        {requirement}
                      </NavLink>
                    ) : (
                      <Tooltip2
                        content={
                          <TooltipContent>
                            You do not have access to this Area of Law. If you
                            would like to access more Areas of Law during your
                            free trial, please{' '}
                            <a
                              href="mailto:info@complianceworks.ca"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              contact us.
                            </a>
                          </TooltipContent>
                        }
                        position="bottom"
                        intent="primary"
                        interactionKind="hover"
                        hoverCloseDelay={100}
                      >
                        <RequirementText>{requirement}</RequirementText>
                      </Tooltip2>
                    )
                  ) : (
                    requirement
                  )}
                </td>
                <td>{requirementType}</td>
                <td>{area?.name}</td>
                <td>{moment(lastUpdated).format('MMMM DD, YYYY')}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </Comp>
  );
}

const Comp = styled.div`
  margin-bottom: 40px;

  table {
    min-width: 100%;
  }

  th {
    min-width: fit-content;
  }

  td {
    max-width: 400px;
  }
`;

const Selected = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colors.gray2};
  border-radius: ${radius};
  background-color: white;
  padding: 5px 10px;
  min-width: 150px;
  max-width: 150px;
  cursor: pointer;
`;

const TableHeader = styled.thead`
  .bp3-popover2-target .bp3-icon {
    vertical-align: middle;
    margin-left: 5px;
    margin-top: -2px;
    cursor: default;
  }
`;

const RequirementText = styled.span`
  color: ${colors.primary};
  text-decoration: underline;
`;

const TooltipContent = styled.div`
  width: 300px;

  a {
    color: ${colors.secondary};
  }
`;
