import React from 'react';
import styled from 'styled-components';
import {Icon} from '@blueprintjs/core';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import {colors, radius, media} from '../modules/styles';
import DesktopOnly from './DesktopOnly';
import MobileOnly from './MobileOnly';
import AddToReportButton from './AddToReportButton';

export default function Update({update, createReport, appendToReport}) {
  const {date, changeType, provision, jurisdiction, area, topic, url, bill} =
    update;

  return (
    <Comp>
      <DateHolder>
        {moment(date).format('MMMM DD, YYYY')}
        <ChangeType>{changeType}</ChangeType>
        <AddToReportButton
          onCreate={name => createReport(name, [provision?.id])}
          onAppend={reportId => appendToReport(reportId, [provision?.id])}
        >
          <AddToReportLink onClick={e => e.preventDefault()}>
            <Icon icon="add" iconSize={12} /> Add To Report
          </AddToReportLink>
        </AddToReportButton>
      </DateHolder>
      <UpdateData>
        <span>
          <Name to={url}>
            {topic.name}: {provision.name}
          </Name>
          <MobileOnly> ({jurisdiction})</MobileOnly>
        </span>

        <Topic>{area.name}</Topic>

        {typeof bill === 'string' ? (
          <Bill
            dangerouslySetInnerHTML={{
              __html: bill,
            }}
          />
        ) : (
          <Bill>{bill}</Bill>
        )}
      </UpdateData>

      <DesktopOnly>
        <Jur>
          <Icon icon="map" iconSize={12} /> {jurisdiction}
        </Jur>
      </DesktopOnly>
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0px 0;
  padding: 20px 0 10px 0;
  border-bottom: 1px solid ${colors.gray3};

  @media (min-width: ${media.lg}) {
    flex-direction: row;
    padding: 20px 0;
  }
`;

const DateHolder = styled.div`
  font-size: 1rem;
  margin-right: 30px;
  min-width: 150px;
  max-width: 150px;
  font-weight: 600;
`;

const ChangeType = styled.div`
  font-size: 0.8rem;
  font-weight: normal;
  margin: 5px 0 0 0;
`;

const UpdateData = styled.div`
  width: 100%;
  margin: 20px 0;

  @media (min-width: ${media.lg}) {
    flex-direction: row;
    margin: 0;
  }
`;

const Name = styled(NavLink)`
  font-weight: bold;
  @media (min-width: ${media.lg}) {
    font-weight: normal;
  }
`;

const Topic = styled.div`
  font-size: 1rem;
`;

const Jur = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
  margin: 0px 0 0 0;
  font-weight: normal;

  .bp3-icon {
    margin-right: 5px;
    color: ${colors.primary};
  }
`;

const Bill = styled.div`
  margin: 20px 0 0 0;
`;

const AddToReportLink = styled.button`
  display: none;

  @media (min-width: ${media.lg}) {
    display: flex;
    border: none;
    border-radius: ${radius};
    color: ${colors.yellowCallout};
    font-size: 0.8rem;
    margin: 10px 0 0 -6px;
    padding: 4px 6px;
    cursor: pointer;
    text-align: left;

    &:hover {
      background-color: ${colors.yellowCalloutLight};
    }

    .bp3-icon {
      color: ${colors.yellowCallout};
      margin-right: 8px;
    }
  }
`;
