import React from 'react';
import styled from 'styled-components';
import SignupSteps from './SignupSteps';
import logo from '../assets/logo.svg';
import {colors} from '../modules/styles';

export default function SignupHeader({
  step = 1,
  disabled = false,
  showSteps = true,
}) {
  return (
    <Comp>
      <a href="https://complianceworks.ca/">
        <img src={logo} alt="Compliance Works" />
      </a>
      {showSteps && <SignupSteps current={step} disabled={disabled} />}
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0;
  border-bottom: 1px solid ${colors.gray2};
  margin: 0 0 20px 0;

  img {
    margin: 0px;
    width: 200px;
  }

  @media (min-width: 950px) {
    padding: 15px 0;
  }
`;
