import React, {useState} from 'react';
import Tour from 'reactour';
import {colors} from '../modules/styles';
import useEvent from '../hooks/useEvent';
import useLocalStorage from '../hooks/useLocalStorage';
import Button from './Button';
import styled from 'styled-components';

export default function UpdatesTour(props) {
  const [hasSeen, setHasSeen] = useLocalStorage('updatesTourSeen', false);
  const [isOpen, setIsOpen] = useState(!hasSeen);

  useEvent('tour:start', () => {
    setHasSeen(false);
    setIsOpen(true);
  });

  const steps = [
    {
      selector: '',
      content: (
        <div>
          <p>
            Welcome to <strong>Compliance Works</strong>.
          </p>
          <p>
            This quick feature tour will show you how to find the information
            you're looking for.
          </p>
          <p>Click the arrow or use your keyboard's arrow keys to continue.</p>
        </div>
      ),
    },
    {
      selector: '[data-tour="help-menu"]',
      content: (
        <p>
          You can revisit this tour at any time by clicking here and selecting{' '}
          <strong>Restart Feature Tours</strong>.
        </p>
      ),
    },
    {
      selector: '[data-tour="reminders"]',
      content:
        'Reminders provide information on upcoming deadlines that are included in legislation in the next 60 days.',
    },
    {
      selector: '[data-tour="amendments"]',
      content:
        'Amendments provide a quick snapshot of all changes to Acts and regulations in the previous 60 days.',
    },
    {
      selector: '[data-tour="filters"]',
      content:
        'You can filter which amendments you see with the options here. And you can create reports of recent amendments.',
    },
    {
      selector: '[data-tour="updated-reports"]',
      content:
        'If any of your saved reports have been affected by recent updates, those reports will be listed here.',
    },
    {
      selector: '[data-tour="announcements"]',
      content:
        'Recent announcements from Compliance Works are shown here. They are also available via the bell icon in the header.',
    },
    {
      selector: '[data-tour="builder-nav-button"]',
      content:
        'The Info Hub allows you to search for the information you need and to save custom reports.',
    },
    {
      selector: '[data-tour="checklists-nav-button"]',
      content:
        'Checklists map out your HR compliance obligations in each jurisdiction where you have employees.',
    },
    {
      selector: '[data-tour="reports-nav-button"]',
      content: "After you've created a custom report, you can access it here.",
    },
    {
      selector: '[data-tour="help-menu"]',
      content: (
        <div>
          <p>
            In addition to receiving notifications about changes to your custom
            reports, you can add entire Areas of Law or Topics to your
            Watchlist.
          </p>
          <p>
            Items on your Watchlist will also trigger email notifications
            whenever they change.
          </p>
          <p>
            You can manage your Watchlist in the "Preferences" section of this
            menu.
          </p>
        </div>
      ),
    },
    {
      selector: '',
      content: (
        <div>
          <p>
            You're all set! If you have any further questions, just let us know.
          </p>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <StyledTour
        steps={steps}
        accentColor={colors.secondary}
        rounded={3}
        isOpen={isOpen}
        startAt={0}
        closeWithMask={false}
        disableInteraction={true}
        onRequestClose={() => {
          setIsOpen(false);
          setHasSeen(true);
        }}
        lastStepNextButton={
          <Button
            onClick={() => {
              setIsOpen(false);
              setHasSeen(true);
            }}
          >
            End Tour
          </Button>
        }
        {...props}
      />
    </React.Fragment>
  );
}

const StyledTour = styled(Tour)`
  max-width: 332px;
`;
