import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import {useStore} from 'react-hookstore';
import {NavLink} from 'react-router-dom';
import fetcher from '../modules/fetcher';
import notifications from '../modules/notifications';
import {colors, radius, media} from '../modules/styles';
import {
  Switch,
  Icon,
  Spinner,
  FormGroup,
  Position,
  Collapse,
} from '@blueprintjs/core';
import moment from 'moment';
import NotificationsFeed from '../components/NotificationsFeed';
import JurisdictionPicker from '../components/JurisdictionPicker';
import UpdatesTour from '../components/UpdatesTour';
import {jurisdictionCodes, getNameByCode} from '../modules/jurisdictions';
import uniq from 'lodash.uniq';
import {DateRangeInput} from '@blueprintjs/datetime';
import Button from '../components/Button';
import AddToReportButton from '../components/AddToReportButton';
import useAddToReport from '../hooks/useAddToReport';
import Update from '../components/Update';

const SHOW_STAFF_ONLY_EMAIL_FEATURE = false;

export default function UpdatesPg() {
  const [currentUser] = useStore('currentUser');
  const [emailSinceDate, setEmailSinceDate] = useState([null, null]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isManualEmailSubmitted, setIsManualEmailSubmitted] = useState(false);
  const [isMobile] = useStore('isMobile');
  const {createReport, appendToReport} = useAddToReport();

  // We want to keep the selected jurisdiction filter in sync with Info Hub
  // so we need to fetch it in the same way
  const getJurs = key => {
    // Only allow a single jurisdiction on Single plans
    if (
      !currentUser.isStaff &&
      currentUser.account.selectedJurisdictions.length !== 0
    ) {
      return currentUser.selectedJurisdictions;
    } else {
      const prevJurs = localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key))
        : [];

      // Make sure they're valid
      const validJurs = prevJurs.filter(j => jurisdictionCodes.includes(j));
      return validJurs.length > 0 ? validJurs : [];
    }
  };

  const [updates, setUpdates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    billIntroduced: true,
    billInForce: true,
    jurs: getJurs('selectedJurisdictions'),
    remindersJurs: getJurs('selectedRemindersJurisdictions'),
  });
  const [isRemindersExpanded, setIsRemindersExpanded] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const res = await fetcher.get('/api/updates', {
          params: {
            dateStart: dateRange[0]
              ? moment(dateRange[0]).format('YYYY-MM-DD')
              : '',
            dateEnd: dateRange[1]
              ? moment(dateRange[1]).format('YYYY-MM-DD')
              : '',
          },
        });
        setUpdates(res.data);
      } catch (e) {
        notifications.error(`Error Loading Updates Feed: ${e.message}`);
      }
      setIsLoading(false);
    };

    loadData();
  }, [dateRange]);

  const toggleFilter = name => {
    setFilters({...filters, [name]: !filters[name]});
  };

  const onJurisdictionsChange = async (jurs, filterKey) => {
    setFilters({
      ...filters,
      ...(filterKey === 'selectedRemindersJurisdictions'
        ? {remindersJurs: jurs}
        : {jurs}),
    });
    localStorage.setItem(filterKey, JSON.stringify(jurs));
  };

  const onClearJurisdictions = async filterKey => {
    onJurisdictionsChange([], filterKey);
    localStorage.setItem(filterKey, JSON.stringify([]));
  };

  const onToggleJurisdiction = async (jur, filter, filterKey) => {
    let newJurs = [];
    if (filter.includes(jur.id)) {
      newJurs = filter.filter(j => j !== jur.id);
    } else {
      // Add
      newJurs = [...filter, jur.id];
    }

    // De-dupe
    newJurs = uniq(newJurs);

    onJurisdictionsChange(newJurs, filterKey);
  };

  const onRemoveJurisdiction = async (jur, filter, filterKey) => {
    if (filter.includes(jur)) {
      // Remove the item
      const newJurs = filter.filter(j => j !== jur);
      onJurisdictionsChange(newJurs, filterKey);
    }
  };

  const filteredUpdates = updates.matchedEntries
    ? updates.matchedEntries.filter(ent => {
        if (
          (ent.changeType === 'BILL_INTRODUCED' ||
            ent.changeType === 'BILL_ASSENTED') &&
          !filters.billIntroduced
        ) {
          return false;
        }
        if (ent.changeType === 'BILL_IN_FORCE' && !filters.billInForce) {
          return false;
        }
        if (
          ent.changeType === 'PROVISION_UPDATED' &&
          !filters.provisionUpdated
        ) {
          return false;
        }

        // Check jurisdictions
        if (filters.jurs?.length && !filters.jurs.includes(ent.jurisdiction)) {
          return false;
        }
        return true;
      })
    : [];

  const filteredReminders = updates.reminders
    ? updates.reminders.filter(ent => {
        if (
          filters.remindersJurs?.length &&
          !filters.remindersJurs.includes(ent.jurisdiction)
        ) {
          return false;
        }
        return true;
      })
    : [];

  const sendEmail = async () => {
    try {
      setIsManualEmailSubmitted(true);
      await fetcher.post('/api/updates/sendEmailForUser', {
        since: emailSinceDate[0],
        until: emailSinceDate[1],
      });

      setEmailSinceDate([null, null]);
      setIsManualEmailSubmitted(false);
      notifications.success(
        'Email sent successfully. Please check your inbox in a few moments.'
      );
    } catch (e) {
      notifications.error(`Error sending email: ${e.message}`);
      setIsManualEmailSubmitted(false);
    }
  };

  return (
    <Comp>
      <Helmet title="Latest Updates" />
      <UpdatesTour />

      <FeedContainer>
        <ContentSection>
          <FilterCol>
            <Filters>
              <Label>What are Reminders?</Label>
              <p>
                "Reminders" provide information on upcoming deadlines that are
                included in legislation (i.e., dates when Bills and Regulations
                will be coming into force, dates that are specified in
                legislation). It covers the <strong>next</strong> 60 days.
              </p>
              <p>
                Employers must comply with an applicable requirement by the
                specified date.
              </p>
              <br />

              {(currentUser.isStaff ||
                currentUser.account.selectedJurisdictions.length === 0) && (
                <div>
                  <Label>Filter Reminders:</Label>

                  <Jurisdictions>
                    <FormGroup
                      className="group"
                      label="Filter by jurisdictions:"
                      labelFor="remindersJurisdiction"
                    >
                      <JurisdictionPicker
                        id="remindersJurisdiction"
                        placeholder="Showing all Jurisdictions"
                        items={jurisdictionCodes.map(jur => ({
                          id: jur,
                          name: getNameByCode(jur),
                        }))}
                        onSelect={jur =>
                          onToggleJurisdiction(
                            jur,
                            filters.remindersJurs,
                            'selectedRemindersJurisdictions'
                          )
                        }
                        onRemove={jur =>
                          onRemoveJurisdiction(
                            jur,
                            filters.remindersJurs,
                            'selectedRemindersJurisdictions'
                          )
                        }
                        selectedItems={filters.remindersJurs.map(jur => ({
                          id: jur,
                          name: getNameByCode(jur),
                        }))}
                      />
                    </FormGroup>
                    {filters.remindersJurs.length > 0 && (
                      <Reset
                        onClick={() =>
                          onClearJurisdictions('selectedRemindersJurisdictions')
                        }
                      >
                        Clear Jurisdiction List
                      </Reset>
                    )}
                  </Jurisdictions>
                </div>
              )}
            </Filters>
          </FilterCol>
          <Content data-tour="reminders">
            <h1>Reminders</h1>
            <Box>
              {isLoading && <Spinner />}

              {filteredReminders?.length === 0 && !isLoading && (
                <NoUpdates>
                  There are no reminders that apply during the upcoming 60 day
                  period.
                </NoUpdates>
              )}

              {!isLoading && (
                <>
                  {filteredReminders.slice(0, 2).map((reminder, idx) => {
                    return (
                      <Update
                        key={`reminder_${idx}`}
                        update={{
                          ...reminder,
                          changeType: reminder.reminderType,
                          bill: reminder.description,
                        }}
                        createReport={createReport}
                        appendToReport={appendToReport}
                      />
                    );
                  })}
                  {filteredReminders?.length > 2 && (
                    <>
                      <Collapse isOpen={isRemindersExpanded}>
                        {filteredReminders?.slice(2).map((reminder, idx) => (
                          <Update
                            key={`reminder_extra_${idx}`}
                            update={{
                              ...reminder,
                              changeType: reminder.reminderType,
                              bill: reminder.description,
                            }}
                            createReport={createReport}
                            appendToReport={appendToReport}
                          />
                        ))}
                      </Collapse>

                      <CollapseToggle
                        onClick={() =>
                          setIsRemindersExpanded(!isRemindersExpanded)
                        }
                      >
                        {isRemindersExpanded ? (
                          <>
                            <Icon icon="chevron-up" />
                            Show Fewer Reminders
                          </>
                        ) : (
                          <>
                            <Icon icon="chevron-down" />
                            Show More Reminders
                          </>
                        )}
                      </CollapseToggle>
                    </>
                  )}
                </>
              )}
            </Box>
          </Content>
        </ContentSection>

        <ContentSection>
          <FilterCol>
            <Filters>
              <Label>What are Amendments?</Label>

              <p>
                “Amendments” provide information about changes to legislation
                that the government has announced or passed. It covers the
                <strong> previous</strong> 60 days, but you can change the date
                range to cover longer or shorter periods.
              </p>

              <p>
                You can also filter to Amendments to focus on specific
                jurisdictions and upcoming vs. in force amendments.
              </p>
              <br />

              <div data-tour="filters">
                <Label>Filter Amendments:</Label>

                <Switch
                  label="Upcoming Amendments"
                  checked={filters.billIntroduced}
                  onChange={() => toggleFilter('billIntroduced')}
                />
                <Switch
                  label="Amendments In Force"
                  checked={filters.billInForce}
                  onChange={() => toggleFilter('billInForce')}
                />

                {/* <Switch
          label="Other Provision Updates"
          checked={filters.provisionUpdated}
          onChange={() => toggleFilter('provisionUpdated')}
        /> */}

                {(currentUser.isStaff ||
                  currentUser.account.selectedJurisdictions.length === 0) && (
                  <Jurisdictions>
                    <FormGroup
                      className="group"
                      label="Filter by jurisdictions:"
                      labelFor="jurisdiction"
                    >
                      <JurisdictionPicker
                        id="jurisdiction"
                        placeholder="Showing all Jurisdictions"
                        items={jurisdictionCodes.map(jur => ({
                          id: jur,
                          name: getNameByCode(jur),
                        }))}
                        onSelect={jur =>
                          onToggleJurisdiction(
                            jur,
                            filters.jurs,
                            'selectedJurisdictions'
                          )
                        }
                        onRemove={jur =>
                          onRemoveJurisdiction(
                            jur,
                            filters.jurs,
                            'selectedJurisdictions'
                          )
                        }
                        selectedItems={filters.jurs.map(jur => ({
                          id: jur,
                          name: getNameByCode(jur),
                        }))}
                      />
                    </FormGroup>
                    {filters.jurs.length > 0 && (
                      <Reset
                        onClick={() =>
                          onClearJurisdictions('selectedJurisdictions')
                        }
                      >
                        Clear Jurisdiction List
                      </Reset>
                    )}
                  </Jurisdictions>
                )}

                <br />
                <p>Filter by date range:</p>
                <DateRangeInput
                  formatDate={date => moment(date).format('LL')}
                  parseDate={d => d}
                  onChange={date => setDateRange(date)}
                  placeholder="Select a date..."
                  value={dateRange ? dateRange : [null, null]}
                  maxDate={moment().toDate()}
                  minDate={moment().subtract(2, 'year').toDate()}
                  highlightCurrentDay={true}
                  className="updates-daterange-picker"
                  popoverProps={{
                    rootBoundary: 'document',
                    position: isMobile ? Position.BOTTOM : Position.RIGHT,
                  }}
                />
                <br />
                <br />
                {filteredUpdates.length > 0 && (
                  <AddToReportButton
                    onCreate={name =>
                      createReport(
                        name,
                        filteredUpdates.map(update => update.provision.id)
                      )
                    }
                    onAppend={reportId =>
                      appendToReport(
                        reportId,
                        filteredUpdates.map(update => update.provision.id)
                      )
                    }
                  />
                )}
              </div>

              {SHOW_STAFF_ONLY_EMAIL_FEATURE && currentUser.isStaff && (
                <SendEmail>
                  <Label>
                    <small>Staff Only:</small>
                    <br />
                    Create an Update Summary
                  </Label>
                  <p>
                    Send yourself an email containing updates during the
                    selected time period.
                  </p>
                  <DateRangeInput
                    formatDate={date => moment(date).format('LL')}
                    parseDate={d => d}
                    onChange={date => setEmailSinceDate(date)}
                    placeholder="Select a date..."
                    value={emailSinceDate ? emailSinceDate : null}
                    maxDate={moment().toDate()}
                    minDate={moment().subtract(1, 'year').toDate()}
                    highlightCurrentDay={true}
                    className="self-email-daterange-picker"
                    popoverProps={{
                      rootBoundary: 'document',
                      position: isMobile ? Position.BOTTOM : Position.RIGHT,
                    }}
                  />

                  {emailSinceDate[0] && emailSinceDate[1] && (
                    <Button
                      onClick={sendEmail}
                      disabled={isManualEmailSubmitted}
                    >
                      {isManualEmailSubmitted ? 'Sending...' : 'Send Email'}
                    </Button>
                  )}
                </SendEmail>
              )}
            </Filters>
          </FilterCol>
          <Content data-tour="amendments">
            <h1>Amendments</h1>
            <Box>
              {isLoading && <Spinner />}

              {filteredUpdates.length === 0 && !isLoading && (
                <NoUpdates>
                  No updates were found within the selected date range.
                </NoUpdates>
              )}

              {!isLoading &&
                filteredUpdates.map((update, idx) => {
                  return (
                    <Update
                      key={`amendment_${idx}`}
                      update={{
                        ...update,
                        date: update.createdAt,
                        provision: {
                          ...update.provision,
                          name: update.name,
                        },
                        changeType:
                          // update.changeType === 'NEW_PROVISION'
                          //   ? 'Amendment In Force'
                          //   : update.changeType === 'PROVISION_UPDATED'
                          //   ? 'Amendment In Force' :
                          update.changeType === 'BILL_IN_FORCE'
                            ? 'Amendment In Force'
                            : update.changeType === 'BILL_ASSENTED'
                            ? 'Amendment Upcoming'
                            : update.changeType === 'BILL_INTRODUCED'
                            ? 'Amendment Introduced'
                            : '',
                        bill: (
                          <>
                            {update.changeType === 'BILL_IN_FORCE' && (
                              <strong>Related Amendment:</strong>
                            )}
                            {(update.changeType === 'BILL_INTRODUCED' ||
                              update.changeType === 'BILL_ASSENTED') && (
                              <strong>Upcoming Amendment:</strong>
                            )}
                            <br />
                            {update.bill.name}
                          </>
                        ),
                      }}
                      createReport={createReport}
                      appendToReport={appendToReport}
                    />
                  );
                })}
            </Box>
          </Content>
        </ContentSection>
      </FeedContainer>

      <Sidebar>
        <h3>
          <Icon icon="take-action" /> Updated Reports
        </h3>
        <Box data-tour="updated-reports">
          {dateRange[0] && dateRange[1] ? (
            <p>
              The following reports were updated between{' '}
              {moment(dateRange[0]).format('MMMM D, YYYY')} and{' '}
              {moment(dateRange[1]).format('MMMM D, YYYY')}:
            </p>
          ) : (
            <p>The following reports have been updated recently:</p>
          )}
          <Reports>
            {updates?.affectedReports?.length === 0 && (
              <em>No reports have been updated recently.</em>
            )}
            {updates?.affectedReports?.map(report => (
              <Report key={report.id}>
                <Icon icon="take-action" iconSize={14} />
                <NavLink to={report.url}>{report.name}</NavLink>
              </Report>
            ))}
          </Reports>
        </Box>

        <h3>
          <Icon icon="notifications" /> Announcements
        </h3>
        <Box>
          <NotificationsFeed highlightUnread={false} />
        </Box>
      </Sidebar>
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  // border: 1px solid red;

  @media (min-width: ${media.lg}) {
    flex-direction: row;
  }
`;

const FilterCol = styled.div`
  width: 100%;

  @media (min-width: ${media.lg}) {
    width: 260px;
    min-width: 260px;
  }
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  // border: 1px solid green;

  @media (min-width: ${media.lg}) {
    flex-direction: row;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;

  @media (min-width: ${media.lg}) {
    margin: 20px 46px 0 60px;
    padding: 0;
  }
`;

const Label = styled.h2`
  font-size: 1.2rem;
  margin: 0 0 20px 0;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (min-width: ${media.lg}) {
    min-width: 220px;
    max-width: 220px;
    margin: 92px 0px 0 50px;
    padding: 0;
  }
`;

const Jurisdictions = styled.div`
  width: 100%;
  margin-top: 20px;

  .bp3-popover-target {
    width: 95%;
  }

  .bp3-form-group {
    margin: 0;
  }
`;

const SendEmail = styled.div`
  margin: 30px 0 30px 0;

  h2 {
    margin-bottom: 5px;
  }

  p {
    font-size: 0.8rem;
    margin: 0 0 10px 0;
  }

  button {
    margin-top: 10px;
  }
`;

const Reset = styled.button`
  background: none;
  border: none;
  text-align: left;
  padding: 0;
  margin-top: 10px;
  color: ${colors.primary};
  cursor: pointer;
  font-size: 0.8rem;

  &:hover {
    text-decoration: underline;
  }
`;

const FeedContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Sidebar = styled.section`
  padding: 0 20px;
  max-width: 100%;
  width: 100%;

  h3 {
    display: flex;
    align-items: center;

    .bp3-icon {
      margin-right: 10px;
      color: ${colors.primary};
    }
  }

  @media (min-width: ${media.lg}) {
    margin: 40px 46px 0 30px;
    max-width: 300px;
    padding: 0;
  }
`;

const Box = styled.div`
  border: 1px solid ${colors.gray2};
  background-color: white;
  border-radius: ${radius};
  padding: 15px 30px;
  min-width: 100%;
  max-width: 1100px;
  margin-bottom: 30px;

  a {
    color: ${colors.primary};
    text-decoration: underline;
  }
`;

const NoUpdates = styled.div`
  font-style: italic;
`;

const Reports = styled.ul`
  margin: 0;
  padding: 0;

  em {
    display: block;
    margin: 20px 0 0 0;
    font-size: 0.9rem;
  }
`;

const Report = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 20px 0;

  .bp3-icon {
    margin-right: 10px;
  }
`;

const CollapseToggle = styled.button`
  border: none;
  border-radius: ${radius};
  background-color: ${colors.primary};
  color: white;
  margin-top: 15px;
  padding: 5px 10px;
  font-size: 0.9rem;
  cursor: pointer;

  .bp3-icon {
    margin-right: 5px;
  }
`;
