import React from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {Icon} from '@blueprintjs/core';
import {colors, media} from '../modules/styles';

export default function NavItem({
  icon,
  to,
  theme,
  onClick,
  children,
  dataTour,
}) {
  return (
    <Comp to={to} theme={theme} onClick={onClick} data-tour={dataTour}>
      {icon && <Icon icon={icon} iconSize={16} />}
      {children}
    </Comp>
  );
}

const Comp = styled(NavLink)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 5px 0;
  padding: 10px 10px;
  font-size: 18px;
  text-decoration: none;
  color: white;

  &:hover {
    text-decoration: none;
  }

  .bp3-icon {
    color: ${({theme}) => theme};
    margin: 0 8px 0 0;
  }

  @media (min-width: ${media.lg}) {
    padding: 5px 0px;
    margin: 0 0 0 30px;
    background-color: none;

    &:hover {
      text-decoration: none;
      color: ${colors.secondaryBright};
    }

    &.active {
      color: ${colors.secondaryBright};
      font-weight: 600;
    }
  }
`;
