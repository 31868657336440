import React from 'react';
import {Helmet} from 'react-helmet';
import {
  Hits,
  HitsPerPage,
  Pagination,
  RefinementList,
  SearchBox,
  connectStateResults,
  ClearRefinements,
  Stats,
} from 'react-instantsearch-dom';
import styled from 'styled-components';
import SearchHit from '../components/SearchHit';
import DesktopOnly from '../components/DesktopOnly';
import {colors, media} from '../modules/styles';
import noResultsImg from '../assets/new-search.svg';

export default function SearchPg() {
  const StateResults = ({searchResults, searching, noResults, children}) => {
    if (searchResults?.query && searchResults?.nbHits) {
      return children;
    } else if (searching) {
      return null;
    } else {
      return noResults;
    }
  };

  const CustomeStateResults = connectStateResults(StateResults);

  return (
    <Comp>
      <Helmet title="Search Results"></Helmet>

      <Content>
        <DesktopOnly>
          <Left>
            <CustomeStateResults noResults={<></>}>
              <ClearRefinements />
              <br />
              <FilterName>Area of Law</FilterName>
              <RefinementList attribute="area.name" showMore={true} />
              <br />

              <FilterName>Topic</FilterName>
              <RefinementList
                attribute="topic.name"
                limit={5}
                showMore={true}
              />
              <br />

              <FilterName>Jurisdiction</FilterName>
              <RefinementList
                attribute="jurisdictionName"
                limit={20}
                showMore={true}
              />
              <br />

              <FilterName>Bill / Reg.</FilterName>
              <RefinementList
                attribute="bills.name"
                limit={5}
                showMore={true}
              />
              <br />

              <FilterName>Special Rules by Industry/Occupation</FilterName>
              <RefinementList
                attribute="specialRules.industries.name"
                limit={5}
                showMore={true}
              />
              <br />
            </CustomeStateResults>
          </Left>
        </DesktopOnly>

        <Right>
          <h1>Search Results</h1>
          <SearchBox />
          <br />
          <br />

          <CustomeStateResults
            noResults={
              <NoResults>
                <img src={noResultsImg} alt="No results found" />
                <h2>No results found.</h2>
              </NoResults>
            }
          >
            <OptionsHolder>
              <Stats />
              <HitsPerPage
                defaultRefinement={10}
                items={[
                  {value: 10, label: 'Show 10 results'},
                  {value: 25, label: 'Show 25 results'},
                  {value: 50, label: 'Show 50 results'},
                  {value: 100, label: 'Show 100 results'},
                ]}
              />
            </OptionsHolder>
            <Hits hitComponent={SearchHit} />
            <br />

            <PaginationHolder>
              <Pagination />
            </PaginationHolder>
          </CustomeStateResults>
        </Right>
      </Content>
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  width: 100%;

  @media (min-width: ${media.lg}) {
    flex-direction: row;
    padding: 0px 50px 40px 46px;
  }

  @media (min-width: ${media.xl}) {
    flex-direction: row;
    padding: 0px 250px 40px 46px;
  }

  .ais-SearchBox-input {
    box-shadow: none;
  }

  .ais-RefinementList-checkbox {
    box-shadow: none;
  }

  .ais-RefinementList-showMore {
    border: none;
    background: none;
    box-shadow: none;
    color: ${colors.primary};
    padding: 10px 0 0 22px;
    margin: 0;
  }
`;

const Content = styled.section`
  display: flex;
  gap: 40px;
  width: 100%;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (min-width: ${media.lg}) {
    min-width: 220px;
    max-width: 220px;
    margin: 92px 0px 0 50px;
    padding: 0;
  }
`;

const Right = styled.div`
  padding: 0 20px;
  width: 100%;

  @media (min-width: ${media.lg}) {
    margin: 20px 46px 0 60px;
    padding: 0;
  }
`;

const FilterName = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0 0 5px 0;
`;

const OptionsHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  font-size: 0.9rem;
  margin: 0 0 5px 0;
`;

const PaginationHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`;

const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 400px;
  }
`;
