import useEvent from './useEvent';
import fetcher from '../modules/fetcher';
import {createStore, useStore} from 'react-hookstore';
import {hasValidSubscription, isOnFreeTrial} from '../modules/subscriptions';
import {emit} from '../modules/eventManager';

// Init our state stores
createStore('currentUser', null);
createStore('siteWidth', 0);
createStore('isMobile', false);

export default function useStoreSetup() {
  const [, setCurrentUser] = useStore('currentUser');

  // Listen to state changing events
  useEvent('user:loggedIn', event => {
    setCurrentUser(event.data);
    fetcher.setToken(event.data.token);

    // If the user is on a free trial and it has expired we need to limit their access
    if (!hasValidSubscription(event.data.account)) {
      const remainingTime = isOnFreeTrial(event.data.account);
      if (remainingTime <= 0) {
        emit('user:trialExpired');
      }
    }

    // LuckyOrange tracking. These are just tags (with no value) so we'll use the user's id as the tag name
    // if (event.data && event.data.email) {
    //   window._loq = window._loq || [];
    //   window._loq.push(['tag', event.data.email]);
    // }
  });

  useEvent('user:loggedOut', () => {
    setCurrentUser(null);
    localStorage.removeItem('token');
    window.location.href = '/';
  });
}
