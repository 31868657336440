import React from 'react';
import styled from 'styled-components';

export default function ActRegLink({
  name = '',
  url,
  sections = [],
  highlightSections = []
}) {
  if (!name) {
    return null;
  }

  const sects = highlightSections.length
    ? sections.map(s => {
        if (highlightSections.includes(s)) {
          return `<mark>${s}</mark>`;
        } else {
          return `<span>${s}</span>`;
        }
      })
    : sections;

  return (
    <Comp>
      {name && url && (
        <a href={url} target="_blank" rel="noreferrer noopener">
          {name}
        </a>
      )}

      {name && !url && <span>{name}</span>}

      {sects.length > 0 ? (
        <span>
          {' '}
          - s.{' '}
          <span dangerouslySetInnerHTML={{__html: sects.join(', ')}}></span>
          <br />
        </span>
      ) : (
        <br />
      )}
    </Comp>
  );
}

const Comp = styled.div``;
