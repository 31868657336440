import React from 'react';
import {MultiSelect} from '@blueprintjs/select';
import {MenuItem, Position} from '@blueprintjs/core';
import {getCodeByName} from '../modules/jurisdictions';
import {useStore} from 'react-hookstore';

export default function JurisdictionPicker({
  id,
  items,
  disabled = false,
  selectedItems,
  placeholder = 'Showing all Jurisdictions',
  position = Position.RIGHT,
  onSelect,
  onRemove,
}) {
  const [isMobile] = useStore('isMobile');

  return (
    <MultiSelect
      id={id}
      items={items}
      selectedItems={selectedItems}
      fill={true}
      disabled={disabled}
      onItemSelect={inf => onSelect(inf)}
      resetOnClose={true}
      filterable={false}
      noResults={<MenuItem disabled={true} text="No results." />}
      popoverProps={{
        rootBoundary: 'document',
        position: isMobile ? Position.BOTTOM : position,
        portalClassName: 'filter-select-popover',
      }}
      placeholder={placeholder}
      itemRenderer={(item, {handleClick, modifiers}) => {
        if (!modifiers.matchesPredicate) {
          return null;
        }
        const isSelected = !!selectedItems.find(i => i.id === item.id);
        return (
          <MenuItem
            active={modifiers.active}
            key={item.id}
            icon={isSelected ? 'tick' : 'blank'}
            text={item.name}
            intent={isSelected ? 'primary' : 'none'}
            onClick={handleClick}
          />
        );
      }}
      tagInputProps={{
        tagProps: {
          minimal: true,
          onRemove: (e, props) => {
            onRemove(getCodeByName(props.children));
          },
        },
        fill: true,
      }}
      tagRenderer={item => item.name}
    />
  );
}
