import {Intent, Position, Toaster} from '@blueprintjs/core';

const toaster = Toaster.create({
  className: 'notifications-toaster',
  position: Position.TOP
});

const notifications = {
  success: (message, timeout = 5000) => {
    toaster.show({message, intent: Intent.SUCCESS, icon: 'tick-circle', timeout});
  },

  error: (message, timeout = 15000) => {
    toaster.show({message, intent: Intent.DANGER, icon: 'error', timeout});
  },

  warn: (message, timeout = 5000) => {
    toaster.show({message, intent: Intent.WARNING, icon: 'warning-sign', timeout});
  },

  toast: args => toaster.show(args),

  clear: () => toaster.clear()
};

export default notifications;
