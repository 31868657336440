import React, {useState} from 'react';
import styled from 'styled-components';
import {useStore} from 'react-hookstore';
import {colors, media} from '../modules/styles';
import ENV from '../environment';
import NavItemWhite from './NavItemWhite';
import logo from '../assets/logo-white.svg';
import {Icon} from '@blueprintjs/core';

export default function AdminHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentUser] = useStore('currentUser');

  const closeNav = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  if (!currentUser || !currentUser.isStaff) {
    window.location.href = '/';
  }

  return (
    <Comp>
      <LogoLink href={ENV.ADMIN_HOME_PAGE}>
        <Logo src={logo} alt="Compliance Works Logo" />
      </LogoLink>
      <MenuIcon onClick={() => setIsOpen(!isOpen)}>
        <Icon icon={isOpen ? 'cross' : 'menu'} iconSize={20} />
      </MenuIcon>
      <Nav className={isOpen ? 'isOpen' : ''}>
        <NavItemWhite to="/a/dashboard" icon="dashboard" onClick={closeNav}>
          Dashboard
        </NavItemWhite>

        <NavItemWhite to="/a/billTracker" icon="take-action" onClick={closeNav}>
          Bill Tracker
        </NavItemWhite>

        <NavItemWhite to="/a/data" icon="search-template" onClick={closeNav}>
          Provisions
        </NavItemWhite>

        <NavItemWhite to="/a/acts" customIcon="scales" onClick={closeNav}>
          Acts &amp; Regs
        </NavItemWhite>

        <NavItemWhite to="/a/industries" icon="briefcase" onClick={closeNav}>
          Industries
        </NavItemWhite>

        <NavItemWhite to="/a/checklistsManager" icon="form" onClick={closeNav}>
          Checklists
        </NavItemWhite>

        {currentUser.role === 'ADMIN' && (
          <NavItemWhite to="/a/accounts" icon="user" onClick={closeNav}>
            Accounts
          </NavItemWhite>
        )}

        {currentUser.role === 'ADMIN' && (
          <NavItemWhite
            to="/a/notifications"
            icon="notifications"
            onClick={closeNav}
          >
            Notifications
          </NavItemWhite>
        )}

        <Spacer />

        <NavItemWhite to={ENV.HOME_PAGE} icon="log-out" onClick={closeNav}>
          Exit
        </NavItemWhite>
      </Nav>
    </Comp>
  );
}

const Comp = styled.header`
  display: flex;
  align-items: center;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  padding: 0 15px;
  background-color: ${colors.gray};

  @media (min-width: ${media.lg}) {
    padding: 0 50px;
  }
`;

const LogoLink = styled.a`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 180px;
`;

const MenuIcon = styled.div`
  display: block;
  margin-left: auto;
  color: white;

  .bp3-icon {
    color: white;
  }

  @media (min-width: ${media.lg}) {
    display: none;
  }
`;

const Nav = styled.nav`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: -100vh;
  right: 0px;
  height: calc(100vh - 60px);
  background-color: ${colors.gray};
  padding: 10px 20px 10px 20px;
  border: 1px solid ${colors.gray2};

  &.isOpen {
    display: flex;
    top: 60px;
  }

  @media (min-width: ${media.lg}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    top: 0;
    right: 0;
    padding: 0;
    height: auto;
    border: none;

    width: 100%;
    margin-left: 20px;
  }
`;

const Spacer = styled.div`
  height: 30px;
  width: 10px;

  @media (min-width: ${media.lg}) {
    height: 10px;
    width: 100%;
  }
`;
