import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {inForce} from '../modules/options';
import {isInForce} from '../modules/bills';
import {colors, radius} from '../modules/styles';
import DesktopOnly from '../components/DesktopOnly';
import BillProgressBar from '../components/BillProgressBar';

export default function BillDetails({
  bill,
  prov,
  onTimeMachineClick,
  isTimeMachineEnabled,
}) {
  const isBillInForce = isInForce(bill);

  const upcoming = prov.upcomingChanges.find(
    uc => uc.bill === bill.id && uc.affectedAct === bill.affectedAct
  );

  return (
    <Comp>
      {isBillInForce && <InForce>IN FORCE</InForce>}

      {bill.url ? (
        <a href={bill.url} target="_blank" rel="noopener noreferrer">
          {bill.name}
        </a>
      ) : (
        <strong>{bill.name}</strong>
      )}

      {bill.introductionDate && (
        <LabelWrapper>
          <Label>Introduction/Announcement:</Label>{' '}
          {moment(bill.introductionDate).format('LL')}
        </LabelWrapper>
      )}
      {bill.assentDate && (
        <LabelWrapper>
          <Label>Royal Assent (Passed):</Label>{' '}
          {moment(bill.assentDate).format('LL')}
        </LabelWrapper>
      )}

      <LabelWrapper>
        <Label>In Force:</Label>
        {bill.inForce === 'assent' && !bill.assentDate && 'When Passed'}
        {bill.inForce === 'assent' &&
          bill.assentDate &&
          ` ${moment(bill.assentDate).format('LL')}`}

        {bill.inForce === 'to be proclaimed' && 'To be announced'}

        {bill.inForce !== 'assent' &&
          bill.inForce !== 'to be proclaimed' &&
          inForce[bill.inForce]}

        {(bill.inForce === 'on date' || bill.inForce === 'retro on date') &&
          bill.inForceDate &&
          ` ${moment(bill.inForceDate).format('LL')}`}
      </LabelWrapper>

      {bill.status !== 'Filed' && (
        <BillProgressBar
          status={bill.status}
          isInForce={isBillInForce}
          jurisdiction={bill.jurisdiction}
        />
      )}

      {bill.description && (
        <>
          <Title>Description:</Title>
          <Description dangerouslySetInnerHTML={{__html: bill.description}} />
        </>
      )}

      {isBillInForce && isTimeMachineEnabled && (
        <DesktopOnly>
          <InForceMsg>
            <TimeMachineButton onClick={onTimeMachineClick}>
              Click here to compare to previous versions of this provision.
            </TimeMachineButton>
          </InForceMsg>
        </DesktopOnly>
      )}

      {upcoming &&
        (upcoming.changesOverview ||
          upcoming.upcomingActSections?.length > 0 ||
          upcoming.upcomingRegSections?.length > 0) && (
          <Details>
            <Title>
              {isBillInForce ? 'Summary of Changes' : 'Upcoming Changes'}:
            </Title>
            <Description
              dangerouslySetInnerHTML={{__html: upcoming.changesOverview}}
            />

            {upcoming.upcomingAct?.id &&
              upcoming.upcomingAct.id !== prov.act.id && (
                <>
                  <Title>New Act:</Title>
                  {upcoming.upcomingAct?.url ? (
                    <a
                      href={upcoming.upcomingAct.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {upcoming.upcomingAct.name}
                    </a>
                  ) : (
                    <span>{upcoming.upcomingAct.name}</span>
                  )}
                </>
              )}
            {upcoming.upcomingRegulations?.id &&
              upcoming.upcomingRegulations.id !== prov.regulations?.id && (
                <>
                  <Title>New Regulation:</Title>
                  {upcoming.upcomingRegulations?.url ? (
                    <a
                      href={upcoming.upcomingRegulations.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {upcoming.upcomingRegulations.name}
                    </a>
                  ) : (
                    <span>{upcoming.upcomingRegulations.name}</span>
                  )}
                </>
              )}

            {(upcoming.upcomingActSections?.length > 0 ||
              upcoming.upcomingRegSections?.length > 0) && (
              <>
                <Title>New Sections Being Introduced:</Title>

                {upcoming.upcomingActSections?.length > 0 && (
                  <p style={{marginLeft: '20px'}}>
                    <strong>
                      Act s. {upcoming.upcomingActSections.join(', ')}
                    </strong>
                  </p>
                )}
                {upcoming.upcomingRegSections?.length > 0 && (
                  <p style={{marginLeft: '20px'}}>
                    <strong>
                      Reg s. {upcoming.upcomingRegSections.join(', ')}
                    </strong>
                  </p>
                )}
              </>
            )}
          </Details>
        )}
    </Comp>
  );
}

const Comp = styled.div`
  line-height: 1.6rem;
  font-size: 1rem;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${colors.gray2};

  a {
    display: block;
    font-size: 1rem;
    text-decoration: underline;
    margin: 0 0 5px 0;
    font-weight: 600;
    color: ${colors.primary};
  }
`;

const InForce = styled.div`
  display: inline-block;
  background-color: ${colors.secondary};
  border-radius: ${radius};
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: white;
  padding: 4px 8px;
  margin-right: 5px;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
`;

const Label = styled.span`
  display: inline-block;
  font-weight: 600;
`;

const Details = styled.div`
  margin: 20px 0 0 0;
`;

const Title = styled.div`
  margin: 25px 0 10px 0;
  font-weight: 600;
`;

const Description = styled.div``;

const InForceMsg = styled.div`
  background-color: ${colors.yellowCalloutLight};
  padding: 9px;
  margin-top: 20px;
  color: ${colors.yellowCallout};
  font-weight: 600;
  font-size: 0.9rem;
  border-left: 2px solid ${colors.yellowCallout};
`;

const TimeMachineButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 20px 0 10px 0;
  text-decoration: underline;
  color: ${colors.yellowCallout};
  font-weight: 600;
  cursor: pointer;
  text-align: left;
`;
