import * as React from 'react';
import icon from '../assets/loading.svg';
import whiteIcon from '../assets/loading-white.svg';
import styled, {keyframes} from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Comp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  img {
    animation: ${rotate} 2s linear infinite;
    width: 32px;
  }
`;

class Spinner extends React.Component {
  render() {
    return (
      <Comp>
        <img src={this.props.white ? whiteIcon : icon} alt="" />
      </Comp>
    );
  }
}

export default Spinner;
