import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import SignupHeader from '../components/SignupHeader';
import SubscriptionPlanBox from '../components/SubscriptionPlanBox';
import fetcher from '../modules/fetcher';
import {media} from '../modules/styles';

export default function SignupPlanSelectionPg({onUpdate}) {
  const [plans, setPlans] = useState([]);
  const history = useHistory();

  useEffect(() => {
    fetcher
      .get('/api/subscriptionPlans/public')
      .then(res => setPlans(res.data));
  }, []);

  return (
    <Comp>
      <Helmet title="Sign Up for a Free Trial" />
      <SignupHeader step={1} />

      <h1>Step 1. Sign Up for a Free Trial</h1>
      <p>A free trial version of Compliance Works includes:</p>
      <ul>
        <li>1 jurisdiction</li>
        <li>3 areas of HR law</li>
        <li>1 user account</li>
      </ul>
      <p>
        A paid subscription to Compliance Works can include <strong>all</strong>{' '}
        jurisdictions, <strong>all</strong> available areas of HR law, access
        for your <strong>team</strong> and much more!
      </p>
      <p>
        We have plans to suit every type of organization, whether you are a
        business or a non-profit organization.{' '}
        <a href="https://complianceworks.ca/pricing/">
          Click here for information on pricing
        </a>
      </p>

      <p>
        Do you want to learn more about the features included in a paid
        subscription to Compliance Works or how to best utilize your free trial?{' '}
        <a href="https://complianceworks.ca/request-a-demo/">
          Contact us for a demo
        </a>
        .
      </p>

      <Plans>
        {plans.map(plan => (
          <SubscriptionPlanBox
            key={plan.id}
            plan={plan}
            onClick={() => {
              onUpdate({plan: plan.id}, true);
              history.push('/signup/customize');
            }}
          />
        ))}
      </Plans>
    </Comp>
  );
}

const Comp = styled.div`
  width: 100%;

  h1 {
    font-size: 1.6rem;
    margin: 30px 0 10px 0;
    font-weight: 600;
  }
`;

const Plans = styled.section`
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 50px 0;

  @media (min-width: ${media.md}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;
