import React from 'react';
import styled from 'styled-components';
import {colors, radius, media} from '../modules/styles';
import {FormGroup, Icon, Position} from '@blueprintjs/core';
import {useStore} from 'react-hookstore';
import FilterSelect from './FilterSelect';
import JurisdictionPicker from './JurisdictionPicker';
import Provisions from './Provisions';
import AddToReportButton from './AddToReportButton';
import {
  filterJurisdictionsBySubscription,
  getNameByCode,
  jurisdictionCodes,
} from '../modules/jurisdictions';
// import {Tooltip2} from '@blueprintjs/popover2';

export default function BuilderFilters({
  currentUser,
  areas,
  jurisdictions,
  // industries,
  selectedArea,
  selectedTopic,
  selectedProvs,
  // selectedIndustry,
  setArea,
  setTopic,
  setProvs,
  // setSelectedIndustry,
  currentTab,
  onToggleJursdiction,
  onRemoveJursdiction,
  onReset,
  // onResetIndustry,
  onCreateReport,
  onAppendToReport,
  hasResults,
}) {
  const [isMobile] = useStore('isMobile');

  const topicOptions = selectedArea
    ? areas.find(a => a.id === selectedArea.id).topics
    : [];

  const jurisdictionOptions =
    selectedArea && currentTab === 'area'
      ? filterJurisdictionsBySubscription(
          currentUser,
          areas.find(a => a.id === selectedArea.id).jurisdictions
        )
      : filterJurisdictionsBySubscription(currentUser, jurisdictionCodes); // Include all jurisdictions for Industry and Keyword search

  if (!selectedArea || !selectedTopic) {
    return null;
  }

  const filterPublished = id => {
    // Only include the provision if it's published
    return selectedProvs[id].isPublished;
  };

  const numAvailableProvisions =
    Object.keys(selectedProvs).filter(filterPublished).length;

  const numSelectedProvisions = Object.keys(selectedProvs)
    .filter(filterPublished)
    .filter(id => selectedProvs[id].selected).length;

  return (
    <Comp data-tour="filters">
      {currentTab !== 'search' && (
        <>
          <FormGroup
            className="group"
            label="Area of Law:"
            labelFor="area-of-law"
          >
            <FilterSelect
              id="area-of-law"
              items={areas}
              onSelect={item => setArea(item)}
              activeItem={{id: selectedArea.id}}
              position={isMobile ? Position.BOTTOM : Position.RIGHT}
            >
              <Selected>
                {selectedArea.name} <Icon icon="caret-down" />
              </Selected>
            </FilterSelect>
          </FormGroup>

          <Topics>
            <FormGroup className="group" label="Topic:" labelFor="topics">
              <FilterSelect
                id="topics"
                items={topicOptions}
                onSelect={item => setTopic(item)}
                activeItem={{id: selectedTopic._id}}
                position={isMobile ? Position.BOTTOM : Position.RIGHT}
                filterable={false}
              >
                <Selected>
                  {selectedTopic.name} <Icon icon="caret-down" />
                </Selected>
              </FilterSelect>
            </FormGroup>
          </Topics>

          {numAvailableProvisions > 0 && (
            <FormGroup
              className="group"
              label="Filter by Provision:"
              labelFor="provisions"
            >
              <Provisions provisions={selectedProvs} onChange={setProvs}>
                <Selected
                  className={
                    numAvailableProvisions === numSelectedProvisions
                      ? 'all'
                      : ''
                  }
                >
                  {numAvailableProvisions === numSelectedProvisions
                    ? 'Showing all Provisions'
                    : 'Some Provisions Hidden'}{' '}
                  <Icon icon="caret-down" />
                </Selected>
              </Provisions>
            </FormGroup>
          )}
        </>
      )}

      {jurisdictionOptions.length > 1 && (
        <Jurisdictions>
          <FormGroup
            className="group"
            label="Filter by Jurisdictions:"
            labelFor="jurisdiction"
          >
            <JurisdictionPicker
              id="jurisdiction"
              items={jurisdictionOptions.map(jur => ({
                id: jur,
                name: getNameByCode(jur),
              }))}
              onSelect={onToggleJursdiction}
              onRemove={onRemoveJursdiction}
              selectedItems={jurisdictions.map(jur => ({
                id: jur,
                name: getNameByCode(jur),
              }))}
            />
            {jurisdictions.length > 0 && (
              <Reset onClick={onReset}>Clear Jurisdiction Filter</Reset>
            )}
          </FormGroup>
        </Jurisdictions>
      )}

      {/* {currentTab !== 'search' && (
        <>
          <Tooltip2
            intent="warning"
            position="top"
            content={
              <IndustryNote>
                <Icon icon="info-sign" iconSize={14} />
                <p>
                  Selecting an industry will limit results to provisions that
                  have special rules or exemptions for that industry.
                </p>
              </IndustryNote>
            }
          >
            <FormGroup
              className="group"
              label="Filter by Industry:"
              labelFor="industry"
            >
              <FilterSelect
                id="industry"
                items={industries}
                activeItem={{id: selectedIndustry?.id}}
                onSelect={item => setSelectedIndustry(item)}
                position={isMobile ? Position.BOTTOM : Position.RIGHT}
              >
                <Selected>
                  <span>{selectedIndustry?.name}</span>
                  <Icon icon="caret-down" />
                </Selected>
              </FilterSelect>
              {selectedIndustry?.id && (
                <Reset onClick={onResetIndustry}>Clear Industry Filter</Reset>
              )}
            </FormGroup>
          </Tooltip2>
        </>
      )} */}

      {hasResults && (
        <>
          <br />
          <br />
          <AddToReportButton
            onCreate={onCreateReport}
            onAppend={onAppendToReport}
          />
        </>
      )}
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;

  @media (min-width: ${media.lg}) {
    position: fixed;
    top: 150px;
    left: 50px;
    width: 220px;
  }

  .bp3-tab-indicator {
    background-color: ${colors.primary};
    height: 2px;
  }

  .bp3-tab {
    outline: none;
    color: ${colors.grayDark};

    &[aria-selected='true'],
    &:hover {
      color: ${colors.primary};
      .bp3-icon {
        color: ${colors.primary};
      }
    }

    & .bp3-icon {
      margin-right: 5px;
      color: ${colors.grayDark};
    }
  }

  hr {
    width: 100%;
    border: none;
    border-top: 1px solid ${colors.gray2};
  }
`;

const Topics = styled.div`
  display: flex;
  align-items: center;

  .provisions {
    margin: 5px 0 0px 10px;
  }
`;

const Jurisdictions = styled.div`
  width: 100%;

  .bp3-popover-target {
    width: 95%;
  }
`;

const Selected = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colors.gray2};
  border-radius: ${radius};
  background-color: white;
  padding: 5px 10px;
  min-width: 210px;
  max-width: 210px;
  cursor: pointer;

  &.all {
    color: ${colors.placeholder};
  }
`;

const Reset = styled.button`
  background: none;
  border: none;
  text-align: left;
  padding: 0;
  color: ${colors.primary};
  cursor: pointer;
  font-size: 0.8rem;

  &:hover {
    text-decoration: underline;
  }
`;

// const IndustryNote = styled.div`
//   display: flex;
//   justify-content: space-between;
//   font-size: 0.9rem;
//   max-width: 200px;

//   .bp3-icon {
//     margin-right: 10px;
//   }

//   p {
//     margin: 0;
//   }
// `;
