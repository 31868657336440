import {Icon} from '@blueprintjs/core';
import React from 'react';
import {Highlight, Snippet} from 'react-instantsearch-dom';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import useAddToReport from '../hooks/useAddToReport';
import {colors, media, radius} from '../modules/styles';
import AddToReportButton from './AddToReportButton';

export default function SearchHit({hit}) {
  const {createReport, appendToReport} = useAddToReport();

  return (
    <Comp>
      <Header>
        <Row style={{justifyContent: 'space-between'}}>
          <Area>
            {hit.area.name}: {hit.topic.name}
          </Area>
          <Jurisdiction>
            <AddToReportButton
              onCreate={name => createReport(name, [hit.id])}
              onAppend={reportId => appendToReport(reportId, [hit.id])}
            >
              <AddToReportLink>
                <Icon icon="add" iconSize={12} /> Add To Report
              </AddToReportLink>
            </AddToReportButton>
            <Icon icon="map" iconSize={12} /> {hit.jurisdictionName}
          </Jurisdiction>
        </Row>
        <Row>
          <Name
            to={`/m/builder/${hit.area.id}/${hit.topic.id}#${hit.id}`}
            target="_blank"
          >
            <Highlight attribute="name" hit={hit} />{' '}
          </Name>
        </Row>
      </Header>

      <Description>
        {hit.textWarning &&
          hit._snippetResult?.textWarning.matchLevel !== 'none' && (
            <>
              <Snippet attribute="textWarning" hit={hit} />
              <br />
              <br />
            </>
          )}
        <Snippet attribute="textDescription" hit={hit} />
      </Description>
    </Comp>
  );
}

const Comp = styled.div`
  em.ais-Highlight-highlighted,
  em.ais-Snippet-highlighted {
    color: ${colors.yellowCallout};
    background-color: ${colors.yellowCalloutLight};
  }

  width: 100%;
`;

const Header = styled.div`
  margin: 0 0 20px 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0 0 5px 0;
`;

const Area = styled.div`
  // color: ${colors.gray};
`;

const Name = styled(NavLink)`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${colors.primary};

  &:hover {
    color: ${colors.primary};
  }
`;

const Description = styled.div`
  font-size: 0.9rem;
  line-height: 1.5rem;
`;

const Jurisdiction = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9rem;

  .bp3-icon {
    margin-right: 0.5ch;
  }
`;

const AddToReportLink = styled.button`
  display: none;

  @media (min-width: ${media.lg}) {
    display: flex;
    border: none;
    border-radius: ${radius};
    color: ${colors.yellowCallout};
    font-size: 0.8rem;
    margin: 0 20px 0 0;
    cursor: pointer;
    text-align: left;

    &:hover {
      background-color: ${colors.yellowCalloutLight};
      color: ${colors.yellowCallout};
    }

    .bp3-icon {
      color: ${colors.yellowCallout};
      margin-right: 8px;
    }
  }
`;
