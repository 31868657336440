import React from 'react';
import styled from 'styled-components';
import {Icon, Position, ProgressBar} from '@blueprintjs/core';
import {
  billStatusDescriptions,
  federalBillStatusDescriptions,
  quebecBillStatusDescriptions,
} from '../modules/options';
import {colors} from '../modules/styles';
import {Popover2} from '@blueprintjs/popover2';

export default function BillProgressBar({status, isInForce, jurisdiction}) {
  // Since "In Force" isn't a status, we'll have to force it to act like one
  if (isInForce) {
    status = 'In Force';
  }

  const statuses =
    jurisdiction === 'FED'
      ? federalBillStatusDescriptions
      : jurisdiction === 'QC'
      ? quebecBillStatusDescriptions
      : billStatusDescriptions;
  const stepSize = 1 / Object.keys(statuses).length;

  const getColour = index => {
    if (jurisdiction === 'FED') {
      if (index <= 4) {
        return colors.primary;
      } else if (index >= 5 && index <= 9) {
        return colors.secondary;
      } else {
        return colors.gray;
      }
    } else {
      return colors.gray;
    }
  };

  return (
    <Comp>
      <Title>Current Status: {status}</Title>

      <ProgressBar
        animate={false}
        stripes={false}
        value={
          (Object.keys(statuses).findIndex(s => s === status) + 1) * stepSize
        }
        intent="primary"
      />

      {jurisdiction === 'FED' && (
        <FedGroups>
          <Group style={{gridColumn: '1 / 6'}}>HOUSE</Group>
          <Group
            style={{
              gridColumn: '6 / 11',
              backgroundColor: colors.secondaryLight,
            }}
          >
            SENATE
          </Group>
        </FedGroups>
      )}

      <StepsWrapper>
        {Object.keys(statuses).map((s, index) => (
          <Popover2
            key={s}
            content={
              <PopoverContent>
                <DescTitle>
                  {s === 'Introduction (Senate)' ? 'First Reading (Senate)' : s}
                </DescTitle>
                <Desc>{statuses[s]}</Desc>
              </PopoverContent>
            }
            position={Position.BOTTOM}
            interactionKind="hover"
          >
            <ProgressLabel>
              <Icon icon="caret-up" style={{color: getColour(index)}} />
              <br />

              {s === 'Introduction (Senate)' ? (
                <Label>1R</Label>
              ) : s.startsWith('Second Reading') ? (
                <Label>2R</Label>
              ) : s.startsWith('Third Reading') ? (
                <Label>3R</Label>
              ) : (
                <Label>{s.match(/\b[A-Z]/g).join('')}</Label>
              )}
            </ProgressLabel>
          </Popover2>
        ))}
      </StepsWrapper>
    </Comp>
  );
}

const Comp = styled.div`
  line-height: 1.6rem;
  font-size: 1rem;
  margin: 30px 0;
`;

const Title = styled.div`
  margin: 25px 0 10px 0;
  font-weight: 600;
`;

const Label = styled.span`
  display: inline-block;
  line-height: 1;
  font-weight: 600;
`;

const StepsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProgressLabel = styled.div`
  display: inline-block;
  font-size: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  padding: 4px;
  cursor: pointer;
`;

const PopoverContent = styled.div`
  padding: 20px;
`;

const DescTitle = styled.h3`
  margin: 0 0 10px 0;
  font-size: 1.1rem;
  color: ${colors.secondary};
`;

const Desc = styled.p`
  max-width: 200px;
  font-size: 0.9rem;
  line-height: 1.3rem;
  margin: 0;
`;

const FedGroups = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 4px;
  margin: 4px 0 0 0;
`;

const Group = styled.div`
  background-color: ${colors.primaryLight};
  padding: 2px 4px;
  font-size: 0.7rem;
  line-height: 0.7rem;
  font-weight: 600;
`;
