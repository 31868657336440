import React, {useEffect, useState} from 'react';
import Tour from 'reactour';
import {colors} from '../modules/styles';
import useEvent from '../hooks/useEvent';
import useLocalStorage from '../hooks/useLocalStorage';
import Button from './Button';

export default function ChecklistsTour({isJurisdictionSelected}, props) {
  // Split into two parts since users with multiple jurisdictions won't be able to see some steps
  // until they select a jurisdiction and the table is loaded
  const [hasSeenPart1, setHasSeenPart1] = useLocalStorage(
    'checklistsTourPart1Seen',
    false
  );
  const [hasSeenPart2, setHasSeenPart2] = useLocalStorage(
    'checklistsTourPart2Seen',
    false
  );
  const [isOpen, setIsOpen] = useState(!hasSeenPart1 && !hasSeenPart2);

  useEvent('tour:start', () => {
    setHasSeenPart1(false);
    setHasSeenPart2(false);
    setIsOpen(true);
  });

  useEffect(() => {
    if (isJurisdictionSelected && !hasSeenPart2) {
      setIsOpen(true);
    }
  }, [isJurisdictionSelected, hasSeenPart2]);

  const steps = [
    {
      selector: '',
      content: (
        <div>
          <p>
            Welcome to <strong>Checklists</strong>.
          </p>
          <p>
            This quick feature tour will show you how to use Checklists to map
            out your HR compliance obligations.
          </p>
          <p>Click the arrow or use your keyboard's arrow keys to continue.</p>
        </div>
      ),
    },
    {
      selector: '[data-tour="help-menu"]',
      content: (
        <p>
          You can revisit this tour at any time by clicking here and selecting{' '}
          <strong>Restart Feature Tours</strong>.
        </p>
      ),
    },
    {
      selector: '[data-tour="checklists-inputs"]',
      content:
        'To get started, select the Jurisdiction where your employees work. You can also use the Number of Employees option to filter out requirements that do not apply to your workplace.',
    },
    ...(isJurisdictionSelected
      ? [
          {
            selector: '[data-tour="requirement-status"]',
            content:
              'Track the Status of your compliance with each requirement. Your selections are saved from one login to the next.',
          },
          {
            selector: '[data-tour="requirement-type-header"]',
            highlightedSelectors: [
              '[data-tour="requirement-type-header"]',
              '[data-tour="area-of-law-header"]',
            ],
            content:
              'Sort by Type of Requirement or Area of Law to prioritize certain tasks.',
          },
          {
            selector: '[data-tour="last-updated-header"]',
            content:
              'Sort by Last Updated to see any legislative amendments since the last time you reviewed your Checklist.',
          },
        ]
      : []),
  ];

  return (
    <React.Fragment>
      <Tour
        steps={steps}
        accentColor={colors.secondary}
        rounded={3}
        isOpen={isOpen}
        startAt={hasSeenPart1 ? 3 : 0}
        closeWithMask={false}
        disableInteraction={true}
        onRequestClose={() => {
          setIsOpen(false);
          isJurisdictionSelected
            ? setHasSeenPart2(true)
            : setHasSeenPart1(true);
        }}
        lastStepNextButton={
          <Button
            onClick={() => {
              setIsOpen(false);
              isJurisdictionSelected
                ? setHasSeenPart2(true)
                : setHasSeenPart1(true);
            }}
          >
            End Tour
          </Button>
        }
        {...props}
      />
    </React.Fragment>
  );
}
