import moment from 'moment';
import countdown from 'countdown';
import ENV from '../environment';

export function isOnFreeTrial(account) {
  if (!account.trialUntil || account.isSubscriptionActive) {
    return false;
  }

  const now = moment();
  return moment(account.trialUntil).isAfter(now);
}

export function hasValidSubscription(account) {
  if (account.id === ENV.OC_ACCOUNT_ID) {
    return true;
  }

  return account?.isSubscriptionActive;
}

export function getTimeRemaining(trialUntil) {
  const time = countdown(
    new Date(trialUntil),
    null,
    countdown.DAYS | countdown.HOURS | countdown.MINUTES,
    1
  ).toString();

  return time;
}
