import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';
import notifications from '../modules/notifications';
import SignupHeader from '../components/SignupHeader';
import fetcher from '../modules/fetcher';
import {jurisdictionList} from '../modules/jurisdictions';
import {colors, radius} from '../modules/styles';
import {Icon} from '@blueprintjs/core';
import {useHistory} from 'react-router-dom';
import PlanOptionSwitches from './PlanOptionSwitches';

export default function SignupCustomizePlanPg({onUpdate, data}) {
  const [plan, setPlan] = useState({});
  const history = useHistory();

  useEffect(() => {
    try {
      if (data.plan) {
        fetcher
          .get(`/api/subscriptionPlans/${data.plan}`)
          .then(res => setPlan(res.data))
          .catch(() =>
            notifications.error(
              `We encountered an error while loading the details of this plan. Please try again.`
            )
          );
      }
    } catch (e) {
      notifications.error(
        `We encountered an error while loading the details of this plan: ${e.data.message}`
      );
    }
  }, [data.plan]);

  const onContinue = () => {
    history.push('/signup/account');
  };

  if (!plan.id) {
    return null;
  }

  const isUnlimitedAreas = plan.numAreas >= (plan.availableAreas?.length || 0);
  const isUnlimitedJurs =
    plan.numJurisdictions >= Object.keys(jurisdictionList).length;

  return (
    <Comp>
      <Helmet title="Customize Your Free Trial" />
      <SignupHeader step={2} />

      <h1>Step 2. Customize Your Free Trial</h1>
      <p>
        Your free trial includes some customization. Please review the sections
        below and make your selections as required.
      </p>

      <PlanOptionSwitches
        selections={{...data, plan: plan}}
        onUpdate={onUpdate}
      />

      <Buttons>
        <NextButton
          disabled={
            (!isUnlimitedAreas && (data.areas?.length || 0) < plan.numAreas) ||
            (!isUnlimitedJurs &&
              (data.jurisdictions?.length || 0) < plan.numJurisdictions)
          }
          onClick={onContinue}
        >
          Continue <Icon icon="chevron-right" iconSize={22} />
        </NextButton>
      </Buttons>
    </Comp>
  );
}

const Comp = styled.div`
  width: 100%;

  h1 {
    font-size: 1.6rem;
    margin: 30px 0 10px 0;
    font-weight: 600;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NextButton = styled.button`
  padding: 15px 10px;
  border: none;
  background-color: ${colors.primary2};
  border-radius: ${radius};
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 150ms ease-out;

  &:hover {
    transform: scale(1.03);
    transition: all 150ms ease-out;
  }

  &:disabled {
    background-color: ${colors.gray2};
    cursor: not-allowed;
  }
`;
