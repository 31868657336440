import React from 'react';
import styled from 'styled-components';
import {Dialog} from '@blueprintjs/core';
import ReactPlayer from 'react-player/youtube';

export default function VideoModal({title, url, isOpen, onClose}) {
  return (
    <Comp>
      <VideoDialog
        title={title}
        isOpen={isOpen}
        onClose={() => onClose()}
        icon="info-sign"
      >
        <VideoWrapper>
          <VideoPlayer url={url} controls width={'100%'} height={'100%'} />
        </VideoWrapper>
      </VideoDialog>
    </Comp>
  );
}

const Comp = styled.div``;

const VideoDialog = styled(Dialog)`
  width: 640px;
`;

const VideoWrapper = styled.div`
  margin-bottom: -20px;
  position: relative;
  padding-top: 56.25%;
`;

const VideoPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;
