import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {useStore} from 'react-hookstore';

export default withRouter(({location, children}) => {
  const [currentUser] = useStore('currentUser');

  if (!currentUser) {
    const thisPage = location.pathname;
    localStorage.setItem('nextPage', thisPage);
    return <Redirect to="/login" />;
  }
  return children;
});
