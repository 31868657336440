import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import notifications from '../modules/notifications';
import fetcher from '../modules/fetcher';
import {jurisdictionList} from '../modules/jurisdictions';
import {colors, media, radius, shadowTight} from '../modules/styles';
import {Switch} from '@blueprintjs/core';

export default function PlanOptionSwitches({onUpdate, selections}) {
  const [plan, setPlan] = useState({});

  useEffect(() => {
    try {
      if (selections.plan?.id) {
        fetcher
          .get(`/api/subscriptionPlans/${selections.plan.id}`)
          .then(res => setPlan(res.data))
          .catch(() =>
            notifications.error(
              `We encountered an error while loading the details of this plan. Please try again.`
            )
          );
      }
    } catch (e) {
      notifications.error(
        `We encountered an error while loading the details of this plan: ${e.data.message}`
      );
    }
  }, [selections.plan.id]);

  const onToggleArea = area => {
    // Only allow if we haven't maxed out yet
    const areaSet = new Set(selections.areas);
    if (areaSet.has(area)) {
      areaSet.delete(area);
    } else if (!selections.areas || selections.areas?.length < plan.numAreas) {
      areaSet.add(area);
    }

    onUpdate({areas: [...areaSet]});
  };

  const onToggleJurisdiction = jur => {
    // Only allow if we haven't maxed out yet
    const jurSet = new Set(selections.jurisdictions);
    if (jurSet.has(jur)) {
      jurSet.delete(jur);
    } else if (
      !selections.jurisdictions ||
      selections.jurisdictions?.length < plan.numJurisdictions
    ) {
      jurSet.add(jur);
    }

    // Reset the selected areas each time to ensure we don't have invalid combos
    onUpdate({jurisdictions: [...jurSet], areas: []});
  };

  const isAreaInJur = (isUnlimitedJurs, area) => {
    // Only allow areas that are available and published in the selected jurisdictions
    if (isUnlimitedJurs) {
      return true;
    }

    if (
      selections.jurisdictions?.some(
        jur => area.jurisdictions.includes(jur) && area.publishedCount[jur] > 0
      )
    ) {
      return true;
    }

    return false;
  };

  if (!plan.id) {
    return null;
  }

  // const isUnlimitedAreas = plan.numAreas >= (plan.availableAreas?.length || 0);
  const isUnlimitedJurs =
    plan.numJurisdictions >= Object.keys(jurisdictionList).length;

  return (
    <Comp>
      <Row>
        <Box>
          <h2>Jurisdictions:</h2>
          {!isUnlimitedJurs && (
            <p>
              Your selected plan allows you to select{' '}
              <strong>
                {plan.numJurisdictions === 1
                  ? `1 Jurisdiction`
                  : `${plan.numJurisdictions} Jurisdictions`}
              </strong>
              . Please make your{' '}
              {plan.numJurisdictions === 1 ? 'selection' : 'selections'} below.
            </p>
          )}

          {isUnlimitedJurs && (
            <p>
              Your selected plan includes{' '}
              <strong>ALL of the following Jurisdictions</strong> which have
              been pre-selected for you:
            </p>
          )}

          <Options>
            {Object.keys(jurisdictionList)
              .sort()
              .map(jur => (
                <Option key={jur}>
                  <Switch
                    label={jurisdictionList[jur]}
                    checked={
                      isUnlimitedJurs ||
                      selections.jurisdictions?.includes(jur) ||
                      false
                    }
                    onChange={() => onToggleJurisdiction(jur)}
                    disabled={
                      isUnlimitedJurs ||
                      (!selections.jurisdictions?.includes(jur) &&
                        selections.jurisdictions?.length >=
                          plan.numJurisdictions)
                    }
                  />
                </Option>
              ))}
          </Options>
        </Box>

        {(isUnlimitedJurs ||
          selections.jurisdictions?.length === plan.numJurisdictions) && (
          <Box>
            <h2>Areas of Law:</h2>

            {plan.includedAreas.length > 0 && (
              <>
                <p>
                  Your selected plan includes{' '}
                  <strong>ALL of the following Areas of Law</strong> which have
                  been pre-selected for you:
                </p>
                <Options>
                  {plan.includedAreas
                    .filter(area => isAreaInJur(isUnlimitedJurs, area))
                    .map(area => (
                      <Option key={area.id}>
                        <Switch
                          label={area.name}
                          checked={true}
                          disabled={true}
                        />
                      </Option>
                    ))}
                </Options>
              </>
            )}

            {plan.numAreas > 0 && plan.availableAreas.length > 0 && (
              <>
                <br />
                <p>
                  Your selected plan allows you to select{' '}
                  <strong>
                    {plan.numAreas === 1
                      ? `one Area of Law`
                      : `${plan.numAreas} ${
                          plan.includedAreas.length ? 'additional' : ''
                        } Areas of Law`}
                  </strong>
                  . Please make your{' '}
                  {plan.numAreas === 1 ? 'selection' : 'selections'} below.
                </p>
                <Options>
                  {plan.availableAreas
                    .filter(
                      area =>
                        isAreaInJur(isUnlimitedJurs, area) &&
                        !plan.includedAreas.some(a => a.id === area.id)
                    )
                    .map(area => (
                      <Option key={area.id}>
                        <Switch
                          label={area.name}
                          checked={selections.areas?.includes(area.id) || false}
                          onChange={() => onToggleArea(area.id)}
                          disabled={
                            !selections.areas?.includes(area.id) &&
                            selections.areas?.length >= plan.numAreas
                          }
                        />
                      </Option>
                    ))}
                </Options>
              </>
            )}
          </Box>
        )}
      </Row>
    </Comp>
  );
}

const Comp = styled.div`
  width: 100%;

  h1 {
    font-size: 1.6rem;
    margin: 30px 0 10px 0;
    font-weight: 600;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;

  @media (min-width: ${media.md}) {
    flex-direction: row;
  }
`;

const Box = styled.div`
  padding: 20px;
  margin: 0 0 20px 0;

  box-shadow: ${shadowTight};
  border-radius: ${radius};
  background-color: white;
  border-top: 4px solid ${colors.secondary};

  h2 {
    margin: 0 0 20px 0;
  }

  @media (min-width: ${media.md}) {
    margin: 0px 0px 20px 0;
    width: 50%;

    &:first-of-type {
      margin-right: 20px;
    }
  }
`;

const Options = styled.div`
  margin: 20px 0 0 20px;
`;

const Option = styled.div`
  margin: 15px 0;
`;
