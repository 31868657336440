import React, {useState, useEffect} from 'react';
import {Alert, Checkbox} from '@blueprintjs/core';

export default function CovidNotice({currentArea, currentTopic}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (
      currentArea &&
      currentArea.id === '5e0f8483c1701a47b74ac2b3' &&
      currentTopic &&
      currentTopic._id === '5ec7115994be522133290502'
    ) {
      // Only show it if we haven't seen it in this session and the user didn't opt-out
      const optOut = localStorage.getItem('covidNotice2OptOut');
      const seenThisSession = sessionStorage.getItem('covidNotice2');

      if (!optOut && !seenThisSession) {
        setIsOpen(true);
      }
    } else {
      setIsOpen(false);
    }
  }, [currentArea, currentTopic]);

  const onConfirm = () => {
    if (isChecked) {
      localStorage.setItem('covidNotice2OptOut', 'true');
    }
    sessionStorage.setItem('covidNotice2', 'true');

    setIsOpen(false);
  };

  return (
    <Alert
      confirmButtonText="Ok"
      icon="info-sign"
      intent="primary"
      isOpen={isOpen}
      onConfirm={onConfirm}
    >
      <p>
        While we will continue to monitor and update the legislation referenced
        in the COVID-19 topic, we will no longer being reporting changes to
        COVID-19 regulatory policies.
      </p>

      <p>This topic was last updated on May 16, 2022.</p>

      <br />
      <p>
        <Checkbox
          checked={isChecked}
          label="Don't show this again"
          onChange={() => setIsChecked(!isChecked)}
        />
      </p>
    </Alert>
  );
}
