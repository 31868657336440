const ENV = {
  environment:
    process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development',
  SERVER_HOST: process.env.REACT_APP_SERVER_HOST,
  CLIENT_HOST: process.env.REACT_APP_CLIENT_HOST,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  OC_ACCOUNT_ID: process.env.REACT_APP_OC_ACCOUNT_ID,
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  ALGOLIA_APP_ID: process.env.REACT_APP_ALGOLIA_APP_ID,
  ALGOLIA_PUBLIC_KEY: process.env.REACT_APP_ALGOLIA_PUBLIC_KEY,
  HOME_PAGE: '/m/updates',
  ADMIN_HOME_PAGE: '/a/billTracker',
};

export default ENV;
