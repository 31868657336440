import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Dialog, Button, Icon, Position} from '@blueprintjs/core';
import fetcher from '../modules/fetcher';
import notifications from '../modules/notifications';
import {colors, radius} from '../modules/styles';
import uniq from 'lodash.uniq';
import JurisdictionPicker from './JurisdictionPicker';
import {
  filterJurisdictionsBySubscription,
  getNameByCode,
} from '../modules/jurisdictions';
import ENV from '../environment';
import {hasValidSubscription} from '../modules/subscriptions';

export default function JurisdictionComparer({
  currentUser,
  isOpen,
  provisionId,
  onClose,
}) {
  const [data, setData] = useState([]);
  const [jurs, setJurs] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const res = await fetcher.get(`/api/provisions/${provisionId}/allJurs`);
        if (res) {
          setData(res.data);
        }
      } catch (e) {
        console.error(e);
        notifications.error(`Error loading provision data: ${e.toString()}`);
      }
    };

    if (provisionId && isOpen) {
      loadData();
    } else {
      setData([]);
    }
  }, [provisionId, isOpen]);

  const onExport = async () => {
    const queryProvs = [selectedProv]
      .concat(provs)
      .map(prov => `provs[]=${prov.id}`)
      .join('&');
    const url = `${ENV.SERVER_HOST}/api/provisions/exportComparison?${queryProvs}&token=${fetcher.token}`;

    window.location = url;
  };

  const onCancel = () => {
    setData([]);

    if (onClose) {
      onClose();
    }
  };

  const onToggleJurisdiction = async jur => {
    let newJurs = [];
    if (jurs.includes(jur.id)) {
      // Remove the item
      newJurs = jurs.filter(j => j !== jur.id);
    } else {
      // Add
      newJurs = [...jurs, jur.id];
    }

    // De-dupe
    newJurs = uniq(newJurs);
    setJurs(newJurs);
  };

  const onRemoveJurisdiction = async jur => {
    if (jurs.includes(jur)) {
      // Remove the item
      const newJurs = jurs.filter(j => j !== jur);
      setJurs(newJurs);
    }
  };

  const onReset = () => setJurs([]);

  if (!data.length) {
    return null;
  }

  const selectedProv = data.find(d => d.id === provisionId);
  const provs = data.filter(d => jurs.includes(d.jurisdiction));

  const jurisdictionOptions = filterJurisdictionsBySubscription(
    currentUser,
    data
      .filter(d => d.jurisdiction !== selectedProv.jurisdiction)
      .map(d => d.jurisdiction)
  );

  return (
    <Comp>
      <Dialog
        isOpen={isOpen}
        title="Compare Jurisdictions"
        icon="comparison"
        onClose={onCancel}
      >
        <SelectJurs>
          <Label>Select Jurisdictions:</Label>
          <PickerHolder>
            <JurisdictionPicker
              items={jurisdictionOptions.map(jur => ({
                id: jur,
                name: getNameByCode(jur),
              }))}
              onSelect={onToggleJurisdiction}
              onRemove={onRemoveJurisdiction}
              placeholder="Select Jurisdictions to Compare"
              position={Position.BOTTOM}
              selectedItems={jurs.map(jur => ({
                id: jur,
                name: getNameByCode(jur),
              }))}
            />
          </PickerHolder>

          {jurs.length > 0 && (
            <Reset onClick={onReset}>Clear Jurisdiction Filter</Reset>
          )}
        </SelectJurs>

        <Main className="bp3-dialog-body">
          <Left>
            <h1>
              {selectedProv.name} ({selectedProv.jurisdiction})
            </h1>

            <Desc
              dangerouslySetInnerHTML={{__html: selectedProv?.description}}
            />
          </Left>
          <Right>
            {provs.length === 0 && (
              <NoJurs>
                <Icon icon="circle-arrow-up" iconSize={40} />
                Select one or more jurisdictions from above to get started.
              </NoJurs>
            )}

            {provs.map(prov => (
              <JurWrapper key={prov.id}>
                <h1>
                  <Icon icon="map" /> {prov.jurisdiction}
                </h1>
                <Desc dangerouslySetInnerHTML={{__html: prov.description}} />
              </JurWrapper>
            ))}
          </Right>
        </Main>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            {(currentUser.isStaff ||
              (hasValidSubscription(currentUser.account) &&
                currentUser.account.isPDFDownloadEnabled &&
                currentUser.account.isExcelDownloadEnabled)) && (
              <Button intent="none" onClick={onExport}>
                Download Excel Version
              </Button>
            )}
            <Button intent="primary" onClick={onCancel}>
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </Comp>
  );
}

const Comp = styled.div``;

const SelectJurs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  margin: 10px 0;

  .bp3-form-group {
    margin: 0;
  }

  .jurisdiction-picker {
    border: 1px solid red;
  }
`;

const PickerHolder = styled.div`
  width: 60%;
  min-width: 400px;
`;

const Label = styled.div`
  white-space: nowrap;
  margin: 0 20px 0 0;
  font-weight: bold;
`;

const Main = styled.div`
  display: flex;
  min-width: 900px;
  min-height: 500px;

  h1 {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    margin: 0 0 20px 0;

    .bp3-icon {
      margin-right: 10px;
    }
  }

  hr {
    border: none;
    border-top: 1px solid ${colors.gray2};
    margin: 0;
  }
`;

const Left = styled.div`
  padding: 0 20px 0 0;
  width: 350px;
  min-width: 350px;
`;

const Right = styled.div`
  display: flex;
  padding: 0 0 0 20px;
  width: 100%;
  overflow: auto;

  border-left: 1px dashed ${colors.gray2};
`;

const NoJurs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-weight: bold;
  font-size: 1.4rem;
  padding: 0 30px;

  color: ${colors.gray};

  .bp3-icon {
    margin: 0 0 30px 0;
  }
`;

const JurWrapper = styled.div`
  min-width: 300px;
  margin: 0 20px 0 0;
`;

const Desc = styled.div`
  background-color: white;
  border-radius: ${radius};
  padding: 10px;

  a {
    text-decoration: underline;
    color: ${colors.primary};
  }
`;

const Reset = styled.button`
  background: none;
  border: none;
  text-align: left;
  padding: 0;
  margin-left: 20px;
  color: ${colors.primary};
  cursor: pointer;
  font-size: 0.8rem;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;
