export const isPresent = val => val !== null && val !== undefined && val !== '';

export const isEmail = val => {
  if (!val) {
    return false;
  }

  // Verify the email address via regex from here: http://emailregex.com/
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const email = val.trim().toLowerCase();

  return email.match(regex) !== null;
};

export const isPhone = val => {
  const regex = /^\d{3}-\d{3}-\d{4}$/;
  const phone = val.trim();

  return phone.match(regex) !== null;
};

export const isValidPassword = val => {
  if (!val) {
    return false;
  }

  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  const pass = val.trim();

  return pass.match(regex) !== null;
};

export const isUrl = val => {
  const regex = /^(http(s)?:\/\/)?$/;
  const url = val.trim();

  return url.match(regex) !== null;
};

export const escapeForRegExp = str => {
  // From: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};
