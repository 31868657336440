// import React from 'react';
import * as GA from 'react-ga';
import {useEffect} from 'react';
import {withRouter} from 'react-router-dom';

function GATracker({id, location}) {
  useEffect(() => {
    GA.initialize(id);
  }, [id]);

  useEffect(() => {
    GA.set({page: location.pathname});
    GA.pageview(location.pathname);
  }, [location.pathname]);

  return null;
}

export default withRouter(GATracker);
