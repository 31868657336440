// import React from 'react';
import {useEffect} from 'react';
import {withRouter} from 'react-router-dom';

function HubspotTracker({location}) {
  useEffect(() => {
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', location.pathname]);
    _hsq.push(['trackPageView']);
  }, [location.pathname]);

  return null;
}

export default withRouter(HubspotTracker);
