import {useEffect} from 'react';
import {animateScroll, scroller} from 'react-scroll';
import {withRouter} from 'react-router-dom';

export default withRouter(({location, speed = 800}) => {
  useEffect(() => {
    const scrollToId = id => {
      // Must use "scroller" and not "animateScroll" in order to scroll to an anchor
      scroller.scrollTo(id, {
        duration: speed,
        delay: 0,
        smooth: 'eastOutQuad',
        offset: -70,
      });
    };

    if (location.hash) {
      const id = location.hash.substr(1);
      const MAX_TRIES = 60; // At 500ms, 60 = 30 seconds
      const INTERVAL_MS = 500;
      let tries = 0;

      // Wait for the item to exist before scrolling to it.
      const interval = setInterval(() => {
        if (document.getElementById(id)) {
          clearInterval(interval);
          tries = 0;
          scrollToId(id);
        } else {
          // Keep track of how many times we try because if the id doesn't exist on the page we'd be stuck in an infinite loop
          tries += 1;
          if (tries >= MAX_TRIES) {
            // Bail
            clearInterval(interval);
            tries = 0;
          }
        }
      }, INTERVAL_MS);
    } else {
      animateScroll.scrollToTop({
        duration: speed,
        delay: 0,
        smooth: 'eastOutQuad',
      });
    }
  }, [location, speed]);

  return null;
});
