import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {colors, media, radius} from '../modules/styles';
import fetcher from '../modules/fetcher';
import {NavLink} from 'react-router-dom';
import {PopoverInteractionKind, Position} from '@blueprintjs/core';
import {Popover2} from '@blueprintjs/popover2';
import {getTimeRemaining, hasValidSubscription} from '../modules/subscriptions';
import {useStore} from 'react-hookstore';

export default function FreeTrialHeader() {
  const [currentUser] = useStore('currentUser');
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [owner, setOwner] = useState();

  useEffect(() => {
    const calcRemaining = () => {
      if (currentUser.account.trialUntil) {
        const time = getTimeRemaining(currentUser.account.trialUntil);
        setTimeRemaining(time);
      }
    };

    let timer;

    if (!hasValidSubscription(currentUser.account)) {
      fetcher.get(`/api/accounts/${currentUser.account.id}/owner`).then(res => {
        setOwner(res.data);
      });

      // Set the default value
      calcRemaining();
      timer = setInterval(() => calcRemaining(), 1000 * 60);
    }

    // Clean up
    return () => clearInterval(timer);
  }, [currentUser]);

  const onClick = e => {
    if (currentUser.role !== 'ACCOUNT_OWNER') {
      e.preventDefault();
    }
  };

  if (timeRemaining === null || hasValidSubscription(currentUser.account)) {
    return null;
  }

  return (
    <Comp>
      <span>
        You are on a Free Trial version of Compliance Works. There are{' '}
        {timeRemaining} remaining in your Free Trial. Contact us to learn more
        about a paid subscription.
      </span>

      <Popover2
        interactionKind={PopoverInteractionKind.CLICK}
        position={Position.BOTTOM}
        disabled={currentUser.role === 'ACCOUNT_OWNER'}
        content={
          <UserNotice>
            <p>
              You have been added to this free trial by your Account Manager
              {owner && (
                <>
                  ,{' '}
                  <strong>
                    {owner.firstName} {owner.lastName}
                  </strong>
                </>
              )}
              .
            </p>
            <p>
              Please contact them for further details regarding a paid
              subscription.
            </p>
          </UserNotice>
        }
      >
        <CTA to="/m/activateSubscription" onClick={onClick}>
          Contact Us
        </CTA>
      </Popover2>
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.secondary};
  width: 100%;
  height: 40px;
  color: ${colors.black};
  margin: 60px 0 0 0; // Top accounts for header height
  padding: 0 15px;
  font-weight: 600;
  font-size: 0.8rem;

  @media (min-width: ${media.sm}) {
    font-size: 1rem;
  }
`;

const CTA = styled(NavLink)`
  display: block;
  margin: 0 0 0 30px;
  border: 1px solid ${colors.black};
  padding: 4px 6px;
  border-radius: ${radius};
  background-color: ${colors.secondary};
  color: ${colors.black};
  cursor: pointer;
  white-space: nowrap;
  transition: all 150ms ease-out;

  &:hover {
    transform: scale(1.05);
    transition: all 150ms ease-out;
    text-decoration: none;
  }
`;

const UserNotice = styled.div`
  width: 250px;
  padding: 20px;
`;
