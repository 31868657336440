import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Button from './Button';
import {colors} from '../modules/styles';

export default function DisclaimerPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const [wasSeen, setWasSeen] = useState(false);

  useEffect(() => {
    const seen = localStorage.getItem('disclaimerSeen');
    if (!seen || seen === 'false') {
      setTimeout(() => setIsOpen(true), 500);
    } else {
      setWasSeen(true);
    }
  }, []);

  const onClick = () => {
    setIsOpen(false);
    localStorage.setItem('disclaimerSeen', true);
  };

  if (wasSeen) {
    return null;
  }

  return (
    <Comp className={isOpen ? 'open' : ''}>
      <p>
        <strong>
          COMPLIANCE WORKS IS PROVIDED FOR GENERAL INFORMATION PURPOSES ONLY AND
          DOES NOT CONSTITUTE LEGAL OR OTHER PROFESSIONAL ADVICE OR AN OPINION
          OF ANY KIND. YOUR USE OF COMPLIANCE WORKS DOES NOT CREATE A
          LAWYER-CLIENT RELATIONSHIP.
        </strong>
      </p>
      <p>
        Compliance Works is provided as a tool for the cost-effective and
        convenient presentation of legal information. We do not warrant or
        guarantee that the information included in Compliance Works is an
        accurate or complete summary of an Area of Law. The Content is current
        as of the date of publication as noted in Compliance Works. Compliance
        Works is not a complete summary of the legislation included in an Area
        of Law, rather it is a curated collection of legal information.
      </p>
      <ButtonHolder>
        <Button onClick={onClick}>I acknowledge the above</Button>
      </ButtonHolder>
    </Comp>
  );
}

const Comp = styled.div`
  position: fixed;
  z-index: 20;
  bottom: -700px;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 30px;
  border-top: 3px solid ${colors.primary};
  transition: bottom 700ms ease-out;

  &.open {
    bottom: 0;
    transition: bottom 700ms ease-out;
  }
`;

const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0 0 0;
`;
