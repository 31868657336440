import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {colors} from '../modules/styles';
import fetcher from '../modules/fetcher';

export default function Notifications({highlightUnread = true}) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    // Do initial menu loading
    const loadData = async () => {
      try {
        const res = await fetcher.get('/api/notifications/mine');
        setItems(res.data);
      } catch (e) {
        // Fail silently since it's not super important
      }
    };

    loadData();
  }, []);

  return (
    <Comp data-tour="announcements">
      {items.length === 0 && <div>You're all caught up!</div>}
      {items.map(item => (
        <React.Fragment key={item.id}>
          <Item className={!item.seen && highlightUnread ? 'unseen' : 'seen'}>
            <Date>{moment(item.createdAt).format('MMMM Do')} </Date>

            {item.link ? (
              <URLTitle href={item.link}>{item.title}</URLTitle>
            ) : (
              <Title>{item.title}</Title>
            )}

            <Text dangerouslySetInnerHTML={{__html: item.text}} />
          </Item>
          <hr />
        </React.Fragment>
      ))}
    </Comp>
  );
}

const Comp = styled.div`
  hr {
    border: none;
    border-bottom: 1px solid ${colors.gray3};
  }
`;

const Item = styled.div`
  max-width: 300px;
  margin: 10px 0;
  padding: 10px 0;

  &.unseen {
    background-color: ${colors.gray4};
  }
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.7rem;
  margin: 0 0 5px 0;
`;

const Title = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 20px 0;
`;

const URLTitle = styled.a`
  display: block;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 20px 0;
  color: ${colors.primary};

  &:hover {
    color: ${colors.primary};
  }
`;

const Text = styled.div`
  font-size: 0.9rem;
`;
