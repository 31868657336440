import React, {useState, useEffect} from 'react';
import {Alert, Checkbox} from '@blueprintjs/core';

export default function HealthAndSafetyNotice({currentArea}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (currentArea && currentArea.id === '5e0f8483c1701a47b74ac2b3') {
      // Only show it if we haven't seen it in this session and the user didn't opt-out
      const optOut = localStorage.getItem('healthAndSafetyNoticeOptOut');
      const seenThisSession = sessionStorage.getItem('healthAndSafetyNotice');

      if (!optOut && !seenThisSession) {
        setIsOpen(true);
      }
    } else {
      setIsOpen(false);
    }
  }, [currentArea]);

  const onConfirm = () => {
    if (isChecked) {
      localStorage.setItem('healthAndSafetyNoticeOptOut', 'true');
    }
    sessionStorage.setItem('healthAndSafetyNotice', 'true');

    setIsOpen(false);
  };

  return (
    <Alert
      confirmButtonText="Ok"
      icon="info-sign"
      intent="primary"
      isOpen={isOpen}
      onConfirm={onConfirm}
    >
      <p>
        This Area of Law covers general health and safety compliance
        obligations. It does not address hazardous substances or industry
        specific requirements (e.g., construction, mining).
      </p>
      <p>
        If this type of information would be of interest to you, please contact
        us.
      </p>
      <br />
      <p>
        <Checkbox
          checked={isChecked}
          label="Don't show this again"
          onChange={() => setIsChecked(!isChecked)}
        />
      </p>
    </Alert>
  );
}
