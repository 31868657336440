import React, {useState} from 'react';
import styled from 'styled-components';
import {Checkbox, Position} from '@blueprintjs/core';
import {useStore} from 'react-hookstore';
import {colors, radius} from '../modules/styles';
import {Popover2} from '@blueprintjs/popover2';

export default function Provisions({provisions, children, onChange}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile] = useStore('isMobile');

  const handleChange = (id, selected) => {
    const newProvs = {...provisions, [id]: {...provisions[id], selected}};
    onChange(newProvs);
  };

  const uncheckAll = () => {
    const newProvs = {...provisions};
    Object.keys(newProvs).forEach(key => {
      newProvs[key].selected = false;
    });

    onChange(newProvs);
  };

  const checkAll = () => {
    const newProvs = {...provisions};
    Object.keys(newProvs).forEach(key => {
      newProvs[key].selected = true;
    });

    onChange(newProvs);
  };

  const handleInteraction = nextOpenState => setIsOpen(nextOpenState);

  const popoverContent = (
    <Content>
      <h3>Select which Provisions to include:</h3>

      {Object.keys(provisions)
        // .filter((id) => {
        //   // Only include the provision if at least one jurisdiction is published
        //   const hasPublished = provisions[id].jurisdictions.some(
        //     (j) => j.isPublished
        //   );
        //   return hasPublished;
        // })
        .map(id => (
          <Option key={id}>
            <Checkbox
              checked={
                provisions[id].selected === undefined
                  ? true
                  : provisions[id].selected
              }
              label={provisions[id].name}
              onChange={e => handleChange(id, e.target.checked)}
            />
          </Option>
        ))}

      <Buttons>
        <AllButton onClick={uncheckAll}>Uncheck All</AllButton>
        <AllButton onClick={checkAll}>Check All</AllButton>
      </Buttons>
    </Content>
  );

  return (
    <Comp className="provisions">
      <Popover2
        content={popoverContent}
        isOpen={isOpen}
        position={isMobile ? Position.BOTTOM : Position.RIGHT}
        onInteraction={state => handleInteraction(state)}
      >
        {children}
      </Popover2>
    </Comp>
  );
}

const Comp = styled.div``;

const Content = styled.div`
  padding: 20px;

  h3 {
    margin: 0 0 20px 0;
    font-size: 1rem;
  }
`;

const Option = styled.div`
  padding: 0px 15px;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const AllButton = styled.button`
  display: flex;
  align-items: center;
  color: ${colors.primary};
  border: none;
  background-color: ${colors.primaryLight};
  border-radius: ${radius};
  padding: 6px 10px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 0.9rem;
  margin: 0 5px;

  &:hover {
    background-color: ${colors.primary};
    color: white;
  }
`;
