import React from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {Icon} from '@blueprintjs/core';
import {radius, media} from '../modules/styles';
import CustomIcon from './CustomIcon';

export default function NavItemWhite({
  icon,
  customIcon,
  to,
  onClick,
  children,
}) {
  return (
    <Comp to={to} onClick={onClick}>
      {customIcon ? (
        <CustomIcon icon={customIcon} size={18} />
      ) : (
        <Icon icon={icon} iconSize={16} />
      )}
      {children}
    </Comp>
  );
}

const Comp = styled(NavLink)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 5px 0;
  padding: 10px 10px;
  font-size: 1rem;
  /* border-radius: ${radius}; */
  text-decoration: none;
  color: white;
  border-bottom: 2px solid transparent;

  &:hover {
    text-decoration: none;
    border-bottom: 2px solid white;
    color: white;
  }

  &.active {
    border-bottom: 2px solid white;
  }

  .bp3-icon,
  .custom-icon {
    color: white;
    margin: 0 8px 0 0;
  }

  @media (min-width: ${media.lg}) {
    padding: 5px 0px;
    margin: 0 10px 0 20px;
  }
`;
