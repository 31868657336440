import React, {useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import styled from 'styled-components';
import SignupHeader from '../components/SignupHeader';
import {colors, media, radius, shadowTight} from '../modules/styles';
import notifications from '../modules/notifications';
import fetcher from '../modules/fetcher';
import ENV from '../environment';
import Input from '../components/Input';
import ReCAPTCHA from 'react-google-recaptcha';
import {isEmail} from '../modules/validation';
import {useHistory} from 'react-router-dom';
// import FilterSelect from '../components/FilterSelect';
// import {provinceOptions} from '../modules/options';
// import SelectBox from '../components/SelectBox';
// import {Label} from '@blueprintjs/core';

export default function SignupBillingPg({data}) {
  const [formData, setFormData] = useState({
    businessName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    stateProv: '',
    country: 'Canada',
    zipPostal: '',
    errors: {},
  });
  const [captchaCode, setCaptchaCode] = useState('');
  const captchaRef = useRef();
  const history = useHistory();

  const onChange = (label, value) => {
    setFormData({...formData, [label]: value});
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};

    [
      'firstName',
      'lastName',
      'email',
      'businessName',
      // 'address1',
      // 'city',
      // 'stateProv',
      // 'country',
      // 'zipPostal',
      // 'phone',
    ].forEach(field => {
      if (!formData[field]) {
        errors[field] = true;
        isValid = false;
      }
    });

    // Check email domains
    if (
      formData.email.includes('gmail.') ||
      formData.email.includes('yahoo.') ||
      formData.email.includes('live.com') ||
      formData.email.includes('hotmail.') ||
      formData.email.includes('outlook.') ||
      formData.email.includes('inter-netsys.com') // Spam domain
    ) {
      errors.email = true;
      isValid = false;
      notifications.error(
        'Please ensure to use your business email address. Personal email addresses will not be accepted.'
      );
    }

    if (!isEmail(formData.email)) {
      errors.email = true;
      isValid = false;
      notifications.error(
        "Your email address doesn't appear to be valid. Please correct it and try again."
      );
    }

    if (!captchaCode) {
      errors.captcha = true;
      isValid = false;
    }

    setFormData({...formData, errors});
    return isValid;
  };

  const onSubmit = async e => {
    try {
      e.preventDefault();
      const isValid = validateForm();

      if (!isValid) {
        notifications.error('Please check the form for errors and try again.');
        return;
      }

      const res = await fetcher.post('/api/accounts', {...formData, ...data});
      if (res.data.success) {
        // Redirect to success page
        history.push('/signup/confirmation');
      } else {
        if (res.data.userExists) {
          notifications.error(
            `An account with the same email address already exists.`
          );
        } else {
          notifications.error(`Something went wrong: ${res.data.error}`);
        }
      }
    } catch (e) {
      console.error(e.message);
      notifications.error(e.message);
    }
  };

  if (!data.plan) {
    return null;
  }

  return (
    <Comp>
      <Helmet title="Account Info" />
      <SignupHeader step={3} />

      <h1>Step 3. Account Info</h1>
      <p>
        Please provide your contact information below so we can create your
        account. After submitting, you will receive an email confirmation to
        activate your free trial account.
      </p>

      <form>
        <Row>
          <Box>
            <h2>Your Account Details:</h2>
            <Input
              label="First Name:"
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={e => onChange('firstName', e.target.value)}
              leftIcon="user"
              large={true}
              fill={true}
              hasError={!!formData.errors.firstName}
              errorMsg={formData.errors.firstName}
            />
            <Input
              label="Last Name:"
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={e => onChange('lastName', e.target.value)}
              leftIcon="user"
              large={true}
              fill={true}
              hasError={!!formData.errors.lastName}
              errorMsg={formData.errors.lastName}
            />
            <Input
              label="Business Email:"
              type="email"
              name="email"
              placeholder="Business Email Address"
              value={formData.email}
              onChange={e => onChange('email', e.target.value)}
              leftIcon="envelope"
              large={true}
              fill={true}
              hasError={!!formData.errors.email}
              errorMsg={formData.errors.email}
            />
            {/* <br />
            <br />
            <h2>Business Details:</h2> */}
            <Input
              label="Business Name:"
              type="text"
              name="businessName"
              placeholder="Business Name"
              value={formData.businessName}
              onChange={e => onChange('businessName', e.target.value)}
              leftIcon="office"
              large={true}
              fill={true}
              hasError={!!formData.errors.businessName}
              errorMsg={formData.errors.businessName}
            />
            {/* <Input
              label="Phone Number:"
              type="telephone"
              name="phone"
              placeholder="Phone Number and Extension"
              value={formData.phone}
              onChange={e => {
                // Stripe has a limit of 20 chars
                if (e.target.value.length <= 20) {
                  onChange('phone', e.target.value);
                }
              }}
              leftIcon="phone"
              large={true}
              fill={true}
              hasError={!!formData.errors.phone}
              errorMsg={formData.errors.phone}
            />
            <Input
              label="Address 1:"
              type="text"
              name="address1"
              placeholder="Address 1"
              value={formData.address1}
              onChange={e => onChange('address1', e.target.value)}
              leftIcon="map-marker"
              large={true}
              fill={true}
              hasError={!!formData.errors.address1}
              errorMsg={formData.errors.address1}
            />
            <Input
              label="Address 2:"
              type="text"
              name="address2"
              placeholder="Address 2 (optional)"
              value={formData.address2}
              onChange={e => onChange('address2', e.target.value)}
              leftIcon="map-marker"
              large={true}
              fill={true}
              hasError={!!formData.errors.address2}
              errorMsg={formData.errors.address2}
            />
            <Input
              label="City:"
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={e => onChange('city', e.target.value)}
              leftIcon="map-marker"
              large={true}
              fill={true}
              hasError={!!formData.errors.city}
              errorMsg={formData.errors.city}
            />
            <Label style={{marginBottom: '5px'}}>Province:</Label>
            <FilterSelect
              id="stateProv"
              items={provinceOptions}
              onSelect={item => {
                onChange('stateProv', item.id);
              }}
              activeItem={{id: formData.stateProv || ''}}
              popoverProps={{minimal: true}}
            >
              <SelectBox hasError={formData.errors.stateProv}>
                {formData.stateProv ? formData.stateProv : 'Select a Province'}
              </SelectBox>
            </FilterSelect>
            <br />
            <Label style={{marginBottom: '5px'}}>Country:</Label>
            <Canada>Canada</Canada> */}
            {/* <FilterSelect
              id="country"
              items={countryOptions}
              onSelect={item => {
                onChange('country', item.name);
              }}
              activeItem={{id: formData.country || 'CA'}}
              popoverProps={{minimal: true}}
              disabled={true}
            >
              <SelectBox>
                {formData.country ? formData.country : 'Select a Country'}
              </SelectBox>
            </FilterSelect> */}
            {/* <br />
            <Input
              label="Postal Code:"
              type="text"
              name="zipPostal"
              placeholder="Postal Code"
              value={formData.zipPostal}
              onChange={e => onChange('zipPostal', e.target.value)}
              leftIcon="map-marker"
              large={true}
              fill={true}
              hasError={!!formData.errors.zipPostal}
              errorMsg={formData.errors.zipPostal}
            /> */}
            <br />
            <br />
            <h2>Password:</h2>
            You will be asked to create your password once you submit this form
            and click on the link in your welcome email.
            <br />
            <br />
          </Box>
        </Row>

        <ReCAPTCHA
          ref={captchaRef}
          sitekey={ENV.RECAPTCHA_SITE_KEY}
          onChange={code => setCaptchaCode(code)}
        />
        {formData.errors.captcha && (
          <CaptchaError>
            Please check the box above. {formData.errors.captcha}
          </CaptchaError>
        )}
      </form>

      <Buttons>
        <NextButton disabled={false} onClick={onSubmit}>
          Create Account &amp; Start Free Trial
        </NextButton>
      </Buttons>
    </Comp>
  );
}

const Comp = styled.div`
  width: 100%;

  h1 {
    font-size: 1.6rem;
    margin: 30px 0 10px 0;
    font-weight: 600;
  }

  form {
    margin: 0;
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  margin: 20px 0;
  width: 100%;

  @media (min-width: ${media.md}) {
    justify-content: center;
  }
`;

const Box = styled.div`
  padding: 20px;
  margin: 0 0 20px 0;

  box-shadow: ${shadowTight};
  border-radius: ${radius};
  background-color: white;
  border-top: 4px solid ${colors.secondary};

  h2 {
    margin: 0 0 20px 0;
  }

  @media (min-width: ${media.md}) {
    margin: 0px 0px 20px 0;
    width: 50%;

    &:first-of-type {
      margin-right: 20px;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  margin: 30px 0 30px 0;
  justify-content: center;
`;

const NextButton = styled.button`
  padding: 15px 20px;
  border: none;
  background-color: ${colors.primary2};
  border-radius: ${radius};
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 150ms ease-out;

  &:hover {
    transform: scale(1.03);
    transition: all 150ms ease-out;
  }

  &:disabled {
    background-color: ${colors.gray2};
    cursor: not-allowed;
  }
`;

const CaptchaError = styled.div`
  color: ${colors.danger};
  font-size: 0.9rem;
  margin: 5px 0 0 0;
`;

// const Canada = styled.div`
//   border: 1px solid ${colors.gray2};
//   border-radius: ${radius};
//   padding: 4px 6px;
//   cursor: not-allowed;
// `;
