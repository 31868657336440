import React from 'react';
import styled from 'styled-components';
import {media} from '../modules/styles';

export default function DesktopOnly({children}) {
  return <Comp>{children}</Comp>;
}

const Comp = styled.span`
  display: none;

  @media (min-width: ${media.lg}) {
    display: inline;
  }
`;
