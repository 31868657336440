class EventMgr {
  constructor() {
    this.subscribers = [];
  }

  addSubscriber = (types, cb) => {
    this.subscribers.push({types, cb});
  };

  removeSubscriber = cb => {
    this.subscribers = this.subscribers.filter(sub => sub.cb !== cb);
  };

  notifySubscribers = event => {
    this.subscribers.forEach(sub => {
      if (Array.isArray(sub.types) ? sub.types.includes(event.type) : sub.types === event.type) {
        sub.cb(event);
      }
    });
  };

  emit = async (type, data = null) => {
    const event = {
      type,
      data
    };

    this.notifySubscribers(event);
  };
}

// Create and expose the singleton instance
export const EventManager = new EventMgr();

// Expose the emit method directly as a shortcut
export const {emit} = EventManager;
