import React from 'react';
import styled from 'styled-components';

import {colors} from '../modules/styles';

const Comp = styled.span`
  display: inline-block;
  svg {
    fill: ${colors.gray};
  }

  &.white svg {
    fill: white;
  }
`;

export const icons = {
  scales: (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      // width="460px"
      // height="460px"
      viewBox="0 0 460 460"
      // style="enable-background:new 0 0 460 460;"
    >
      <g>
        <path
          fill="currentColor"
          d="M279.27,392.188h-30.51V112.579h128.58l-54.464,86.423c-2.762,4.382-1.448,10.176,2.935,12.937
			c1.554,0.98,3.282,1.445,4.992,1.445c3.115,0,6.161-1.551,7.944-4.378l47.235-74.954l47.233,74.954
			c2.763,4.382,8.557,5.696,12.938,2.933c4.382-2.761,5.694-8.555,2.933-12.937l-54.462-86.423h0.688
			c8.029,0,14.539-6.51,14.539-14.539c0-8.028-6.509-14.538-14.539-14.538H288.356c-6.04-8.786-14.954-15.438-25.39-18.596
			C261.475,48.005,247.289,34.75,230,34.75c-17.29,0-31.475,13.255-32.967,30.156c-10.436,3.158-19.35,9.81-25.389,18.596H64.688
			c-8.03,0-14.54,6.51-14.54,14.538c0,8.029,6.509,14.539,14.54,14.539h0.688l-54.464,86.423
			c-2.762,4.382-1.448,10.176,2.934,12.937c1.554,0.98,3.283,1.445,4.992,1.445c3.114,0,6.162-1.551,7.945-4.378l47.235-74.954
			l47.234,74.954c2.762,4.382,8.555,5.696,12.937,2.933c4.383-2.761,5.696-8.555,2.935-12.937l-54.463-86.423h128.58v279.607
			h-30.509c-18.259,0-33.062,14.804-33.062,33.063h164.661C312.331,406.99,297.528,392.188,279.27,392.188z"
        />
        <path
          fill="currentColor"
          d="M143.035,223.359H5c-2.761,0-5,2.238-5,5c0,40.813,33.204,74.017,74.018,74.017c40.813,0,74.018-33.204,74.018-74.017
			C148.035,225.598,145.796,223.359,143.035,223.359z"
        />
        <path
          fill="currentColor"
          d="M455,223.359H316.965c-2.761,0-5,2.238-5,5c0,40.813,33.204,74.017,74.019,74.017c40.813,0,74.017-33.204,74.017-74.017
			C460,225.598,457.761,223.359,455,223.359z"
        />
      </g>
    </svg>
  )
};

export default function CustomIcon({icon, white = false, size = '16px'}) {
  return (
    <Comp
      className={`custom-icon ${white ? 'white' : ''} ${icon}`}
      style={{
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        margin: icon === 'ic' ? '0 10px 0 5px' : ''
      }}
    >
      {icons[icon]}
    </Comp>
  );
}
