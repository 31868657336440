import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.svg';
import {emit} from '../modules/eventManager';
import {colors, media} from '../modules/styles';

export default function AccountLockedHeader() {
  return (
    <Comp>
      <img src={logo} alt="Compliance Works" />

      <Message>
        Your free trial has now expired.
        <br />
        To continue using Compliance Works, please activate your subscription
        below.
        <br />
        <div>
          <a href="mailto:info@complianceworks.ca">Contact Us</a>
          <Logout onClick={() => emit('user:loggedOut')}>Log Out</Logout>
        </div>
      </Message>
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  width: 100%;
  padding: 0px 30px;
  border-bottom: 1px solid ${colors.gray2};
  background-color: white;
  margin: 0 0 20px 0;

  img {
    margin: 15px 0 0 0;
    width: 150px;
  }

  @media (min-width: ${media.sm}) {
    flex-direction: row;
    padding: 10px 30px;
    align-items: center;
    justify-content: space-between;

    img {
      margin: 0 40px 0 0;
      width: 190px;
    }
  }
`;

const Message = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
  margin: 20px 0;

  a {
    color: ${colors.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: ${media.sm}) {
    text-align: right;
    margin: 0;
  }
`;

const Logout = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 10px 0 0 20px;
  color: ${colors.primary};
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
