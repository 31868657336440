import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import orderBy from 'lodash.orderby';
import {useStore} from 'react-hookstore';
import {colors, media, radius} from '../modules/styles';
import {Button, Drawer, Icon} from '@blueprintjs/core';

import BillDetails from './BillDetails';
import ESRDetails from './ESRDetails';
import ActRegLink from './ActRegLink';
import {getNameByCode} from '../modules/jurisdictions';
import ENV from '../environment';
import TimeMachineComparer from './TimeMachineComparer';
import JurisdictionComparer from './JurisdictionComparer';
import fetcher from '../modules/fetcher';
import notifications from '../modules/notifications';
import NoteCard from './NoteCard';
import useAddToReport from '../hooks/useAddToReport';
import AddToReportButton from './AddToReportButton';

export default function ReportContents({
  provisions,
  industries,
  showAreaName = true,
  isNotesEnabled = true,
  isRemoveEnabled = false,
  isReportIssueEnabled = true,
  isTimeMachineEnabled = true,
  isPermalinkEnabled = true,
  onRemoveSection,
  highlightText,
  highlightIndustry,
}) {
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(false);
  const [detailsPanelTitle, setDetailsPanelTitle] = useState('Details');
  const [detailsPanelContent, setDetailsPanelContent] = useState(null);
  const [compareVersionId, setCompareVersionId] = useState(null);
  const [compareJurisdictionId, setCompareJurisdictionId] = useState(null);
  const [updatedProvs, setUpdatedProvs] = useState(null);
  const [accountUsers, setAccountUsers] = useState([]);
  const [currentUser] = useStore('currentUser');
  const {createReport, appendToReport} = useAddToReport();

  let lastArea;
  let lastProvisionGroup;

  useEffect(() => {
    // Save provs in state so the provs shown in the UI will update when one is added or deleted
    provisions && setUpdatedProvs(provisions);
  }, [provisions]);

  useEffect(() => {
    const getAccountUsers = async () => {
      try {
        const usersRes = await fetcher.get(
          `/api/accounts/${currentUser.account.id}/users`
        );
        setAccountUsers(
          usersRes.data.filter(
            user => user.id !== currentUser.id && user.isActive
          )
        );
      } catch (e) {
        notifications.error(`Error loading account users: ${e.message}`);
      }
    };

    isNotesEnabled && getAccountUsers();
  }, [isNotesEnabled, currentUser.account.id, currentUser.id]);

  const getIndustry = id => industries?.find(i => i.id === id);

  const showBillDetails = (bills, prov) => {
    const content = (
      <DetailsPanel>
        <h4>This provision is affected by the following:</h4>

        {orderBy(
          bills,
          ['introductionDate', 'assentDate', 'inForceDate'],
          'desc'
        ).map((bill, index) => (
          <BillDetails
            key={index}
            bill={bill}
            prov={prov}
            onTimeMachineClick={() => setCompareVersionId(prov._id)}
            isTimeMachineEnabled={prov.hasVersions && isTimeMachineEnabled}
          />
        ))}
      </DetailsPanel>
    );

    setDetailsPanelTitle('Bill Details');
    setDetailsPanelContent(content);
    setIsDetailsPanelOpen(true);
  };

  const showExemptionDetails = exemption => {
    const highlighted = {
      ...exemption,
      description: exemption.description,
    };
    const content = (
      <DetailsPanel>
        <h4>This industry/occupation is exempt due to the following:</h4>
        <ESRDetails sre={highlighted} />
      </DetailsPanel>
    );

    setDetailsPanelTitle('Exemption Details');
    setDetailsPanelContent(content);
    setIsDetailsPanelOpen(true);
  };

  const showSpecialRuleDetails = rule => {
    const content = (
      <DetailsPanel>
        <h4>
          This industry/occupation has special rules due to the following:
        </h4>
        <ESRDetails sre={rule} />
      </DetailsPanel>
    );

    setDetailsPanelTitle('Special Rule Details');
    setDetailsPanelContent(content);
    setIsDetailsPanelOpen(true);
  };

  const closeDetailsPanel = () => {
    setDetailsPanelTitle('Details');
    setDetailsPanelContent(null);
    setIsDetailsPanelOpen(false);
  };

  const sortByIndustry = ESRs => {
    const sortedExemptions = [];
    ESRs.forEach(ex => {
      ex.industries.forEach(ind => {
        const indName = getIndustry(ind);
        sortedExemptions.push({
          name: indName ? indName.name : 'N/A',
          data: ex,
        });
      });
    });

    return orderBy(sortedExemptions, 'name');
  };

  const compareVersion = async provId => {
    setCompareVersionId(provId);
  };

  const compareJurisdictions = async provId => {
    setCompareJurisdictionId(provId);
  };

  const getSectionHighlights = keyword => {
    if (
      keyword?.toLowerCase().startsWith('s.') ||
      keyword?.toLowerCase().startsWith('s. ')
    ) {
      const sectionNums = keyword
        .replace(/^\w\.\s*/, '')
        .split(/\s+|,/)
        .filter(Boolean);

      return sectionNums;
    } else {
      return [];
    }
  };

  const onDeleteNote = async (provId, noteId) => {
    try {
      if (noteId) {
        await fetcher.delete(`/api/notes/${noteId}`);
      }

      const newProvs = [...updatedProvs];
      const provIndex = newProvs.findIndex(prov => prov._id === provId);
      const notes = newProvs[provIndex].notes.filter(
        note => note._id !== noteId
      );

      newProvs[provIndex] = {...newProvs[provIndex], notes: notes};
      setUpdatedProvs([...newProvs]);
    } catch (e) {
      notifications.error(`Error deleting note: ${e.message}`);
    }
  };

  const onSaveNote = async (provId, noteId, text, reminder, sharedWith) => {
    try {
      let res;

      if (!noteId) {
        res = await fetcher.post(`/api/notes`, {
          provisionId: provId,
          text,
          reminder,
          sharedWith,
        });
      } else {
        res = await fetcher.patch(`/api/notes/${noteId}`, {
          text,
          reminder,
          sharedWith,
        });
      }

      const newNote = res.data;

      // For updating our local copy of things
      setUpdatedProvs(provs => {
        const newProvs = [...provs];
        const provIndex = newProvs.findIndex(prov => prov._id === provId);

        if (Array.isArray(newNote)) {
          newProvs[provIndex].notes = newNote;
        } else {
          const noteIndex = newProvs[provIndex].notes.findIndex(
            note => note._id === noteId
          );
          newProvs[provIndex].notes[noteIndex] = newNote;
        }

        return newProvs;
      });
    } catch (e) {
      notifications.error(`Error saving note: ${e.message}`);
    }
  };

  const onNewNote = async provId => {
    setUpdatedProvs(provs => {
      const newProvs = [...provs];
      const provIndex = newProvs.findIndex(prov => prov._id === provId);
      newProvs[provIndex].notes.push({
        provision: provId,
        text: '',
        reminder: '',
        sharedWith: [],
      });

      return newProvs;
    });
  };

  return (
    <Comp>
      <Drawer
        position="right"
        size={400}
        icon="search-template"
        isOpen={isDetailsPanelOpen}
        title={detailsPanelTitle}
        onClose={closeDetailsPanel}
      >
        {detailsPanelContent}
      </Drawer>

      {isTimeMachineEnabled && (
        <TimeMachineComparer
          isOpen={!!compareVersionId}
          isEditable={false}
          provisionId={compareVersionId}
          onClose={() => {
            setCompareVersionId(null);
          }}
        />
      )}

      <JurisdictionComparer
        currentUser={currentUser}
        isOpen={!!compareJurisdictionId}
        provisionId={compareJurisdictionId}
        onClose={() => {
          setCompareJurisdictionId(null);
        }}
      />

      {updatedProvs
        ?.map(prov => {
          const output = [];

          // We need to show the various AoL names in the saved reports
          if (showAreaName && prov.area.id !== lastArea) {
            lastArea = prov.area.id;
            output.push(
              <Area key={prov.area.id} className="area-header">
                {prov.area.name}
              </Area>
            );
          }

          // If the provision is not published then don't show it unless this is a staff user
          if (!prov.isPublished && !currentUser.isStaff) {
            return null;
          }

          const exemptions = prov.specialRules
            ? prov.specialRules.filter(
                sre => sre.type === 'Exemption' && sre.industries.length
              )
            : [];
          const specialRules = prov.specialRules
            ? prov.specialRules.filter(
                sre => sre.type === 'Special Rule' && sre.industries.length
              )
            : [];

          // We need to sort the exemptions and special rules alphabetically by the industry name. This is
          // a bit tricky though because each ESR can have multiple industries, so we need to flatten everything first
          // before we can do any sorting
          const sortedExemptions = sortByIndustry(exemptions);
          const sortedRules = sortByIndustry(specialRules);

          const hasActiveBill =
            prov.bills && prov.bills.filter(b => b.isActive).length > 0;

          const reportIssueBody = encodeURIComponent(
            `Hi,\n\nI'd like to report an issue with the following Provision:\n\nArea of Law: ${prov.area?.name}\nTopic: ${prov.topic?.name}\nProvision: ${prov.name}\nJurisdiction: ${prov.jurisdiction}\n`
          );

          const jurBlock = (
            <Jurisdiction
              key={prov.jurisdiction}
              className={`${prov.isPublished ? '' : 'not-published'}`}
            >
              <JursLeft>
                <JurisdictionName>
                  <Icon icon="map" />
                  <JurDateWrapper>
                    {getNameByCode(prov.jurisdiction)}

                    <LastUpdated>
                      Updated:{' '}
                      {moment(prov.updatedAt || prov.updatedAt).format(
                        'MMM DD, YYYY'
                      )}
                    </LastUpdated>
                  </JurDateWrapper>
                </JurisdictionName>

                {prov.hasVersions && isTimeMachineEnabled && (
                  <CompareVersion onClick={() => compareVersion(prov._id)}>
                    <Icon icon="comparison" iconSize={12} /> Compare to Previous
                    Versions
                  </CompareVersion>
                )}

                {(currentUser.account.selectedJurisdictions.length === 0 ||
                  currentUser.account.selectedJurisdictions.length > 1) && (
                  <CompareJurisdictions
                    className="compare-jurisdictions"
                    onClick={() => compareJurisdictions(prov.id)}
                  >
                    <Icon icon="comparison" iconSize={12} /> Compare
                    Jurisdictions
                  </CompareJurisdictions>
                )}

                <AddToReportButton
                  onCreate={name => createReport(name, [prov.id])}
                  onAppend={reportId => appendToReport(reportId, [prov.id])}
                >
                  <AddToReportLink onClick={e => e.preventDefault()}>
                    <Icon icon="add" iconSize={12} /> Add To Report
                  </AddToReportLink>
                </AddToReportButton>

                {isReportIssueEnabled && (
                  <ReportAnIssue
                    className="report-an-issue"
                    href={`mailto:info@complianceworks.ca?subject=Issue with Provision&body=${reportIssueBody}`}
                  >
                    <Icon icon="issue" iconSize={12} /> Report an Issue
                  </ReportAnIssue>
                )}

                {prov.area?.id && prov.topic?.id && isPermalinkEnabled && (
                  <ProvPermalink
                    className="prov-permalink"
                    href={`${ENV.CLIENT_HOST}/m/builder/${prov.area.id}/${prov.topic.id}#${prov._id}`}
                  >
                    <Icon icon="link" iconSize={12} /> Permalink
                  </ProvPermalink>
                )}

                {isRemoveEnabled && (
                  <Delete
                    className="delete"
                    onClick={() => onRemoveSection(prov._id)}
                  >
                    <Icon icon="trash" iconSize={10} /> Remove this Section
                  </Delete>
                )}
              </JursLeft>
              <JursRight>
                <Act className={`${prov.isPublished ? '' : 'not-published'}`}>
                  <ActRegLink
                    name={prov.act?.name}
                    url={prov.act?.url}
                    sections={prov.actSections}
                    highlightSections={getSectionHighlights(highlightText)}
                  />
                  <ActRegLink
                    name={prov.regulations?.name}
                    url={prov.regulations?.url}
                    sections={prov.regSections}
                    highlightSections={getSectionHighlights(highlightText)}
                  />
                </Act>

                <Cols>
                  <div style={{width: '100%'}}>
                    {/* NOTE: We're checking "textWarning" here because "warning" may have empty <p> tags so we can't rely on its length */}
                    {prov.textWarning && (
                      <Warning>
                        <Icon icon="error" />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: prov.warning,
                          }}
                        ></div>
                      </Warning>
                    )}

                    {/* Only show the callout if at least one bill is specified */}
                    {hasActiveBill && (
                      <BillTracker>
                        <BillTrackerHeading>
                          <div>
                            <Icon icon="warning-sign" />
                            <strong>
                              This provision is affected by {prov.bills.length}{' '}
                              {prov.bills.length === 1
                                ? 'amendment.'
                                : 'amendments.'}
                            </strong>
                          </div>
                          <BillDetailsButton
                            onClick={() => showBillDetails(prov.bills, prov)}
                          >
                            View details
                          </BillDetailsButton>
                        </BillTrackerHeading>
                      </BillTracker>
                    )}

                    <Description
                      dangerouslySetInnerHTML={{
                        __html:
                          prov.description || '<i>Details coming soon.</i>',
                      }}
                    />

                    {prov.references?.length > 0 && (
                      <References>
                        <p>Also see:</p>
                        <ul>
                          {prov.references.map(reference => (
                            <li key={reference._id}>
                              <a
                                href={`/m/builder/${reference.area.id}/${reference.topic.id}#${reference._id}`}
                              >{`${reference.topic.name}: ${reference.name} (${reference.area.name})`}</a>
                            </li>
                          ))}
                        </ul>
                      </References>
                    )}
                  </div>

                  <div>
                    {sortedExemptions.length > 0 && (
                      <SRECallout>
                        <SREHeading>
                          <Icon icon="info-sign" />{' '}
                          <strong>
                            The following industries/occupations are exempt from
                            this provision (click for details):
                          </strong>
                        </SREHeading>
                        <Industries>
                          {sortedExemptions.map((ex, index) => (
                            <Industry
                              key={`ex_${index}`}
                              onClick={() => showExemptionDetails(ex.data)}
                              className={`
                                  ${
                                    highlightText
                                      ? prov.matchedESRs?.length > 0 &&
                                        prov.matchedESRs.find(
                                          m => m._id === ex.data._id
                                        )
                                        ? 'matched'
                                        : 'not-matched'
                                      : ''
                                  }

                                  ${
                                    highlightIndustry &&
                                    ex.data.industries.includes(
                                      highlightIndustry.id
                                    )
                                      ? 'matched'
                                      : 'not-matched'
                                  }
                                `}
                            >
                              {ex.name}
                            </Industry>
                          ))}
                        </Industries>
                      </SRECallout>
                    )}

                    {sortedRules.length > 0 && (
                      <SRECallout>
                        <SREHeading>
                          <Icon icon="info-sign" />
                          <strong>
                            The following industries/occupations have special
                            rules related to this provision (click for details):
                          </strong>
                        </SREHeading>
                        <Industries>
                          {sortedRules.map((rule, index) => (
                            <Industry
                              key={`sr_${index}`}
                              onClick={() => showSpecialRuleDetails(rule.data)}
                              className={`
                                  ${
                                    highlightText
                                      ? prov.matchedESRs?.length > 0 &&
                                        prov.matchedESRs.find(
                                          m => m._id === rule.data._id
                                        )
                                        ? 'matched'
                                        : 'not-matched'
                                      : ''
                                  }

                                    ${
                                      highlightIndustry &&
                                      rule.data.industries.includes(
                                        highlightIndustry.id
                                      )
                                        ? 'matched'
                                        : 'not-matched'
                                    }
                                `}
                            >
                              {rule.name}
                            </Industry>
                          ))}
                        </Industries>
                      </SRECallout>
                    )}
                  </div>
                </Cols>
              </JursRight>
              {isNotesEnabled && (
                <NotesWrapper data-tour="note">
                  <AddNote
                    className="add-note"
                    onClick={() => onNewNote(prov._id)}
                  >
                    <Button
                      intent="primary"
                      icon={<Icon icon="plus" size={20} />}
                    />
                    <span>Add a Note/Reminder</span>
                  </AddNote>

                  {prov.notes?.map((note, idx) => (
                    <NoteCard
                      key={`${note._id}_${idx}`}
                      note={note}
                      onSave={onSaveNote}
                      onDelete={onDeleteNote}
                      users={accountUsers}
                    />
                  ))}
                </NotesWrapper>
              )}
            </Jurisdiction>
          );

          let provHeader = null;
          if (prov.provisionGroup !== lastProvisionGroup) {
            lastProvisionGroup = prov.provisionGroup;
            provHeader = (
              <Name
                key={`provHeader-${prov.provisionGroup}`}
                id={prov.provisionGroup}
                name={prov.provisionGroup}
                className="name-header"
              >
                <TopicName>
                  {prov.area?.name}: {prov.topic?.name}
                </TopicName>
                <ProvisionName>
                  {prov.name}{' '}
                  {/* {prov.area?.id && prov.topic?.id && prov.provisionGroup && (
                    <Permalink
                      href={`${ENV.CLIENT_HOST}/m/builder/${prov.area.id}/${prov.topic.id}#${prov.provisionGroup}`}
                    >
                      <Icon icon="link" iconSize={14} />
                    </Permalink>
                  )} */}
                </ProvisionName>
              </Name>
            );
          }

          output.push(
            provHeader,
            <a
              className="anchor"
              href="/" // Needed for a11y
              key={`anchor-${prov._id}`}
              id={prov._id}
            >
              {prov.name} {/* a value is needed for a11y */}
            </a>,
            <Provision key={prov._id}>{jurBlock}</Provision>
          );

          return output;
        })
        .filter(Boolean)}
    </Comp>
  );
}

const Comp = styled.div`
  /* https://stackoverflow.com/questions/10732690/offsetting-an-html-anchor-to-adjust-for-fixed-header */
  a.anchor {
    display: block;
    position: relative;
    top: -40px;
    font-size: 0px;
    line-height: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    visibility: hidden;
  }
`;

const DetailsPanel = styled.section`
  padding: 20px;
  overflow-y: auto;

  h4 {
    margin: 0 0 20px 0;
  }
`;

// const Permalink = styled.a`
//   display: flex;
//   align-items: center;
//   margin: 5px 0 0 15px;
//   color: ${colors.gray2};

//   .bp3-icon {
//     color: ${colors.gray2} !important;
//   }

//   &:hover {
//     .bp3-icon {
//       color: ${colors.primary} !important;
//     }
//   }
// `;

const Area = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  margin: 0 0 20px 0;
  color: ${colors.secondary};

  .bp3-icon {
    color: ${colors.primary};
    margin-right: 15px;
  }
`;

const Provision = styled.div`
  font-size: 1rem;

  .bp3-icon {
    color: ${colors.primary};
    margin-right: 5px;
  }

  /* This adds space above a name-header after a provision, giving space between main sections */
  & + .area-header {
    margin-top: 50px;
  }

  /* This adds space above a name-header after a provision, giving space between main sections */
  & + .name-header {
    margin-top: 30px;
  }
`;

const Jurisdiction = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  &.not-published {
    opacity: 0.5;
  }

  @media (min-width: ${media.lg}) {
    flex-direction: row;
    margin: 30px 0 10px 0;

    &:hover .delete,
    &:hover .report-an-issue,
    &:hover .prov-permalink {
      display: flex;
    }

    // &:hover .add-note {
    //   visibility: visible;
    //   cursor: pointer;
    // }
  }
`;

const JurisdictionName = styled.div`
  display: flex;
  .bp3-icon {
    color: ${colors.primary};
    margin-right: 5px;
  }
`;

const JurDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 5px 0;

  @media (min-width: ${media.lg}) {
    flex-direction: column;
    margin: 0;
  }
`;

const JursLeft = styled.div`
  @media (min-width: ${media.lg}) {
    min-width: 180px;
    max-width: 180px;
  }
`;

const JursRight = styled.div`
  width: 100%;
`;

const Name = styled.div`
  position: sticky;
  top: 59px;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: white;
  padding: 5px 2px; // The 2px on the sides helps because a "J" bleeds out the left side a little when the header is sticky
  margin: 10px 0 10px 0;
  border-bottom: 1px solid ${colors.gray2};
`;

const ProvisionName = styled.div`
  display: flex;
  align-items: center;
`;

const TopicName = styled.div`
  font-size: 0.8rem;
`;

const LastUpdated = styled.div`
  font-size: 0.8rem;

  @media (min-width: ${media.lg}) {
    font-size: 0.8rem;
  }
`;

const Delete = styled.button`
  display: none;
  border: none;
  border-radius: ${radius};
  color: ${colors.danger};
  font-size: 0.8rem;
  margin: 10px 0 0 0;
  padding: 4px 8px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.dangerLight};
  }

  .bp3-icon {
    color: ${colors.danger};
    margin-right: 8px;
  }
`;

const CompareVersion = styled.button`
  display: none;

  @media (min-width: ${media.lg}) {
    display: flex;
    border: none;
    border-radius: ${radius};
    color: ${colors.yellowCallout};
    font-size: 0.8rem;
    margin: 10px 40px 0 0;
    padding: 4px 8px;
    cursor: pointer;
    text-align: left;

    &:hover {
      background-color: ${colors.yellowCalloutLight};
      color: ${colors.yellowCallout};
    }

    .bp3-icon {
      color: ${colors.yellowCallout};
      margin-right: 8px;
    }
  }
`;

const CompareJurisdictions = styled(CompareVersion)`
  display: none;

  @media (min-width: ${media.lg}) {
    display: flex;
  }
`;

const AddToReportLink = styled(CompareVersion)`
  display: none;

  @media (min-width: ${media.lg}) {
    display: flex;
  }
`;

const ReportAnIssue = styled.a`
  display: none;
  border: none;
  border-radius: ${radius};
  color: ${colors.yellowCallout};
  font-size: 0.8rem;
  margin: 10px 40px 0 0;
  padding: 4px 8px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${colors.yellowCalloutLight};
    color: ${colors.yellowCallout};
    text-decoration: none;
  }

  .bp3-icon {
    color: ${colors.yellowCallout};
    margin-right: 8px;
  }
`;

const ProvPermalink = styled.a`
  display: none;
  border: none;
  border-radius: ${radius};
  color: ${colors.yellowCallout};
  font-size: 0.8rem;
  margin: 10px 40px 0 0;
  padding: 4px 8px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${colors.yellowCalloutLight};
    color: ${colors.yellowCallout};
    text-decoration: none;
  }

  .bp3-icon {
    color: ${colors.yellowCallout};
    margin-right: 8px;
  }
`;

const Act = styled.div`
  background-color: ${colors.primaryLight};
  border-radius: ${radius};
  padding: 5px;
  width: 100%;

  a {
    color: ${colors.primary};
  }

  &.not-published {
    background-color: ${colors.gray3};

    a {
      color: ${colors.gray};
    }
  }
`;

const Warning = styled.div`
  display: flex;
  color: ${colors.redCallout};
  background-color: ${colors.redCalloutLight};
  border-left: 2px solid ${colors.redCallout};
  border-radius: ${radius};
  padding: 10px 10px;
  margin: 20px 0 0 0;
  font-size: 0.9rem;

  .bp3-icon {
    color: ${colors.redCallout};
    margin-right: 10px;
  }

  a {
    color: ${colors.redCallout};
    text-decoration: underline;
    font-weight: 600;
  }

  p {
    margin: 0 0 10px 0;

    &:last-of-type {
      margin: 0;
    }
  }
`;

const Description = styled.div`
  margin: 20px 0;

  a {
    color: ${colors.primary};
    text-decoration: underline;
    font-weight: 600;
  }

  mark {
    background-color: ${colors.searchHighlight};
  }
`;

const References = styled.div`
  li {
    margin-bottom: 10px;
  }

  a {
    color: ${colors.primary};
  }
`;

const BillTracker = styled.div`
  background-color: ${colors.yellowCalloutLight};
  border-left: 2px solid ${colors.yellowCallout};
  border-radius: ${radius};
  padding: 10px 10px;
  margin: 20px 0 0 0;
`;

const BillTrackerHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.yellowCallout};

  strong {
    margin-right: 20px;
    font-size: 0.9rem;
    font-weight: 600;
  }

  span {
    text-decoration: underline;
  }

  .bp3-icon {
    color: ${colors.yellowCallout};
    margin-right: 10px;
  }
`;

const BillDetailsButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  color: ${colors.yellowCallout};
  cursor: pointer;
  font-size: 0.9rem;
`;

const SREHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 0.9rem;

  .bp3-icon {
    color: ${colors.primary};
    margin-right: 10px;
  }
`;

const Cols = styled.div`
  display: flex;
  flex-direction: column;
`;

const SRECallout = styled.div`
  border-radius: ${radius};
  padding: 10px 10px 10px 10px;
  margin: 0 0 20px 0;
  border-top: 2px solid ${colors.primaryLight};

  @media (min-width: ${media.lg}) {
    border: none;
    margin: 0 0 0 10px;
  }
`;

const Industries = styled.div`
  margin-left: 25px;
`;

const Industry = styled.div`
  display: inline-block;
  background-color: ${colors.primaryLight};
  color: ${colors.primary};
  border-radius: ${radius};
  padding: 4px 8px;
  margin: 0 5px 5px 0;
  cursor: pointer;
  font-size: 0.8rem;

  &.matched {
    background-color: ${colors.searchHighlight};
    color: ${colors.yellowCallout};

    &:hover {
      background-color: ${colors.yellowCallout};
      color: ${colors.searchHighlight};
    }
  }

  &:hover {
    background-color: ${colors.primary};
    color: white;
  }
`;

const NotesWrapper = styled.div`
  display: none;
  position: absolute;
  right: -240px;
  width: 270px;
  padding: 0px 10px 10px 10px;

  .bp3-card {
    margin: 10px 0px;
    padding: 3px;
  }

  @media (min-width: ${media.xl}) {
    display: block;
  }
`;

const AddNote = styled.div`
  // visibility: hidden;
  cursor: pointer;

  span {
    color: ${colors.primary};
    font-weight: bold;
    margin-left: 10px;
    font-size: 0.9rem;
  }

  .bp3-button {
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgb(16 22 26 / 10%), 0 1px 1px rgb(16 22 26 / 20%),
      0 2px 6px rgb(16 22 26 / 20%);
  }
`;
