import React from 'react';
import styled from 'styled-components';
import {colors} from '../modules/styles';
import {Icon} from '@blueprintjs/core';

export default function BillTrackerTableColumnHeading({
  label,
  field,
  sortField,
  currentSort,
  currentSortDir,
  onClick,
  width = '10%',
  showSortIcon = false,
  ...props
}) {
  const isActive = field === currentSort || sortField === currentSort;

  return (
    <Comp
      width={width}
      clickable={!!onClick}
      className={isActive ? 'active' : ''}
      onClick={() => (onClick ? onClick(field) : null)}
      {...props}
    >
      {label}
      {(isActive || showSortIcon) && (
        <Icon
          icon={
            isActive && currentSortDir === 'desc' ? 'sort-desc' : 'sort-asc'
          }
        />
      )}
    </Comp>
  );
}

const Comp = styled.th`
  ${({clickable}) => (clickable ? 'cursor: pointer' : '')}
  width: ${props => props.width};

  &.active {
    color: ${colors.primary};
  }

  .bp3-icon {
    margin-left: 10px;
  }
`;
