import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import SignupHeader from '../components/SignupHeader';
import fetcher from '../modules/fetcher';
import notifications from '../modules/notifications';
import {emit} from '../modules/eventManager';
import {colors, radius} from '../modules/styles';
import Helmet from 'react-helmet';
import PasswordReset from '../components/PasswordReset';
import ENV from '../environment';

export default function SignupPasswordPg({history, match, showSteps = true}) {
  const [account, setAccount] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const decoded = atob(match.params.code);
    const [accountId, userId] = decoded?.split(':');

    setAccount(accountId);
    setUser(userId);
  }, [match.params.code]);

  const onSubmit = async password => {
    try {
      const res = await fetcher.patch('/signup/setPassword', {
        userId: user,
        accountId: account,
        password,
      });

      if (res && res.data && account) {
        emit('user:loggedIn', res.data);

        notifications.success('Welcome to Compliance Works!');
        history.push(ENV.HOME_PAGE);
      }
    } catch (e) {
      console.error(e.message);
      notifications.error(e.message);
    }
  };

  return (
    <Comp>
      <Helmet title="Email Confirmation" />
      <SignupHeader step={5} disabled={true} showSteps={showSteps} />

      <h1>
        To get started with your account, use the form below to set your
        password.
      </h1>

      <Content>
        <Box>
          <PasswordReset onSubmit={onSubmit} showTerms={true} />
        </Box>
      </Content>
    </Comp>
  );
}

const Comp = styled.div`
  width: 100%;

  h1 {
    font-size: 1.6rem;
    margin: 30px 0 40px 0;
    font-weight: 600;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
  }

  img {
    margin: 50px 0 20px 0;
    width: 200px;
  }
`;

const Box = styled.div`
  width: 500px;
  max-width: 500px;
  background-color: white;
  border-radius: ${radius};
  padding: 30px;
  border: 1px solid ${colors.gray2};

  h1 {
    margin: 0 0 20px 0;
    font-size: 1.2rem;
  }

  h2 {
    font-size: 1rem;
  }

  p {
    margin: 0 0 30px 0;
  }
`;
