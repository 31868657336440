import React from 'react';
import fetcher from '../modules/fetcher';
import notifications from '../modules/notifications';

export default function useAddToReport() {
  const createReport = async (name, provisionIds) => {
    try {
      const res = await fetcher.post('/api/reports', {
        name,
        provisions: provisionIds,
        isShared: false,
      });

      notifications.success(
        <span>
          Report created successfully!{' '}
          <a href={`/m/my/reports/${res.data.id}`}>View Report</a>
        </span>
      );
    } catch (e) {
      notifications.error(`Error creating new report: ${e.message}`);
    }
  };

  const appendToReport = async (reportId, provisionIds) => {
    try {
      const res = await fetcher.patch(`/api/reports/${reportId}`, {
        provisions: provisionIds,
      });

      notifications.success(
        <span>
          Report updated successfully!{' '}
          <a href={`/m/my/reports/${res.data.id}`}>View Report</a>
        </span>
      );
    } catch (e) {
      notifications.error(`Error updating report: ${e.message}`);
    }
  };

  return {createReport, appendToReport};
}
