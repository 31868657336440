import React, {useState, useEffect} from 'react';
import {Alert, Checkbox} from '@blueprintjs/core';

export default function OfficialLanguagesNotice({currentArea, currentTopic}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (currentArea && currentArea.id === '623b29a47b46f803daa30eac') {
      // Only show it if we haven't seen it in this session and the user didn't opt-out
      const optOut = localStorage.getItem('officialLanguagesNoticeOptOut');
      const seenThisSession = sessionStorage.getItem('officialLanguagesNotice');

      if (!optOut && !seenThisSession) {
        setIsOpen(true);
      }
    } else {
      setIsOpen(false);
    }
  }, [currentArea, currentTopic]);

  const onConfirm = () => {
    if (isChecked) {
      localStorage.setItem('officialLanguagesNoticeOptOut', 'true');
    }
    sessionStorage.setItem('officialLanguagesNotice', 'true');

    setIsOpen(false);
  };

  return (
    <Alert
      confirmButtonText="Ok"
      icon="info-sign"
      intent="primary"
      isOpen={isOpen}
      onConfirm={onConfirm}
    >
      <p>
        This Area of Law covers official language requirements related to
        employer obligations and the language of work.
      </p>

      <br />
      <p>
        <Checkbox
          checked={isChecked}
          label="Don't show this again"
          onChange={() => setIsChecked(!isChecked)}
        />
      </p>
    </Alert>
  );
}
