import React from 'react';
import styled from 'styled-components';
import {colors} from '../modules/styles';
import {Icon} from '@blueprintjs/core';

export default function SignupSteps({current, disabled = false}) {
  const getClass = num => {
    if (num === current) {
      return 'active';
    }
    if (num < current) {
      return 'prev';
    }
    if (num > current) {
      return 'next';
    }
  };

  const onStepClick = (e, step) => {
    if (disabled || getClass(step) !== 'prev') {
      e.preventDefault();
    }
  };

  return (
    <Comp current={current}>
      <ol className={disabled ? 'disabled' : ''}>
        <li className={getClass(1)}>
          <a href="/signup/start" onClick={e => onStepClick(e, 1)}>
            <Icon icon="tick-circle" /> 1. Sign Up for a Free Trial
          </a>
        </li>
        <li className={getClass(2)}>
          <a href="/signup/customize" onClick={e => onStepClick(e, 2)}>
            <Icon icon="tick-circle" /> 2. Customize Your Free Trial
          </a>
        </li>
        <li className={getClass(3)}>
          <a href="/signup/account" onClick={e => onStepClick(e, 3)}>
            <Icon icon="tick-circle" /> 3. Account Info
          </a>
        </li>
        <li className={getClass(4)}>
          <a href="/signup/confirmation" onClick={e => onStepClick(e, 4)}>
            <Icon icon="tick-circle" /> 4. Email Confirmation
          </a>
        </li>
      </ol>
    </Comp>
  );
}

const Comp = styled.div`
  font-weight: 700;

  ol {
    display: flex;
    flex-direction: column;

    @media (min-width: 950px) {
      & {
        flex-direction: row;
      }
    }

    &.disabled a {
      cursor: not-allowed !important;
    }

    li {
      display: flex;
      align-items: center;
      margin: 0 0 4px 0;

      @media (min-width: 950px) {
        margin: 0 0 0 50px;
      }
    }

    a {
      display: flex;
      align-items: center;
      /* margin: 0 0 4px 0; */

      .bp3-icon {
        margin: 0 5px 0 0;
        color: ${colors.primary2};
        display: none;
      }

      &:hover {
        text-decoration: none;
      }
    }

    li.active a {
      color: ${colors.primary};
      font-weight: 700;
      cursor: default;
    }

    li.prev a {
      color: ${colors.gray};
      font-weight: 600;
      cursor: pointer;

      .bp3-icon {
        display: block;
      }
    }

    li.next a {
      color: ${colors.gray2};
      font-weight: 400;
      cursor: not-allowed;
    }
  }
`;
