import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import debounce from 'lodash.debounce';
import {animateScroll} from 'react-scroll';
import {useStore} from 'react-hookstore';
import BuilderFilters from '../components/BuilderFilters';
import fetcher from '../modules/fetcher';
import notifications from '../modules/notifications';
import NoData from '../assets/no_data.svg';
import ReportContents from '../components/ReportContents';
import uniq from 'lodash.uniq';
import {colors, media, radius} from '../modules/styles';
import {jurisdictionCodes} from '../modules/jurisdictions';
import {Spinner} from '@blueprintjs/core';
import HealthAndSafetyNotice from '../components/HealthAndSafetyNotice';
import ReportBuilderTour from '../components/ReportBuilderTour';
import CovidNotice from '../components/CovidNotice';
import useEvent from '../hooks/useEvent';
import useAddToReport from '../hooks/useAddToReport';
import OfficialLanguagesNotice from '../components/OfficialLanguagesNotice';

// This is stored OUTSIDE of the component so that we can update it and don't need to watch for a re-render. We
// could probably also have used useRef() but this is fine too.
let LAST_QUERY = '';

export default function BuilderPg({history, match, location}) {
  const [isLoading, setIsLoading] = useState(true);
  const [areas, setAreas] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [reportData, setReportData] = useState([]);

  const [selectedArea, setSelectedArea] = useState();
  const [selectedTopic, setSelectedTopic] = useState();
  const [selectedProvs, setSelectedProvs] = useState({});
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [currentTab, setCurrentTab] = useState('area');
  const [searchText, setSearchText] = useState('');
  const [highlightText, setHighlightText] = useState('');
  const [currentUser] = useStore('currentUser');
  const {createReport, appendToReport} = useAddToReport();

  const [jurs, setJurs] = useState(() => {
    // If a URL hash is present (for permalinks), we need to clear any persisted
    // jurisdiction selections to ensure the provision will be visible
    const prevJurs =
      localStorage.getItem('selectedJurisdictions') && !location.hash
        ? JSON.parse(localStorage.getItem('selectedJurisdictions'))
        : [];

    // Make sure they're valid
    const validJurs = prevJurs.filter(j => jurisdictionCodes.includes(j));
    return validJurs.length > 0 ? validJurs : [];
  });

  const onClearJurisdictions = async () => {
    localStorage.setItem('selectedJurisdictions', []);
    setJurs([]);

    // Reload the report
    setReportData([]);
    if (currentTab === 'area') {
      await loadData(selectedProvs, [], selectedIndustry);
    } else if (currentTab === 'search' && searchText) {
      await loadDataViaSearch(searchText, []);
    }
  };

  const onClearIndustry = async () => {
    setSelectedIndustry(null);

    // Reload the report
    setReportData([]);

    await loadData(selectedProvs, jurs, null);
  };

  const loadData = useCallback(async (provs, jurs, ind = null) => {
    try {
      setIsLoading(true);

      if (provs) {
        // Build a list of selected provisions
        const provIds = Object.keys(provs).filter(
          id => provs[id].selected === true
        );

        const res = await fetcher.post('/api/buildReport', {
          provisions: provIds,
          jurisdictions: jurs,
          industry: ind?.id,
        });

        setReportData(res.data);
      } else {
        setReportData([]);
      }
      setIsLoading(false);
    } catch (e) {
      notifications.error(`Error building report: ${e.message}`);
      setReportData([]);
    }
  }, []);

  const loadDataViaSearch = async (text, jurs) => {
    try {
      setIsLoading(true);

      if (text && text.length > 2) {
        // Because we may have issued multiple queries while the user was typing, there's no
        // guarantee that the results are coming back in the same order, so we should only accept
        // results that match the latest query. To do that, we need to store the last query we used.
        // * Note that the LAST_QUERY var is stored OUTSIDE of the component as the hook lifecycle is a bitch.
        LAST_QUERY = text;

        const res = await fetcher.post(`/api/buildReportViaSearch`, {
          text: text,
          jurisdictions: jurs,
        });

        const {query, results} = res.data;

        // Only update our results if they match our most recent query
        if (query === LAST_QUERY) {
          setReportData(results);
          setHighlightText(text);
        }
      } else {
        setReportData([]);
        setHighlightText('');
      }
      setIsLoading(false);
    } catch (e) {
      notifications.error(`Error building report: ${e.message}`);
      setReportData([]);
      setHighlightText('');
    }
  };

  const scrollToTop = () => {
    animateScroll.scrollToTop({
      duration: 800,
      delay: 0,
      smooth: 'eastOutQuad',
    });
  };

  const setArea = async area => {
    // Make sure the selected jurs are allowed in this area
    const newJurs = jurs.filter(j => area.jurisdictions.includes(j));
    setJurs(newJurs);
    setSelectedArea(area);
    setSelectedTopic(area.topics[0]);

    const provsObj = await loadProvisionsForTopic(area.topics[0]);

    await loadData(provsObj, newJurs, selectedIndustry);
    scrollToTop();

    // Update the url
    if (area?.id && area?.topics[0]?._id) {
      history.push(`/m/builder/${area.id}/${area.topics[0]._id}`);
    }
  };

  const setTopic = async topic => {
    setSelectedTopic(topic);

    const provsObj = await loadProvisionsForTopic(topic);

    await loadData(provsObj, jurs, selectedIndustry);
    scrollToTop();

    // Update the url
    history.push(`/m/builder/${selectedArea.id}/${topic._id}`);
  };

  const setProvs = async provs => {
    setSelectedProvs(provs);
    await loadData(provs, jurs, selectedIndustry);
    scrollToTop();
  };

  const setIndustry = async ind => {
    if (ind) {
      setSelectedIndustry(ind);
      await loadData(selectedProvs, jurs, ind);
      scrollToTop();
    }
  };

  useEvent('search', e => {
    // Someone used the header search
    if (currentTab !== 'search') {
      setCurrentTab('search');
    }
    onSearchChange(e.data);
  });

  const onSearchChange = async text => {
    setSearchText(text);
    await debouncedDoSearch(text, jurs);
  };

  const debouncedDoSearch = useCallback(
    debounce(async (text, juris) => {
      await loadDataViaSearch(text, juris);
      scrollToTop();
    }, 750),
    []
  );

  const setTab = async tab => {
    setReportData([]);
    setCurrentTab(tab);

    if (tab === 'area') {
      // We need to reset the Jurs again in case someone added disabled jurs while in Industry or Keyword Search
      const newJurs = jurs.filter(j => selectedArea.jurisdictions.includes(j));
      setJurs(newJurs);
      await loadData(selectedProvs, newJurs);
    } else if (tab === 'search' && searchText) {
      await loadDataViaSearch(searchText, jurs);
    }

    scrollToTop();
  };

  const resetSelectedProvs = useCallback((provisions, selectedTopic) => {
    const provsObj = {};
    if (selectedTopic) {
      provisions
        .filter(p => p.topic.id === selectedTopic._id)
        .forEach(p => {
          provsObj[p.provisionGroup] = {
            name: p.name,
            selected: true,
            isPublished: provsObj[p.provisionGroup] // if it's already true then keep it that way
              ? provsObj[p.provisionGroup]
              : p.isPublished,
          };
        });
    }

    return provsObj;
  }, []);

  const loadProvisionsForTopic = useCallback(
    async topic => {
      if (topic) {
        // Load the list of Provisions for the selected Topic
        const provsRes = await fetcher.get(
          `/api/topics/${topic._id}/provisions`
        );
        const provsObj = resetSelectedProvs(provsRes.data, topic);
        setSelectedProvs(provsObj);

        return provsObj;
      } else {
        setSelectedProvs({});
      }
    },
    [resetSelectedProvs]
  );

  // First time load... Populate the pulldowns
  useEffect(() => {
    const loadOptionsData = async () => {
      try {
        const [areasRes, indRes] = await Promise.all([
          fetcher.get('/api/areas/withPrunedTopics'),
          fetcher.get('/api/industries'),
          // fetcher.get('/api/acts'),
        ]);

        setAreas(areasRes.data);
        setIndustries(indRes.data);
        // setActs(actsRes.data);

        // If the URL contains ids then use those
        const area = match.params.areaId
          ? areasRes.data?.find(a => a.id === match.params.areaId)
          : areasRes.data[0];
        setSelectedArea(area);

        const topic = match.params.topicId
          ? area?.topics.find(t => t._id === match.params.topicId)
          : area?.topics[0];
        setSelectedTopic(topic);

        // If the URL doesn't contain any ids let's updated it with the current ones
        if (
          (!match.params.areaId || !match.params.topicId) &&
          match.path !== '/m/search'
        ) {
          history.replace(`/m/builder/${area.id}/${topic._id}`);
        }

        // Load the list of Provisions for the selected Topic
        const provsObj = await loadProvisionsForTopic(topic);

        // Make sure the selected jurs are allowed in this area
        const newJurs = jurs.filter(j => area.jurisdictions.includes(j));
        setJurs(newJurs);

        await loadData(provsObj, newJurs);
      } catch (e) {
        notifications.error(`Error loading menu data: ${e.message}`);
      }
    };

    loadOptionsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData, resetSelectedProvs]);

  useEffect(() => {
    // If the user clicked on the main header button again it won't have any ids set so we need to deal with that
    if (
      (!match.params.areaId || !match.params.topicId) &&
      selectedArea &&
      selectedTopic
    ) {
      setSearchText('');
      setHighlightText('');
      setTab('area');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.areaId, match.params.topicId, match.path]);

  const onToggleJursdiction = async jur => {
    let newJurs = [];
    if (jurs.includes(jur.id)) {
      // Remove the item
      newJurs = jurs.filter(j => j !== jur.id);
    } else {
      // Add
      newJurs = [...jurs, jur.id];
    }

    // De-dupe
    newJurs = uniq(newJurs);

    setJurs(newJurs);
    persistJurisdictions(newJurs);

    // Reload the report
    setReportData([]);
    if (currentTab === 'area') {
      await loadData(selectedProvs, newJurs, selectedIndustry);
    } else if (currentTab === 'search' && searchText) {
      await loadDataViaSearch(searchText, newJurs);
    }
  };

  const onRemoveJurisdiction = async jur => {
    if (jurs.includes(jur)) {
      // Remove the item
      const newJurs = jurs.filter(j => j !== jur);
      setJurs(newJurs);
      persistJurisdictions(newJurs);

      // Reload the report
      setReportData([]);
      if (currentTab === 'area') {
        await loadData(selectedProvs, newJurs, selectedIndustry);
      } else if (currentTab === 'search' && searchText) {
        await loadDataViaSearch(searchText, newJurs);
      }
    }
  };

  const persistJurisdictions = jurs => {
    localStorage.setItem('selectedJurisdictions', JSON.stringify(jurs));
  };

  const handleCreateReport = async name => {
    createReport(
      name,
      reportData.map(p => p.id)
    );
  };

  const handleAppendToReport = async reportId => {
    appendToReport(
      reportId,
      reportData.map(p => p.id)
    );
  };

  const getNumResults = () => {
    if (currentUser.isStaff) {
      return reportData.length;
    } else {
      return reportData.filter(p => p.isPublished).length;
    }
  };

  const numSearchResults = getNumResults();

  return (
    <Comp>
      <Helmet title="Info Hub" />
      <ReportBuilderTour />
      <HealthAndSafetyNotice currentArea={selectedArea} />
      <CovidNotice currentArea={selectedArea} currentTopic={selectedTopic} />
      <OfficialLanguagesNotice currentArea={selectedArea} />

      <BuilderFilters
        currentUser={currentUser}
        areas={areas}
        jurisdictions={jurs}
        industries={industries}
        selectedArea={selectedArea}
        selectedTopic={selectedTopic}
        selectedProvs={selectedProvs}
        selectedIndustry={selectedIndustry}
        setArea={setArea}
        setTopic={setTopic}
        setProvs={setProvs}
        setSelectedIndustry={setIndustry}
        currentTab={currentTab}
        setCurrentTab={setTab}
        onToggleJursdiction={onToggleJursdiction}
        onRemoveJursdiction={onRemoveJurisdiction}
        onReset={onClearJurisdictions}
        onResetIndustry={onClearIndustry}
        onCreateReport={handleCreateReport}
        onAppendToReport={handleAppendToReport}
        hasResults={reportData.length > 0}
      />

      <Content>
        {currentTab === 'area' && selectedArea && selectedTopic && (
          <h1>
            {selectedArea.name}: {selectedTopic.name}
          </h1>
        )}
        {currentTab === 'industry' && (
          <h1>
            Industry Search
            {selectedIndustry ? `: ${selectedIndustry?.name}` : ''}
            {selectedIndustry &&
              !isLoading &&
              ` (${numSearchResults} ${
                numSearchResults === 1 ? 'result' : 'results'
              })`}
          </h1>
        )}
        {currentTab === 'search' && (
          <h1>
            Search Results
            {highlightText && highlightText === searchText && !isLoading
              ? `: ${highlightText} (${numSearchResults} ${
                  numSearchResults === 1 ? 'result' : 'results'
                })`
              : ''}
          </h1>
        )}

        {isLoading && currentTab === 'search' && (
          <Loading>
            <Spinner />
          </Loading>
        )}

        {!isLoading && reportData.length > 0 && searchText === highlightText && (
          <Box>
            <ReportContents
              provisions={
                jurs.length
                  ? reportData.filter(p => jurs.includes(p.jurisdiction))
                  : currentTab === 'area'
                  ? reportData.filter(p =>
                      selectedArea.jurisdictions.includes(p.jurisdiction)
                    )
                  : reportData
              }
              industries={industries}
              showAreaName={false}
              isRemoveEnabled={false}
              highlightText={
                currentTab === 'search' && highlightText ? highlightText : ''
              }
              highlightIndustry={
                currentTab === 'industry' && selectedIndustry
                  ? selectedIndustry
                  : ''
              }
              onCreateReport={createReport}
              onAppendToReport={appendToReport}
            />
          </Box>
        )}

        {reportData.length === 0 && !isLoading && (
          <NoReports>
            <img src={NoData} alt="No results found" />

            <h2>No results found.</h2>
            <p>Please adjust your filters and try again.</p>
          </NoReports>
        )}
      </Content>
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (min-width: ${media.lg}) {
    flex-direction: row;
    padding: 0px;
  }
`;

const Content = styled.section`
  margin: 20px 0 0 0;
  width: 100%;

  @media (min-width: ${media.lg}) {
    margin: 20px 46px 0 330px;
  }

  @media (min-width: ${media.xl}) {
    margin: 20px 250px 0 330px;
  }
`;

const Box = styled.div`
  border: 1px solid ${colors.gray2};
  background-color: white;
  border-radius: ${radius};
  padding: 5px 10px;
  width: 100%;
  max-width: 1100px;
  margin-bottom: 30px;

  @media (min-width: ${media.lg}) {
    padding: 15px 30px;
    position: relative;
  }
`;

const NoReports = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 100%;

  img {
    width: 40%;
  }

  h2 {
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
  }

  a {
    color: ${colors.primary};
    text-decoration: underline;
  }
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
`;
