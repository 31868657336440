import React, {useState, useCallback, useEffect} from 'react';
import styled from 'styled-components';

import {
  Icon,
  PopoverInteractionKind,
  Position,
  InputGroup,
  ControlGroup,
  Button,
  Classes,
} from '@blueprintjs/core';
import {Popover2} from '@blueprintjs/popover2';
import {useStore} from 'react-hookstore';
import {colors, radius, media} from '../modules/styles';
import fetcher from '../modules/fetcher';
import notifications from '../modules/notifications';

export default function AddToReportButton({onCreate, onAppend, children}) {
  const [currentUser] = useStore('currentUser');
  const [isMobile] = useStore('isMobile');
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [newReportName, setNewReportName] = useState('');

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);

      const res = await fetcher.get('/api/reports');

      setReports(Array.isArray(res.data) ? res.data : []);
      setIsLoading(false);
    } catch (e) {
      notifications.error(`Error fetching report list: ${e.message}`);
      setReports([]);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const content = (
    <ReportSelection>
      <ReportCreation>
        <Label>Create a New Report:</Label>
        <form
          onSubmit={async e => {
            e.preventDefault();
            await onCreate(newReportName);
            setNewReportName('');
            await loadData();
          }}
        >
          <ControlGroup fill={true}>
            <InputGroup
              fill={true}
              // autoFocus={true} /* WARNING: Enabling this triggers a bug that causes the page to scroll to top: https://github.com/palantir/blueprint/issues/2864 */
              placeholder="Give your report a name..."
              onChange={e => setNewReportName(e.target.value)}
            ></InputGroup>
            <Button
              type="submit"
              icon="chevron-right"
              intent="primary"
              className={Classes.POPOVER_DISMISS}
              disabled={!newReportName}
            />
          </ControlGroup>
        </form>
      </ReportCreation>

      {reports.length > 0 && !isLoading && (
        <>
          <Or>
            <hr />
            <span> OR </span>
            <hr />
          </Or>
          <ReportList>
            <Label>Append to an Existing Report:</Label>

            {reports.map(report => (
              <Existing
                minimal={true}
                key={report.id}
                onClick={() => onAppend(report.id)}
                className={Classes.POPOVER_DISMISS}
                icon="dot"
                rightIcon={
                  currentUser.id !== report.user ? 'people' : undefined
                }
              >
                {report.name}
              </Existing>
            ))}
          </ReportList>
        </>
      )}
    </ReportSelection>
  );

  return (
    <Comp data-tour="add-to-report">
      <Popover2
        interactionKind={PopoverInteractionKind.CLICK}
        position={isMobile ? Position.BOTTOM : Position.RIGHT_BOTTOM}
        content={content}
        onOpening={loadData}
      >
        {children ? (
          children
        ) : (
          <AddToReport>
            <Icon icon="add-to-artifact" iconSize={18} />
            Add Results To Report
          </AddToReport>
        )}
      </Popover2>
    </Comp>
  );
}

const Comp = styled.div``;

const AddToReport = styled.button`
  display: flex;
  align-items: center;
  background-color: ${colors.primary};
  border: none;
  border-radius: ${radius};
  padding: 6px 10px;
  color: white;
  white-space: nowrap;
  cursor: pointer;
  font-size: 1rem;

  .bp3-icon {
    margin: 0 5px 3px 0;
    /* color: ${colors.primary}; */
  }

  &:hover {
    opacity: 0.9;
  }

  /* @media (min-width: ${media.lg}) {
    margin: 0 0 0 60px;
  } */
`;

const ReportSelection = styled.div`
  padding: 20px;
  min-width: 330px;
  max-width: 330px;
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
`;

const ReportCreation = styled.div`
  margin-bottom: 20px;

  .bp3-input:focus,
  .bp3-input.bp3-active {
    box-shadow: 0 0 0 1px ${colors.primary};
  }
`;

const ReportList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Or = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  hr {
    width: 100%;
    border: none;
    border-top: 1px solid ${colors.gray3};
    margin: 0 5px;
  }
`;

const Existing = styled(Button)`
  display: flex;
  justify-content: flex-start;

  &:hover {
    background-color: ${colors.primaryLight} !important;
  }

  span:nth-of-type(3) {
    margin-left: auto;
  }

  /* border: none;
  margin: 0 0 10px 15px;
  color: ${colors.primary};
  text-align: left;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  } */
`;
