const darkBlue = '#387EA2';
const lightBlue = '#61BCDF';
const brightYellow = '#F4BA4D'; // This is the real yellow but it's a little too light for text
const yellow = '#F4BA4D';
const black = '#001D29';

export const colors = {
  primary: darkBlue,
  primary2: lightBlue,
  // primaryLight: '#E1F2FE',
  primaryLight: '#e7f6fb',
  secondary: yellow,
  secondaryBright: brightYellow,
  secondaryLight: '#ffe5d3',

  success: '#15b371',
  warning: '#f29d49',
  danger: '#db3737',
  dangerLight: 'hsl(0, 63%, 94%)',
  info: '#266dd3',

  black,
  grayDark: '#30404d',
  // grayDark: '#30404d',
  gray: '#5c7080',
  gray2: '#bfccd6',
  gray3: '#e1e8ed',
  gray4: '#ebf1f5',
  placeholder: '#9da9b3',

  redCallout: '#b22121',
  redCalloutLight: 'hsl(0, 63%, 94%)',

  yellowCallout: '#967315',
  yellowCalloutLight: '#fff4d7',

  greenCallout: '#0f9960',
  greenCalloutLight: '#ddf3ea',

  searchHighlight: '#ffefc6',
  searchHighlightLight: '#fff4d7',

  background: '#f7fafb',
};

export const media = {
  sm: '576px',
  md: '768px',
  lg: '1060px', // Note: this size is also referenced in base.css so if you need to change it be sure to update it there as well
  xl: '1200px',
};

export const radius = '3px';
export const shadow =
  '0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 8px 24px rgba(16, 22, 26, 0.2);';
export const shadowTight =
  '0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2), 0 0px 12px rgba(16, 22, 26, 0.2);';
export const appearDown = 'appear-down 200ms ease-out';
export const appearUp = 'appear-up 200ms ease-out';
