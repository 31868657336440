import React from 'react';
import numeral from 'numeral';
import styled from 'styled-components';
import {colors, radius, shadowTight} from '../modules/styles';

export default function SubscriptionPlanBox({
  plan,
  isSelected = false,
  showSelectCTA = false,
  adminMode = false,
  onClick,
}) {
  return (
    <Comp>
      {isSelected && <Selected>CURRENTLY SELECTED</Selected>}

      <PlanName>{plan.name}</PlanName>
      <Summary>{plan.summary}</Summary>

      {(plan.showPricing || adminMode) && (
        <>
          <Price>
            {numeral(plan.annualPrice / 12).format('$0,0')} CAD
            <small>/mth</small> <Tiny>billed annually</Tiny>
          </Price>
          <AltPrice>
            or {numeral(plan.monthlyPrice).format('$0,0')} CAD /mth if billed
            monthly
          </AltPrice>
        </>
      )}

      {!plan.showPricing && !adminMode && (
        <>
          <Price>
            <PricingLink href="https://complianceworks.ca/pricing/">
              See Pricing
            </PricingLink>
          </Price>
          <AltPrice>and compare plans</AltPrice>
        </>
      )}

      {showSelectCTA ? (
        <CTAButton onClick={onClick} disabled={isSelected}>
          {isSelected ? 'Selected' : 'Select Plan'}
        </CTAButton>
      ) : (
        <CTAButton onClick={onClick}>
          Try for Free
          <NoCard>No Credit Card Needed</NoCard>
        </CTAButton>
      )}

      <Features dangerouslySetInnerHTML={{__html: plan.features}}></Features>
    </Comp>
  );
}

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  min-width: 320px;
  margin: 10px;
  position: relative;

  box-shadow: ${shadowTight};
  border-radius: ${radius};
  background-color: white;

  border-top: 4px solid ${colors.primary};
`;

const Selected = styled.div`
  position: absolute;
  top: -15px;
  left: 0;
  background-color: ${colors.success};
  color: white;
  width: 100%;
  height: 25px;
  text-align: center;
  padding: 4px 10px;
  font-weight: 600;
  border-top-left-radius: ${radius};
  border-top-right-radius: ${radius};
`;

const PlanName = styled.h2`
  font-size: 1.6rem;
  margin: 0 0 10px 0;
  font-weight: 700;
`;

const Summary = styled.p`
  text-align: center;
  font-size: 0.9rem;
  color: ${colors.gray};
`;

const Price = styled.h3`
  font-size: 2.2rem;
  font-weight: 700;
  margin: 0 0 5px 0;

  small {
    font-size: 1.6rem;
    color: ${colors.gray};
    font-weight: 600;
  }
`;

const Tiny = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.gray};
`;

const AltPrice = styled.div`
  font-size: 1rem;
  margin: 0 0 20px 0;
  color: ${colors.gray};
`;

const PricingLink = styled.a`
  text-decoration: underline;
  color: ${colors.primary2};
`;

const CTAButton = styled.button`
  margin: 10px 0 30px 0;
  padding: 15px 10px;
  width: 100%;
  border: none;
  background-color: ${colors.primary2};
  border-radius: ${radius};
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 150ms ease-out;

  &:hover {
    transform: scale(1.03);
    transition: all 150ms ease-out;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const Features = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;

  p {
    text-align: center;
    margin: 0 0 20px 0;
  }

  strong {
    font-weight: 700;
  }
`;

const NoCard = styled.div`
  font-size: 0.8rem;
  margin: 10px 0 0 0;
`;
