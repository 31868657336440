import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Dialog, Button, Switch} from '@blueprintjs/core';
import fetcher from '../modules/fetcher';
import notifications from '../modules/notifications';
import {colors, radius} from '../modules/styles';
import {useStore} from 'react-hookstore';
import moment from 'moment';

export default function TimeMachineComparer({
  isOpen,
  isEditable,
  provisionId,
  onClose,
}) {
  const [currentUser] = useStore('currentUser');
  const [data, setData] = useState(null);
  const [isCurrentRedlined, setIsCurrentRedlined] = useState(true);
  const [isPreviousRedlined, setIsPreviousRedlined] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [versionsToDelete, setVersionsToDelete] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const res = await fetcher.get(
          `/api/provisions/${provisionId}/timeMachine`
        );
        if (res) {
          // console.log('REDLINE DATA', res.data);
          setData(res.data);
        }
      } catch (e) {
        console.error(e);
        notifications.error(`Error loading provision history: ${e.toString()}`);
      }
    };

    if (provisionId && isOpen) {
      loadData();
    } else {
      setData(null);
    }
  }, [provisionId, isOpen]);

  const onCancel = () => {
    setData(null);
    setVersionsToDelete([]);
    setIsCurrentRedlined(true);
    setIsPreviousRedlined(true);
    setCurrentIndex(0);

    if (onClose) {
      onClose();
    }
  };

  const onDelete = (index, snapshotId) => {
    const newVersions = [...data.versions];
    newVersions.splice(index, 1);
    const newData = {...data, versions: newVersions};

    setData(newData);
    setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0);
    setVersionsToDelete([...versionsToDelete, snapshotId]);
  };

  const onSave = async () => {
    try {
      await fetcher.delete(`/api/provisions/${provisionId}/timeMachine`, {
        params: {ids: versionsToDelete},
      });
      onCancel();
    } catch (e) {
      notifications.error(`Error deleting snapshots: ${e.message}`);
    }
  };

  return (
    <Comp>
      <Dialog
        isOpen={isOpen}
        title="Compare Provision History"
        icon="comparison"
        onClose={onCancel}
        portalClassName="provision-version-comparer"
      >
        <Note>
          Note: Upcoming changes are not included. Some changes may simply be to
          the formatting of the content, in which case those changes will not be
          highlighted.
        </Note>
        <Main className="bp3-dialog-body">
          <Left>
            <TitleWrapper>
              <h1>Previous Description</h1>
              <Switch
                label="Show Redline"
                checked={isPreviousRedlined}
                onChange={() => setIsPreviousRedlined(!isPreviousRedlined)}
              />
            </TitleWrapper>

            <Actions>
              <Date>
                <Button
                  onClick={() => setCurrentIndex(currentIndex + 1)}
                  disabled={
                    data?.versions.length === 0 ||
                    currentIndex === data?.versions.length - 1
                  }
                >
                  Older
                </Button>

                <span style={{margin: '0 10px'}}>
                  {moment(data?.versions[currentIndex]?.previousDate).format(
                    'll'
                  )}
                </span>

                <Button
                  onClick={() => setCurrentIndex(currentIndex - 1)}
                  disabled={data?.versions.length === 0 || currentIndex === 0}
                >
                  Newer
                </Button>
              </Date>

              {isEditable && currentUser.isStaff && (
                <Button
                  intent="danger"
                  small={true}
                  disabled={data?.versions.length === 0}
                  onClick={() =>
                    onDelete(
                      currentIndex,
                      data?.versions[currentIndex]?.snapshotId
                    )
                  }
                >
                  Delete This Version
                </Button>
              )}
            </Actions>

            {data?.versions[currentIndex]?.previous && (
              <PreviousDesc
                dangerouslySetInnerHTML={{
                  __html: isPreviousRedlined
                    ? data.versions[currentIndex]?.previousRedline
                    : data.versions[currentIndex]?.previous,
                }}
              />
            )}
            {!data ||
              (data?.versions.length === 0 && (
                <NotFound>No previous versions found.</NotFound>
              ))}
          </Left>
          <Right>
            <TitleWrapper>
              <h1>Current Description</h1>
              <Switch
                label="Show Redline"
                checked={isCurrentRedlined}
                onChange={() => setIsCurrentRedlined(!isCurrentRedlined)}
              />
            </TitleWrapper>
            {data?.asOf && (
              <Actions>
                <Date>As of {moment(data?.asOf).format('ll')}</Date>
              </Actions>
            )}

            {data?.versions[currentIndex]?.currentRedline && (
              <CurrentDesc
                dangerouslySetInnerHTML={{
                  __html: isCurrentRedlined
                    ? data?.versions[currentIndex]?.currentRedline
                    : data?.current,
                }}
              />
            )}
            {!data?.versions[currentIndex]?.currentRedline && (
              <Desc dangerouslySetInnerHTML={{__html: data?.current}} />
            )}
          </Right>
        </Main>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            {isEditable && currentUser.isStaff && (
              <>
                <Button onClick={onCancel}>Cancel &amp; Close</Button>
                <Button intent="primary" onClick={onSave}>
                  Save Changes
                </Button>
              </>
            )}
            {!isEditable && (
              <>
                <Button intent="primary" onClick={onCancel}>
                  Close
                </Button>
              </>
            )}
          </div>
        </div>
      </Dialog>
    </Comp>
  );
}

const Comp = styled.div``;

const Note = styled.div`
  font-size: 0.9rem;
  line-height: 1.4rem;
  border: 1px solid ${colors.yellowCallout};
  padding: 5px;
  margin: 10px auto;
  background-color: ${colors.yellowCalloutLight};
  color: ${colors.yellowCallout};
  border-radius: ${radius};
`;

const Main = styled.div`
  display: flex;
  min-width: 900px;
  min-height: 500px;

  h1 {
    font-size: 1.4rem;
    margin: 0;
  }

  hr {
    border: none;
    border-top: 1px solid ${colors.gray2};
    margin: 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Date = styled.div`
  margin: 0 0 20px 0;
  font-size: 0.9rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  height: 30px;
  margin-bottom: 20px;
  /* align-items: center; */
`;

const Left = styled.div`
  padding: 0 20px 0 0;
  width: 50%;
`;

const Right = styled.div`
  padding: 0 0 0 20px;
  width: 50%;

  border-left: 1px dashed ${colors.gray2};
`;

const Desc = styled.div`
  background-color: white;
  border-radius: ${radius};
  padding: 10px;

  a {
    text-decoration: underline;
    color: ${colors.primary};
  }
`;

const PreviousDesc = styled(Desc)`
  del {
    text-decoration: none;
    display: none;
  }
  ins {
    color: ${colors.redCallout};
    background-color: ${colors.redCalloutLight};
    text-decoration: line-through;
  }
`;

const CurrentDesc = styled(Desc)`
  ins {
    color: ${colors.greenCallout};
    background-color: ${colors.greenCalloutLight};
    text-decoration: none;
  }
  del {
    text-decoration: none;
    display: none;
  }
`;

const NotFound = styled(Desc)`
  /* font-style: italic; */
`;
