import moment from 'moment';

export function isInForce(billActData) {
  const now = moment();
  if (
    billActData.status?.endsWith('Assent') &&
    billActData.assentDate &&
    moment(billActData.assentDate).isBefore(now) &&
    (billActData.inForce === 'assent' ||
      ((billActData.inForce === 'on date' ||
        billActData.inForce === 'retro on date') &&
        billActData.inForceDate &&
        moment(billActData.inForceDate).isBefore(now)))
  ) {
    return true;
  }

  if (
    billActData.status === 'Filed' &&
    (billActData.inForce === 'on date' ||
      billActData.inForce === 'retro on date') &&
    billActData.inForceDate &&
    moment(billActData.inForceDate).isBefore(now)
  ) {
    return true;
  }

  if (
    billActData.status?.endsWith('Assent') &&
    billActData.assentDate &&
    moment(billActData.assentDate).isBefore(now) &&
    billActData.inForce === 'assent'
  ) {
    return true;
  }

  return false;
}
