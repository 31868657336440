import React from 'react';
import styled from 'styled-components';
import {Menu, MenuItem, MenuDivider} from '@blueprintjs/core';
import {media} from '../modules/styles';
import {Select} from '@blueprintjs/select';

export default function HelpMenu({children, mobile, setCurrentVideo}) {
  const items = [
    {
      url: 'https://www.youtube.com/watch?v=GmuXkyCJTyM',
      title: 'How To - Find Amendments',
    },
    {
      url: 'https://www.youtube.com/watch?v=RMvwpMErn8U',
      title: 'How To - Create Reports',
    },
    {
      url: 'https://www.youtube.com/watch?v=mcu5jCdj01A',
      title: 'How To - Use Checklists',
    },
    {
      url: 'https://www.youtube.com/watch?v=hb6nCoEpuHY',
      title: 'How To - Add/Remove Users and Manage Your Account',
    },
    {
      url: 'https://www.youtube.com/watch?v=8yRfFeUWRgA',
      title: 'How To - Use Search',
    },
    {
      url: 'https://www.youtube.com/watch?v=MXfNOR2X5OY',
      title: 'How To - Compare HR Laws',
    },
    {
      url: 'https://www.youtube.com/watch?v=9iEmUjIaXT8',
      title:
        'How To - Find Special Rules for an Occupational Group or Industry',
    },
    {
      url: 'https://www.youtube.com/watch?v=mAn1sLl2SsQ',
      title: 'How To - Manage Email Preferences',
    },
    {
      url: 'https://www.youtube.com/watch?v=VFp-ErWXUi4',
      title: 'How To - Share HR Compliance Information',
    },
  ];

  const itemRenderer = item => (
    <MenuItem
      icon="video"
      text={item.title}
      multiline
      onClick={() => setCurrentVideo(item)}
      role="listitem"
      key={item.url}
    />
  );

  const itemListRenderer = ({
    items,
    itemsParentRef,
    query,
    renderItem,
    menuProps,
  }) => {
    const renderedItems = items
      .map(renderItem)
      .filter(item =>
        item.props.text?.toLowerCase().includes(query.toLowerCase())
      );

    return (
      <HelpMenuList ulRef={itemsParentRef} role="listbox" {...menuProps}>
        <MenuItem
          icon="help"
          text="Help / FAQ"
          href="https://complianceworks.ca/faqs/"
          target="_blank"
          rel="noopener noreferrer"
          role="listoption"
        />
        <MenuDivider title="How-To-Videos" icon="video" className="divider" />
        {renderedItems.length ? (
          renderedItems
        ) : (
          <MenuItem disabled={true} text="No results" role="listoption" />
        )}
      </HelpMenuList>
    );
  };

  return (
    <Comp>
      <Select
        items={items}
        popoverProps={{
          position: mobile ? 'left' : 'bottom',
          minimal: mobile,
          usePortal: false,
        }}
        itemRenderer={itemRenderer}
        itemListRenderer={itemListRenderer}
        resetOnClose
        resetOnSelect
        className={mobile && 'mobile-only'}
      >
        {children}
      </Select>
    </Comp>
  );
}

const Comp = styled.div`
  @media (min-width: 1200px) {
    margin: 0 8px 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bp3-input-group {
    margin: 10px;
  }

  .divider {
    margin: 10px 0 0 0;

    .bp3-heading {
      margin: 5px 0 5px 0;
    }
  }
`;

const HelpMenuList = styled(Menu)`
  width: 45vw;

  @media (min-width: ${media.lg}) {
    width: 350px;
  }
`;
