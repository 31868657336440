import React from 'react';
import styled from 'styled-components';
import {Select} from '@blueprintjs/select';
import {MenuItem} from '@blueprintjs/core';

export default function FilterSelect({
  id,
  items,
  disabled = false,
  activeItem,
  onSelect,
  children,
  position = 'bottom',
  filterable = false,
  ...rest
}) {
  return (
    <Comp>
      <Select
        id={id}
        items={items}
        activeItem={activeItem}
        itemsEqual="id"
        disabled={disabled}
        onItemSelect={inf => onSelect(inf)}
        resetOnClose={true}
        filterable={filterable}
        noResults={<MenuItem disabled={true} text="No results." />}
        popoverProps={{
          rootBoundary: 'document',
          position: position,
          portalClassName: 'filter-select-popover',
        }}
        itemPredicate={(query, item) => {
          const qLower = query.toLowerCase();
          const nLower = item?.name?.toLowerCase();

          return nLower.includes(qLower);
        }}
        itemRenderer={(item, {handleClick, modifiers}) => {
          if (!modifiers.matchesPredicate) {
            return null;
          }
          return (
            <MenuItem
              active={modifiers.active}
              key={item.id || item.name}
              text={item.name}
              onClick={handleClick}
            />
          );
        }}
        {...rest}
      >
        {children}
      </Select>
    </Comp>
  );
}

const Comp = styled.div`
  .bp3-popover-wrapper {
    display: inline-block; /* Prevents the click area from being 100% wide */
    width: 100%;
  }
`;
