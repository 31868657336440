import {useEffect, useState} from 'react';
import {EventManager} from '../modules/eventManager';

export default function useEvent(events, cb) {
  /**
   * If the incoming 'events' is an array, it may get re-created with each render in the calling component
   * which would cause this hook to unmount/remount each time. So by pushing it into useState(), we
   * can ensure we only ever use the first instance and ignore further changes.
   *
   * If we ever want the ability to dynamically *change* which event types the client wants to listen to, then we'd
   * have to modify this further but for now we'll assume the list is static.
   */
  const [types] = useState(events);

  /* Now subscribe to incoming events */
  useEffect(() => {
    EventManager.addSubscriber(types, cb);
    return () => EventManager.removeSubscriber(cb);
  }, [types, cb]);
}
