import React, {Component} from 'react';
import styled from 'styled-components';
import {Button as BPComp} from '@blueprintjs/core';
import {colors} from '../modules/styles';
import {darken} from 'polished';
import {NavLink} from 'react-router-dom';

const Comp = styled(BPComp)`
  white-space: nowrap;
  text-decoration: none !important;

  &:disabled {
    opacity: 0.5;
  }

  &.bp3-button:not([class*='bp3-intent-']) {
    display: flex;
    align-items: center;
    background-color: ${colors.primary} !important;
    background-image: none !important;
    border: none !important;
    color: white !important;
    box-shadow: none !important;

    .bp3-icon {
      color: white;
      fill: white;
    }

    &.secondary {
      background-color: ${colors.gray4} !important;
      color: ${colors.gray} !important;
      border: 1px solid ${colors.gray2} !important;

      .bp3-icon {
        color: ${colors.gray} !important;
        fill: ${colors.gray} !important;
      }
    }
  }

  &.bp3-button:not([class*='bp3-intent-']):hover {
    background-color: ${colors.primaryDark} !important;

    &.secondary {
      background-color: ${darken(0.05, colors.gray4)} !important;
    }
  }

  &.bp3-button.bp3-intent-warning,
  &.bp3-button.bp3-intent-danger {
    border: none !important;
    box-shadow: none !important;
  }

  &.bp3-button.bp3-minimal {
    background: none !important;
    color: ${colors.gray} !important;
    box-shadow: none !important;

    .bp3-icon {
      color: ${colors.gray};
      fill: ${colors.gray};
    }
  }

  &.bp3-button.bp3-minimal:hover {
    background: none !important;
    color: ${colors.primary} !important;
    .bp3-icon {
      color: ${colors.primary} !important;
      fill: ${colors.primary} !important;
    }
  }

  &.bp3-button.bp3-intent-danger.bp3-minimal:hover {
    background: none !important;
    color: ${colors.danger} !important;
    .bp3-icon {
      color: ${colors.danger} !important;
      fill: ${colors.danger} !important;
    }
  }
`;

class Button extends Component {
  render() {
    if (this.props.to) {
      return (
        <NavLink to={this.props.to}>
          <Comp {...this.props}>{this.props.children}</Comp>
        </NavLink>
      );
    } else if (this.props.href) {
      return (
        <a href={this.props.href} target={this.props.target || '_self'}>
          <Comp {...this.props}>{this.props.children}</Comp>
        </a>
      );
    } else {
      return <Comp {...this.props}>{this.props.children}</Comp>;
    }
  }
}

export default Button;
