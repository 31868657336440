import React from 'react';
import styled from 'styled-components';
import {colors} from '../modules/styles';
import ActRegLink from './ActRegLink';

export default function ESRDetails({sre}) {
  const {act, regulations} = sre;

  return (
    <Comp>
      {act && (
        <ActRegLink name={act.name} url={act.url} sections={sre.actSections} />
      )}
      {regulations && (
        <ActRegLink
          name={regulations.name}
          url={regulations.url}
          sections={sre.regSections}
        />
      )}

      {sre.description && (
        <Description
          dangerouslySetInnerHTML={{
            __html: sre.description,
          }}
        />
      )}
    </Comp>
  );
}

const Comp = styled.div`
  margin-bottom: 20px;

  a {
    font-size: 1rem;
    text-decoration: underline;
    margin: 0 0 5px 0;
    font-weight: 600;
    color: ${colors.primary};
  }
`;

const Description = styled.div`
  margin: 20px 0;

  mark {
    background-color: ${colors.searchHighlight};
  }
`;
